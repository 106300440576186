import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Host,
	Input,
	Optional,
	SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlcBaseFormControl } from '@components/models/foms.models';

@Component({
	selector: 'plc-checkbox',
	templateUrl: './checkbox.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends PlcBaseFormControl {
	@Input() public type: 'check' | 'slide' = 'check';

	public get checked(): boolean {
		return this._checked;
	}

	public get isCheck(): boolean {
		return this.type === 'check';
	}

	public get isSlide(): boolean {
		return this.type === 'slide';
	}

	private _checked: boolean;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
	) {
		super(controlContainer, cdr);
	}

	public handleCheck(): void {
		this._checked = !this.checked;
		this.onTouch();
		this.onChange(this._checked);
	}

	writeValue(value: boolean): void {
		this._checked = value ? value : false;
		this.cdr.markForCheck();
	}
}
