/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ChangeDetectorRef,
	DestroyRef,
	Directive,
	Host,
	inject,
	Input,
	OnInit,
	Optional,
	SkipSelf,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	AbstractControlOptions,
	AsyncValidatorFn,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	UntypedFormControl,
	ValidatorFn,
} from '@angular/forms';
import { ValidationMessagesViewMode } from '@components/modules/forms/validation-messages/validation-messages.component';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';

export enum PlcValidationErrorType {
	required = 'required',
	minLen = 'minlength',
	maxLen = 'maxlength',
	unmatched = 'unmatched',
	nameExists = 'nameExists',
	emailNotValid = 'emailNotValid',
	emailExists = 'emailExists',
	emailDoesNotExists = 'emailDoesNotExists',
	gameExists = 'gameExists',
	min = 'min',
	max = 'max',
}

export interface PlcAbstractControlOptions extends AbstractControlOptions {
	validationErrors?: PlcValidationError[] | null;
}

export interface PlcValidationError {
	error: PlcValidationErrorType;
	message: string;
	interpolatedParams?: { [key: string]: string | number };
}

export type PlcValidation = PlcValidationError & {
	valid: boolean;
};

export class PlcTypedFormControl<T> extends FormControl<T> {
	public get validationErrors(): PlcValidationError[] {
		return this._validationErrors || [];
	}

	private _validationErrors: PlcValidationError[];

	constructor(formState?: any, options?: PlcAbstractControlOptions) {
		super(formState, options);
		this._validationErrors = options?.validationErrors;
	}

	public setPlcValidators(
		newValidator: ValidatorFn | ValidatorFn[] | null,
		validationErrors: PlcValidationError[],
	): void {
		super.setValidators(newValidator);
		this._validationErrors = validationErrors;
	}

	public setPlcAsyncValidators(
		newValidator: AsyncValidatorFn | AsyncValidatorFn[] | null,
		validationErrors: PlcValidationError[],
	): void {
		super.setAsyncValidators(newValidator);
		this._validationErrors = validationErrors;
	}
}

export type PlcInputType = 'text' | 'password';

@Directive()
export abstract class PlcBaseFormControl
	implements OnInit, ControlValueAccessor, PlcUnsubscribable
{
	@Input() public formControlName?: string;
	@Input() public formControl?: UntypedFormControl;

	public destroyRef: DestroyRef = inject(DestroyRef);

	public onChange = (_: any) => {};
	public onTouch = () => {};

	public get value(): unknown {
		return this._value ?? '';
	}

	public get valid(): boolean {
		return this._control && this._control.value && this._control.valid;
	}

	public get validations(): PlcValidation[] {
		if (!this._control || !(this._control instanceof PlcTypedFormControl))
			return [];

		const { validationErrors } = this._control;
		return validationErrors.map(
			({ message, error, interpolatedParams }: PlcValidationError) => ({
				message,
				error,
				valid: !this.errors.find((err) => err.error === error),
				interpolatedParams,
			}),
		);
	}

	public get errors(): PlcValidationError[] {
		if (!this._control || !(this._control instanceof PlcTypedFormControl))
			return [];

		const { validationErrors } = this._control;
		if (this._control.touched && this._control.errors)
			return validationErrors.filter((msg) =>
				Object.keys(this._control.errors).includes(msg.error),
			);
		else return [];
	}

	public get viewMode(): ValidationMessagesViewMode {
		if (this._focused && this.validations.length > 0) return 'all';
		else if (!this._focused && this.errors && this.errors.length > 0)
			return 'first';
		else return 'none';
	}

	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	protected _value: unknown;
	protected _isDisabled: boolean;
	protected _control: AbstractControl;
	protected _focused: boolean;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		private controlContainer: ControlContainer,
		protected cdr: ChangeDetectorRef,
	) {}

	ngOnInit() {
		if (this.controlContainer && this.formControlName)
			this._control = this.controlContainer.control.get(
				this.formControlName,
			);
		else if (this.formControl) this._control = this.formControl;

		if (this._control)
			this._control.valueChanges
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((value) => {
					this._value = value;
					this.cdr.markForCheck();
				});
	}

	public handleInput(value: string | number): void {
		this._value = value;
		this.onTouch();
		this.onChange(this._value);
	}

	public handleBlur(): void {
		this.handleFocus(false);
		this.onTouch();
	}

	public handleFocus(value: boolean): void {
		this._focused = value;
	}

	public writeValue(value: unknown): void {
		this._value = value ? value : null;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
	}
}
