import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SCORE_COUNTER_ANIMATION } from '@components/modules/game/commons/score-counter/score-counter.component';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';
import { AUDIO_DIGIT_DURATION } from '@core/services/native/audio.feature';

export const PHASE_SCORE_COUNTER_ANIMATION_DURATION =
	SCORE_COUNTER_ANIMATION * 3;

class Times {
	public timeBlue: number;
	public timeOrange: number;
	public timeAccumulatedBlue: number;
	public timeAccumulatedOrange: number;
}

@Component({
	selector: 'plc-phase-score-counter',
	templateUrl: './phase-score-counter.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-phase-score-counter' },
})
export class PhaseScoreCounterComponent
	implements OnInit, OnChanges, PlcUnsubscribable
{
	@Input({ required: true }) public timeBlue: number;
	@Input({ required: true }) public timeOrange: number;
	@Input({ required: true }) public timeAccumulatedBlue: number;
	@Input({ required: true }) public timeAccumulatedOrange: number;
	@Output() public valueChange = new EventEmitter<number>();

	public destroyRef: DestroyRef = inject(DestroyRef);

	public get timeBlue$(): Observable<number> {
		return this._timeBlue$.asObservable();
	}

	public get timeOrange$(): Observable<number> {
		return this._timeOrange$.asObservable();
	}
	public get timeAccumulatedBlue$(): Observable<number> {
		return this._timeAccumulatedBlue$.asObservable();
	}

	public get timeAccumulatedOrange$(): Observable<number> {
		return this._timeAccumulatedOrange$.asObservable();
	}

	private _timeBlue$ = new BehaviorSubject(0);
	private _timeOrange$ = new BehaviorSubject(0);
	private _timeAccumulatedBlue$ = new BehaviorSubject(0);
	private _timeAccumulatedOrange$ = new BehaviorSubject(0);

	ngOnInit(): void {
		const times = {
			timeBlue: this.timeBlue,
			timeOrange: this.timeOrange,
			timeAccumulatedBlue: this.timeAccumulatedBlue,
			timeAccumulatedOrange: this.timeAccumulatedOrange,
		};
		this.runValueUpdateSequence(times);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			const {
				timeBlue,
				timeOrange,
				timeAccumulatedBlue,
				timeAccumulatedOrange,
			} = changes;

			const times = {
				timeBlue:
					timeBlue && timeBlue.currentValue
						? timeBlue.currentValue
						: this.timeBlue,
				timeOrange:
					timeOrange && timeOrange.currentValue
						? timeOrange.currentValue
						: this.timeOrange,
				timeAccumulatedBlue:
					timeAccumulatedBlue && timeAccumulatedBlue.currentValue
						? timeAccumulatedBlue.currentValue
						: this.timeAccumulatedBlue,
				timeAccumulatedOrange:
					timeAccumulatedOrange && timeAccumulatedOrange.currentValue
						? timeAccumulatedOrange.currentValue
						: this.timeAccumulatedOrange,
			};
			this.runValueUpdateSequence(times);
		}
	}

	public emitValueChange(value: number): void {
		this.valueChange.emit(value);
	}

	private runValueUpdateSequence(times: Times): void {
		const {
			timeBlue,
			timeOrange,
			timeAccumulatedBlue,
			timeAccumulatedOrange,
		} = times;

		this._timeBlue$.next(timeBlue);
		this._timeOrange$.next(timeOrange);
		this._timeAccumulatedBlue$.next(timeAccumulatedBlue);
		this._timeAccumulatedOrange$.next(timeAccumulatedOrange);

		of(true)
			.pipe(
				delay(SCORE_COUNTER_ANIMATION),
				tap(() => {
					const newTimeBlue =
						this.timeAccumulatedBlue + this.timeBlue;
					this._timeAccumulatedBlue$.next(newTimeBlue);
					this._timeBlue$.next(0);
				}),
				delay(SCORE_COUNTER_ANIMATION + AUDIO_DIGIT_DURATION),
				tap(() => {
					const newTimeOrange =
						this.timeAccumulatedOrange + this.timeOrange;
					this._timeAccumulatedOrange$.next(newTimeOrange);
					this._timeOrange$.next(0);
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}
}
