import { Game } from '@api/models/game.entity';
import { Phase } from '@bussiness/custom-models/game.custom-models';

export enum GamesEventType {
	Joined = 'games:joined',
	Navigated = 'games:navigated',
	Leaved = 'games:leaved',
	Updated = 'games:updated',
	PhaseAdded = 'games:phase-added',
}

export class GamesUpdatedEvent {
	public game: Game;
}

export class GamesPhaseAddedEvent {
	public gameId: string;
	public phase: Phase;
}

export enum GameNavigationType {
	SongPhasePreview = 'song:preview',
	SongPhaseGame = 'song:game',
	SongPhaseResult = 'song:result',
	MemoryPhasePreview = 'memory:preview',
	MemoryPhaseGame = 'memory:game',
	MemoryPhaseResult = 'memory:result',
	BagelsPhasePreview = 'bagels:preview',
	BagelsPhaseGame = 'bagels:game',
	BagelsPhaseResult = 'bagels:result',
	SoupsPhasePreview = 'soups:preview',
	SoupsPhaseGame = 'soups:game',
	SoupsPhaseResult = 'soups:result',
	GameResult = 'game:result',
}

export class GameNavigatedEvent {
	public gameId: string;
	public type: GameNavigationType;
}
