import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsSharedModule } from '../shared/components-shared.module';
import { HiddenIfChildsAreEmptyDirective } from '../shared/hidden-if-childs-are-empty.directive';
import { ActionButtonsWrapperComponent } from './action-buttons-wrapper/action-buttons-wrapper.component';
import { BackgroundScreenComponent } from './background-screen/background-screen.component';
import { BoxComponent } from './box/box.component';
import { FooterComponent } from './footer/footer.component';
import { PanelComponent } from './panel/panel.component';
import { SectionComponent } from './section/section.component';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabsService } from './tabs/tabs.service';
import { ToolbarDesktopMenuComponent } from './toolbar/toolbar-desktop/toolbar-desktop-menu.component';
import { ToolbarDesktopComponent } from './toolbar/toolbar-desktop/toolbar-desktop.component';
import { ToolbarMobileComponent } from './toolbar/toolbar-mobile/toolbar-mobile.component';

@NgModule({
	declarations: [
		ActionButtonsWrapperComponent,
		FooterComponent,
		PanelComponent,
		SectionComponent,
		TabsComponent,
		TabComponent,
		ToolbarDesktopComponent,
		ToolbarDesktopMenuComponent,
		ToolbarMobileComponent,
		BoxComponent,
		HiddenIfChildsAreEmptyDirective,
	],
	imports: [
		CommonModule,
		ComponentsSharedModule,
		TranslateModule.forChild({
			extend: true,
		}),
	],
	exports: [
		ActionButtonsWrapperComponent,
		BackgroundScreenComponent,
		FooterComponent,
		PanelComponent,
		SectionComponent,
		TabsComponent,
		TabComponent,
		ToolbarDesktopComponent,
		ToolbarMobileComponent,
		BoxComponent,
	],
	providers: [TabsService],
})
export class PlcLayoutModule {}
