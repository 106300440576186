import {
	animate,
	query,
	stagger,
	style,
	transition,
	trigger,
} from '@angular/animations';

export const ACCORDION_ITEM_ANIMATION_DURATION = 100;

export const plcAccordionAnimation = trigger('plcAccordionAnimation', [
	transition('* => *', [
		query(
			':enter',
			[
				style({ opacity: 0 }),
				stagger(ACCORDION_ITEM_ANIMATION_DURATION, [
					animate('0.4s', style({ opacity: 1 })),
				]),
			],
			{ optional: true },
		),
	]),
]);
