import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _history: Array<string> = [];
	private _last: string;

	constructor(
		private router: Router,
		private location: Location,
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				const { urlAfterRedirects } = event;

				if (!this._last) this._last = urlAfterRedirects;

				if (urlAfterRedirects !== this._last)
					this._history.push(event.urlAfterRedirects);
			}
		});
	}

	public back(): void {
		this._history.pop();
		this._last = this._history[this._history.length - 1];

		if (this._history.length > 0) this.location.back();
		else this.router.navigateByUrl('/');
	}

	public getNavigationIndex(): number {
		return this._history.length;
	}
}
