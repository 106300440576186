import {
	AfterViewInit,
	Directive,
	ElementRef,
	OnDestroy,
	Renderer2,
} from '@angular/core';

const HIDDEN_CLASS = 'plc-hidden-if-childs-are-empty';

@Directive({
	selector: '[plcHiddenIfChildsAreEmpty]',
})
export class HiddenIfChildsAreEmptyDirective
	implements AfterViewInit, OnDestroy
{
	private _mutationObserver: MutationObserver;

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
	) {}

	ngAfterViewInit(): void {
		const hostHtml = this.elementRef.nativeElement as HTMLElement;

		const childrenElements = Array.from(hostHtml.children);
		this.setHostClass(childrenElements, hostHtml);

		this._mutationObserver = new MutationObserver(() => {
			this.setHostClass(childrenElements, hostHtml);
		});

		childrenElements.forEach((footerChild) =>
			this._mutationObserver.observe(footerChild, { childList: true }),
		);
	}

	private setHostClass(
		childrenElements: Element[],
		hostHtml: HTMLElement,
	): void {
		const allChildrenAreEmpty = childrenElements.every(
			(section) => section.children.length === 0,
		);

		if (allChildrenAreEmpty) this.renderer.addClass(hostHtml, HIDDEN_CLASS);
		else this.renderer.removeClass(hostHtml, HIDDEN_CLASS);
	}

	ngOnDestroy(): void {
		this._mutationObserver.disconnect();
	}
}
