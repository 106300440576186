import { KeyValue } from '@angular/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { loaderFeatureKey, LoaderState } from './loader.reducer';

const selectLoader = createFeatureSelector<LoaderState>(loaderFeatureKey);

export class LoaderSelectors {
	public static reasons = createSelector(selectLoader, (state: LoaderState) =>
		state.reasons.map(({ value }: KeyValue<string, string>) => value),
	);
}
