import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import { PlcIconAngle } from '@components/models/icon.models';

@Component({
	selector: 'plc-section',
	templateUrl: './section.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-section' },
})
export class SectionComponent {
	@HostBinding('class')
	public get hostClass(): string {
		return this._toggled ? 'plc-section--toggled' : '';
	}

	@Input({ required: true }) public title: string;
	@Input() public layout: 'column' | 'row' = 'column';
	@Input() public set toggled(value: boolean) {
		this._toggled = value ?? true;
	}

	public get angle(): PlcIconAngle {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		return this._toggled ? 90 : null;
	}

	public get mainClasses(): string {
		const classes: string[] = [];

		classes.push(`plc-section__main--${this.layout}-layout`);

		return classes.join(' ');
	}

	private _toggled = true;

	public toggle(): void {
		this._toggled = !this._toggled;
	}
}
