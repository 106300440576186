<span
	[style.visibility]="!environment ? 'hidden' : 'visible'"
	[innerHTML]="
		'components.layout.toolbars.environment'
			| translate: { environment: environment }
	"
>
</span>

<span
	[innerHTML]="
		'components.layout.toolbars.rights' | translate: { year: year }
	"
>
</span>

<div class="plc-footer__media">
	<plc-icon [name]="'youtube'" [hover]="true" />
	<plc-icon [name]="'twitter'" [hover]="true" />
	<plc-icon [name]="'reddit'" [hover]="true" />
</div>
