<ng-container *ngIf="!sideMenuIsToggled">
	<plc-icon
		[name]="'left-arrow'"
		[hover]="true"
		(click)="emitOption('back')"
	/>

	<plc-icon [name]="'menu'" [hover]="true" (click)="toggleSideMenu()" />

	<plc-theme-switcher2
		[initialTheme]="theme"
		(themeChange)="emitThemeChange($event)"
	/>
</ng-container>

<plc-background-screen
	[type]="'transparent'"
	*ngIf="sideMenuIsToggled"
	(click)="toggleSideMenu()"
>
	<aside @plcWidthIncrease class="plc-side-menu" (click)="stopEvent($event)">
		<span class="plc-side-menu__title">
			{{ 'components.layout.toolbars.menu' | translate }}
		</span>

		<section *ngIf="userData" class="plc-side-menu__user-wrapper">
			<plc-avatar
				class="plc-side-menu__user-wrapper__avatar"
				[src]="userData.avatarUrl"
				[shape]="'circle'"
			/>

			<span>
				{{ 'components.layout.toolbars.welcome-back' | translate }}
				<span class="plc-side-menu__user-wrapper__name">
					{{ userData.name }}
				</span>
			</span>
		</section>

		<section
			class="plc-side-menu__option"
			*ngFor="let entry of entries"
			(click)="emitOption(entry.action, $event)"
		>
			<plc-icon
				class="plc-side-menu__option__icon"
				[name]="entry.icon"
				[size]="'s'"
			/>
			<span> {{ entry.name | translate }} </span>
		</section>

		<section class="plc-side-menu__volume">
			<plc-icon
				[name]="volumeIcon"
				[size]="'s'"
				(click)="emitVolumeChange(0)"
			/>
			<plc-range-input
				[values]="volumeValues"
				[(ngModel)]="volume"
				(change)="emitVolumeChange($event.target.value)"
			/>
		</section>

		<plc-flag-picker
			[dir]="'column'"
			[options]="options"
			[selected]="selectedLang"
			(change)="emitLangChange($event)"
		/>

		<div class="plc-side-menu__spacer"></div>

		<footer class="plc-side-menu__footer">
			<span
				[innerHTML]="
					'components.layout.toolbars.rights'
						| translate: { year: year }
				"
			>
			</span>
			<span
				*ngIf="environment"
				[innerHTML]="
					'components.layout.toolbars.environment'
						| translate: { environment: environment }
				"
			>
			</span>
			<div class="plc-side-menu__footer__icons">
				<plc-icon [name]="'youtube'" [size]="'s'" />
				<plc-icon [name]="'twitter'" [size]="'s'" />
				<plc-icon [name]="'reddit'" [size]="'s'" />
			</div>
		</footer>
	</aside>
</plc-background-screen>
