import { Directive, ElementRef, Input } from '@angular/core';

export const TYPE_WRITTER_DEFAULT_SPEED = 20;

@Directive({
	selector: '[plcTypeWritter]',
})
export class TypeWritterDirective {
	@Input() public text = '';
	@Input() public speed = TYPE_WRITTER_DEFAULT_SPEED;

	constructor(private el: ElementRef) {
		const htmlTag = this.el.nativeElement as HTMLElement;

		let index = 0;
		const intervalId = setInterval(() => {
			htmlTag.textContent += this.text.charAt(index);

			index++;
			if (index === this.text.length) clearInterval(intervalId);
		}, this.speed);
	}
}
