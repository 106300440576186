import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';

import { BackNavigationDirective } from './directives/back-button.directive';
import {
	PlcHttpConfig,
	PlcHttpConfigService,
} from './models/core-module.models';
import { MetricsService } from './services/http/metrics.service';
import { AudioFeature } from './services/native/audio.feature';
import { AlphabetSoupPhaseSockets } from './services/sockets/alphabet-soup-phase.sockets';
import { BagelPhaseSockets } from './services/sockets/bagels-phase.sockets';
import { GamesSockets } from './services/sockets/games.sockets';
import { MemoryPhaseSockets } from './services/sockets/memory-phase.sockets';
import { PlayersSockets } from './services/sockets/players.sockets';
import { SongPhaseSockets } from './services/sockets/song-phase.sockets';
import { UsersSockets } from './services/sockets/users.sockets';
import { LocalStorageService } from './services/storage/local-storage.service';
import { SessionStorageService } from './services/storage/session-storage.service';
import { PlcFileUtil } from './utils/file.util';
import { PlcPlatform } from './utils/platform.util';

@NgModule({
	declarations: [BackNavigationDirective],
	imports: [CommonModule, HttpClientModule],
	providers: [
		AudioFeature,
		LocalStorageService,
		PlcFileUtil,
		PlcPlatform,
		SessionStorageService,
		{ provide: Window, useValue: window },
	],
	exports: [BackNavigationDirective],
})
export class CoreModule {
	constructor(injector: Injector) {
		CoreInjector.instance = injector;
	}

	public static forRoot(
		config: PlcHttpConfig,
	): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				BagelPhaseSockets,
				GamesSockets,
				MemoryPhaseSockets,
				PlayersSockets,
				SongPhaseSockets,
				UsersSockets,
				AlphabetSoupPhaseSockets,
				MetricsService,
				{
					provide: PlcHttpConfigService,
					useValue: config,
				},
			],
		};
	}
}

export class CoreInjector {
	private static _instance: Injector;
	public static get instance(): Injector {
		return CoreInjector._instance;
	}
	public static set instance(value: Injector) {
		CoreInjector._instance = value;
	}
}
