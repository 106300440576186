import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import { Dir, Theme } from './theme-switcher.models';

@Component({
	selector: 'plc-theme-switcher',
	templateUrl: './theme-switcher.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-theme-switcher' },
})
export class ThemeSwitcherComponent implements OnInit, OnChanges {
	@HostBinding('class') public get switcherClass(): string {
		const baseClass = 'plc-theme-switcher--';
		return `${baseClass}${this.dir}`;
	}

	@Input() public dir: Dir = 'horizontal';
	@Input({ required: true }) public initialTheme: Theme;
	@Output() public themeChange: EventEmitter<Theme> = new EventEmitter();

	public get checked(): boolean {
		return this._theme && this._theme === 'dark';
	}

	public get labelClass(): string {
		const baseClass = 'plc-theme-switcher__label--';
		return `${baseClass}${this.dir}`;
	}

	public get sliderClass(): string {
		const baseClass = 'plc-theme-switcher__label__slider--';
		return `${baseClass}${this.dir}`;
	}

	private _theme: Theme;

	ngOnInit() {
		this._theme = this.initialTheme ?? 'light';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.initialTheme)
			this._theme = changes.initialTheme.currentValue;
	}

	public emitThemeChange(): void {
		this._theme = this._theme === 'light' ? 'dark' : 'light';
		this.themeChange.emit(this._theme);
	}

	public getClass(icon: 'sun' | 'moon'): string {
		const className = `plc-theme-switcher__${icon}`;

		if (icon === 'sun' && this._theme === 'light')
			return `${className} ${className}--choosen`;
		else if (icon === 'moon' && this._theme === 'dark')
			return `${className} ${className}--choosen`;

		return className;
	}
}
