<span #clueTextWrapper class="plc-clue-timer__clue">
	<span
		#clueDefaulText
		*ngIf="!(bannerMode$ | async)"
		class="plc-clue-timer__clue__text--default"
	>
		{{ clue }}
	</span>
	<span
		*ngIf="bannerMode$ | async"
		class="plc-clue-timer__clue__text--animated"
	>
		{{ clue }}
	</span>
</span>

<span *ngIf="showHits$ | async" class="plc-clue-timer__hits">
	{{ hits }}
</span>

<span class="plc-clue-timer__timer"> {{ time }} </span>
