import {
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';

export const TO_CIRCLE_EDGE_ANIMATION_DELAY = 3000;
const DEFAULT_INITIAL_ANGLE = 265;
const TOTAL_ANGLE = 360;

@Directive({
	selector: '[plcToCircleEdge]',
})
export class ToCircleEdgeDirective implements OnInit, OnDestroy {
	@Input({ required: true }) public total: number;
	@Input({ required: true }) public index: number;
	@Input() public initialAngle: number = DEFAULT_INITIAL_ANGLE;
	@Input() public animationActive = true;

	private _timeout: NodeJS.Timeout;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
	) {}

	ngOnInit() {
		const element = this.el.nativeElement;
		const { parentNode: parent } = element;

		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		const radius = parent.clientWidth / 2;
		const deg =
			(TOTAL_ANGLE / this.total) * (this.index + 1) + this.initialAngle;

		if (this.animationActive)
			this.renderer.setStyle(
				element,
				'transition',
				`${TO_CIRCLE_EDGE_ANIMATION_DELAY}ms`,
			);

		this.renderer.setStyle(
			element,
			'transform',
			`rotate(${deg}deg) translate(${radius}px, 0px) rotate(-${deg}deg)`,
		);

		this._timeout = setTimeout(
			() => this.renderer.setStyle(element, 'transition', `none`),
			TO_CIRCLE_EDGE_ANIMATION_DELAY,
		);
	}

	ngOnDestroy() {
		clearTimeout(this._timeout);
	}
}
