import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
} from '@angular/core';
import { Lang } from '@api/models/enums';
import { plcWidthIncreaseAnimation } from '@components/animations/size-increate.animation';
import { PlcIcon } from '@components/models/icon.models';
import { PlcFlagPickerOption } from '@components/modules/common/flag-picker/flag-picker.component';
import { Theme } from '@components/modules/common/theme-switcher/theme-switcher.models';

import {
	FLAG_PICKER_OPTIONS,
	MAX_VOLUME,
	PlcToolbarAction,
	PlcToolbarData,
	PlcToolbarEntry,
	VOLUME_VALUES,
} from '../toolbar.models';

@Component({
	selector: 'plc-toolbar-mobile',
	templateUrl: './toolbar-mobile.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toolbar-mobile' },
	animations: [plcWidthIncreaseAnimation],
})
export class ToolbarMobileComponent {
	@HostBinding('class') public get toggledClass(): string {
		return this._sideMenuIsToggled ? 'plc-toolbar-mobile--toggled' : '';
	}

	@Input({ required: true }) public year: number;
	@Input({ required: true }) public environment: string;
	@Input({ required: true }) public userData: PlcToolbarData;
	@Input({ required: true }) public entries: PlcToolbarEntry<any>[];
	@Input({ required: true }) public volume: number;
	@Input() public theme: Theme = 'light';
	@Input() public lang: Lang = Lang.Gl;

	@Output() public pressOption: EventEmitter<PlcToolbarAction> =
		new EventEmitter();
	@Output() public themeChange: EventEmitter<Theme> = new EventEmitter();
	@Output() public volumeChange: EventEmitter<number> = new EventEmitter();
	@Output() public langChange: EventEmitter<Lang> = new EventEmitter();

	public volumeValues: number[] = VOLUME_VALUES;
	public options: PlcFlagPickerOption[] = FLAG_PICKER_OPTIONS;
	public get selectedLang(): PlcFlagPickerOption {
		return this.options.find(({ lang }) => lang === this.lang);
	}

	public get sideMenuIsToggled(): boolean {
		return this._sideMenuIsToggled;
	}

	public get volumeIcon(): PlcIcon {
		if (this.volume === 0) return 'speaker-mute';
		if (this.volume > 0 && this.volume < MAX_VOLUME) return 'speaker-low';
		if (this.volume === MAX_VOLUME) return 'speaker-high';
	}

	private _sideMenuIsToggled = false;

	public emitOption(action: PlcToolbarAction): void {
		if (action !== 'back') this.toggleSideMenu();

		this.pressOption.emit(action);
	}

	public emitThemeChange(theme: Theme): void {
		this.theme = theme;
		this.themeChange.emit(theme);
	}

	public emitVolumeChange(volume: number): void {
		this.volume = +volume;
		this.volumeChange.emit(volume);
	}

	public emitLangChange(option: PlcFlagPickerOption): void {
		this.langChange.emit(option.lang);
	}

	public stopEvent(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}

	public toggleSideMenu(): void {
		this.pressOption.emit('toggle');
		this._sideMenuIsToggled = !this._sideMenuIsToggled;
	}
}
