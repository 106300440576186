/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	Host,
	Input,
	Optional,
	SkipSelf,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
	PlcBaseFormControl,
	PlcInputType,
} from '@components/models/foms.models';

@Component({
	selector: 'plc-text-input',
	templateUrl: './text-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextInputComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-text-input' },
})
export class TextInputComponent extends PlcBaseFormControl {
	@ViewChild('input') public inputEl: ElementRef<HTMLInputElement>;

	@Input({ required: true }) public placeholder: string;
	@Input({ required: true }) public label: string;
	@Input() public set type(type: PlcInputType) {
		this._type = type;
		this.passwordIcon = this._type === 'password' ? 'eye' : null;
	}

	public passwordIcon: 'eye' | 'eye-closed';

	public get wrapperClass(): string {
		const classes: string[] = [];

		if (this.errors && this.errors.length > 0)
			classes.push('plc-text-input__wrapper--invalid');
		else if (this._control && this._control.dirty)
			classes.push('plc-text-input__wrapper--valid');

		return classes.join(' ');
	}

	public get type(): PlcInputType {
		return this._showPassword ? 'text' : this._type;
	}

	public get labelClass(): string {
		const classes: string[] = [];

		if (this._focused || this._value)
			classes.push('plc-text-input__wrapper__label--focus');

		if (this._control && this._control.validator) {
			const validator = this._control.validator({} as AbstractControl);
			if (validator && validator.required)
				classes.push('plc-text-input__wrapper__label--required');
		}

		return classes.join(' ');
	}

	private _type: PlcInputType;
	private _showPassword: boolean;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
	) {
		super(controlContainer, cdr);
	}

	public forceFocus(): void {
		this.inputEl.nativeElement.focus();
	}

	public togglePassword(): void {
		this._showPassword = !this._showPassword;
		this.passwordIcon = this._showPassword ? 'eye-closed' : 'eye';
		this._type = this._showPassword ? 'text' : 'password';
	}
}
