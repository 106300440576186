import { Lang } from '@api/models/enums';
import { PlcIcon } from '@components/models/icon.models';
import { PlcFlagPickerOption } from '@components/modules/common/flag-picker/flag-picker.component';

export type PlcToolbarData = {
	name: string;
	avatarUrl: string;
};

export type PlcToolbarAction =
	| 'home'
	| 'sign-in'
	| 'log-in'
	| 'profile'
	| 'settings'
	| 'logout'
	| 'new-game'
	| 'search-game'
	| 'toggle'
	| 'back'
	| 'fullscreen'
	| 'about'
	| 'exit-fullscreen';

export type PlcAdminToolbarAction =
	| 'back'
	| 'toggle'
	| 'song-metrics'
	| 'word-metrics'
	| 'memory-word-group-metrics';

export interface PlcToolbarEntry<A> {
	action: A;
	icon: PlcIcon;
	name: string;
}

/** Values from 0 to 10, both included */
const TOTAL_VOLUMES = 11;
export const MAX_VOLUME = 10;
export const VOLUME_VALUES = [...Array(TOTAL_VOLUMES).keys()];

export const FLAG_PICKER_OPTIONS: PlcFlagPickerOption[] = [
	{
		name: 'gl',
		lang: Lang.Gl,
	},
	{
		name: 'es',
		lang: Lang.Es,
	},
	{
		name: 'us',
		lang: Lang.En,
	},
];
