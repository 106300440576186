import isMobile, { isMobileResult } from 'ismobilejs';

import { Injectable } from '@angular/core';
import { PlcError } from '@core/models/common/error.entity';

@Injectable()
export class PlcPlatform {
	public get isPhone(): boolean {
		return isMobile(this.window.navigator).phone;
	}

	public get isIOS(): boolean {
		return isMobile(this.window.navigator).apple.device;
	}

	public get fullScreenIsToggled(): boolean {
		return !!this.document.fullscreenElement;
	}

	private _device: isMobileResult;

	public get document(): Document {
		return this.window.document;
	}

	constructor(private window: Window) {
		this.detectDevice();
	}

	public isTablet(): boolean {
		return this._device.tablet;
	}

	public isDesktop(): boolean {
		return !this._device.phone && !this._device.tablet;
	}

	public detectDevice(): void {
		this._device = isMobile(this.window.navigator);
	}

	public requestFullScreen(): void {
		if (!!this.document.fullscreenEnabled)
			this.document.documentElement.requestFullscreen?.();
		else throw new PlcError('errors.no-fullscreen');
	}

	public exitFullScreen(): void {
		if (!!this.document.fullscreenEnabled) this.document.exitFullscreen?.();
		else throw new PlcError('errors.no-fullscreen');
	}
}
