<section
	*ngFor="let option of filteredOptions"
	class="plc-toggle-button-three__option"
	[class]="selectedClass(option.key)"
	(click)="select(option.key)"
>
	<div
		*ngFor="let item of option.items"
		class="plc-toggle-button-three__option__item"
	>
		<img
			*ngIf="item.type === 'img'"
			class="plc-toggle-button-three__option__item__img"
			[src]="item.value"
		/>
		<span *ngIf="item.type === 'text'">
			{{ item.value }}
		</span>
	</div>
</section>
