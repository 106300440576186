import { PhaseKind } from '@api/models/enums';

export enum PlcPublicRoute {
	Home = '/public/home',
	About = '/public/about',
	Tutorial = '/public/tutorial',
	NotAuth = '/public/auth/not-authorized',
	FastLogin = '/public/auth/fast-login',
	Login = '/public/auth/log-in',
	SignIn = '/public/auth/sign-in',
	ResetPassword = '/public/auth/reset-password',
	IOSFullScreen = '/public/ios-fullscreen',
}

export type PlcResetPassFormStep = 'email' | 'code' | 'new-pass' | 'confirm';
export const PLC_RESET_PASS_FORM_STEPS: PlcResetPassFormStep[] = [
	'email',
	'code',
	'new-pass',
	'confirm',
];

export enum PlcPrivateRoute {
	NewGame = '/private/new-game',
	SearchGame = '/private/search-game',
	Profile = '/private/profile',
	ProfileEdit = '/private/profile/edit',
	ProfileAvatarEdit = '/private/profile/avatar-edit',
}

export enum PlcGameRoute {
	Phase = '/private/games/${gameId}/phase/${kind}/${page}',
	RolePicker = '/private/games/${gameId}/role-picker',
}

export type PlcGamePhasePage =
	| 'preview'
	| 'dashboard'
	| 'player'
	| 'viewer'
	| 'result';

export type PlcGamePathsPhaseParams = {
	gameId: string;
	kind: PhaseKind;
	page: PlcGamePhasePage;
};

export type PlcGamePathsGamePickerParams = {
	gameId: string;
};
