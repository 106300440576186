import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import { PlcIcon } from '@components/models/icon.models';

export type PlcDisclaimerType = 'info' | 'warn' | 'success';

@Component({
	selector: 'plc-disclaimer',
	templateUrl: './disclaimer.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-disclaimer' },
})
export class DisclaimerComponent {
	@HostBinding('class') public get typeClass(): string {
		return `plc-disclaimer--${this.type}`;
	}

	@Input({ required: true }) public text: string;
	@Input() public type: PlcDisclaimerType = 'info';

	public get icon(): PlcIcon {
		if (this.type === 'info') return 'info';
		else if (this.type === 'warn') return 'exclamation';
		else if (this.type === 'success') return 'tick';
	}
}
