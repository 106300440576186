import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	effect,
	forwardRef,
	Host,
	HostBinding,
	Injector,
	Input,
	Optional,
	QueryList,
	SkipSelf,
} from '@angular/core';
import {
	ControlContainer,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { plcAccordionAnimation } from '@components/animations/accordion.animation';
import { PlcBaseFormControl } from '@components/models/foms.models';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';

import { CheckPanelComponent } from './check-panel.component';

@Component({
	selector: 'plc-check-panel-group',
	templateUrl: './check-panel-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckPanelGroupComponent),
			multi: true,
		},
	],
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-check-panel-group' },
	animations: [plcAccordionAnimation],
})
export class CheckPanelGroupComponent
	extends PlcBaseFormControl
	implements AfterContentInit, PlcUnsubscribable, ControlValueAccessor
{
	@HostBinding('@plcAccordionAnimation')
	public get hostAnimation(): number {
		return this.checkPanels ? this.checkPanels.toArray().length : 0;
	}

	@HostBinding('class')
	public get hostClass(): string {
		const classes: string[] = [`plc-check-panel-group--${this.direction}`];

		if (this._isDisabled) classes.push('plc-check-panel-group--disabled');

		return classes.join(' ');
	}

	@ContentChildren(CheckPanelComponent)
	public checkPanels: QueryList<CheckPanelComponent>;

	@Input() public direction: 'vertical' | 'horizontal' = 'vertical';
	@Input() public selectionMultiple = true;

	protected _value: unknown[];

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
		private injector: Injector,
	) {
		super(controlContainer, cdr);
	}

	ngAfterContentInit(): void {
		const timeout = setTimeout(() => {
			this.checkPanels.toArray().forEach((checkPanel) => {
				if (this._value.includes(checkPanel.value)) checkPanel.check();

				let skipFirst = true;
				if (this._value.includes(checkPanel.value)) checkPanel.check();

				effect(
					() => {
						const checked = checkPanel.checked();

						if (skipFirst) {
							skipFirst = false;
							return;
						}

						if (this.selectionMultiple) {
							const newValue = checked
								? this._value.concat(checkPanel.value)
								: this._value.filter(
										(value) => value !== checkPanel.value,
									);

							this.writeValue(newValue);
						} else {
							this.writeValue([checkPanel.value]);
							this.checkPanels
								.toArray()
								.filter(
									({ value }) => this._value.at(0) !== value,
								)
								.forEach((otherCheckPanel) =>
									otherCheckPanel.uncheckQuiet(),
								);
						}
					},
					{ injector: this.injector, allowSignalWrites: true },
				);
			});

			clearTimeout(timeout);
		}, 0);
	}

	writeValue(value: any): void {
		this._value = value ?? [];
		this.onTouch();
		this.onChange(this._value);
	}
}
