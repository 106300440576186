import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnChanges,
} from '@angular/core';
import { PlcIcon } from '@components/models/icon.models';

import { TabsService } from './tabs.service';

@Component({
	selector: 'plc-tab',
	templateUrl: './tab.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-tab' },
})
export class TabComponent implements OnChanges {
	@HostBinding('class.plc-tab--hidden')
	public get hiddenClass(): boolean {
		return this.hidden;
	}

	@Input() public title?: string;
	@Input({ required: true }) public icon: PlcIcon;
	@Input() public id?: string;

	public hidden = true;

	constructor(private tabsService: TabsService) {}

	ngOnChanges() {
		this.tabsService.emitTitlesUpdated();
	}
}
