import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
	constructor(private window: Window) {}

	public save<T>(key: SessionStorageKey, value: T): void {
		let valueToStore;
		if (this.isPrimitive(value)) valueToStore = value;
		else valueToStore = JSON.stringify(value);

		this.window.sessionStorage.setItem(key, valueToStore);
	}

	public get<T>(key: SessionStorageKey): T {
		const val = this.window.sessionStorage.getItem(key);

		try {
			return JSON.parse(val) as T;
		} catch (error) {
			return val as unknown as T;
		}
	}

	private isPrimitive(value: any): boolean {
		return value !== Object(value);
	}
}

export enum SessionStorageKey {
	Users = 'users',
	Auth = 'auth',
	Wizard = 'wizard',
	Games = 'games',
}
