<span class="plc-role-card__name"> {{ roleName | translate }} </span>

<div class="plc-role-card__main" [class]="mainClass" (click)="handleClick()">
	<plc-icon [name]="roleIcon" [reversed]="true" [size]="'l'" />

	<plc-avatar [src]="avatar" [shape]="'circle'" [reverseFilter]="true" />

	<plc-icon [name]="'dot'" [color]="statusColor" [size]="'s'" />
	<plc-label
		class="plc-role-card__main__name"
		[title]="name | translate"
		[reversedColors]="true"
	/>
</div>
