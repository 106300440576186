<section class="plc-stepper__wrapper">
	<div *ngFor="let step of steps; let i = index" class="plc-stepper__step">
		<span class="plc-stepper__step__dot" [class]="dotClass(i)"> </span>

		<p class="plc-stepper__step__name">{{ step | translate }}</p>

		<span
			*ngIf="i < lastStep"
			class="plc-stepper__step__line"
			[class]="lineClass(i)"
		>
		</span>
	</div>
</section>

<span class="plc-stepper__name-mobile">
	{{ currentStepName | translate }}
</span>
