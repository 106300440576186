<header #header plcHiddenIfChildsAreEmpty class="plc-box__header">
	<section class="plc-box__header__left">
		<ng-content select="[header-left]"></ng-content>
	</section>
	<section class="plc-box__header__center">
		<ng-content select="[header-center]"></ng-content>
	</section>
	<section class="plc-box__header__right">
		<ng-content select="[header-right]"></ng-content>
	</section>
</header>

<header #subHeader class="plc-box__sub-header">
	<ng-content select="[sub-header]"></ng-content>
</header>

<main class="plc-box__main" [class]="mainClass" (scroll)="handleScroll($event)">
	<ng-content select="[main]"></ng-content>
</main>

<footer plcHiddenIfChildsAreEmpty class="plc-box__footer">
	<section class="plc-box__footer__left">
		<ng-content select="[footer-left]"></ng-content>
	</section>
	<section class="plc-box__footer__right">
		<ng-content select="[footer-right]"></ng-content>
	</section>
</footer>
