import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	OnDestroy,
	Output,
	QueryList,
} from '@angular/core';

import { TabComponent } from './tab.component';
import { TabsService } from './tabs.service';

type TabHeaderInfo = {
	title: string;
	icon: string;
	id?: string;
};

@Component({
	selector: 'plc-tabs',
	templateUrl: './tabs.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-tabs' },
})
export class TabsComponent implements AfterContentInit, OnDestroy {
	@Output() public select: EventEmitter<string> = new EventEmitter();
	@ContentChildren(TabComponent) public tabQueryList: QueryList<TabComponent>;

	public tabsHeaders: Array<TabHeaderInfo>;
	public get selectedTab(): number {
		return this._selectedTab;
	}

	public tabClass(index: number): string {
		return this._selectedTab == index
			? 'plc-tabs__header__tab--selected'
			: '';
	}

	private _selectedTab = 0;
	private _unsubscribe$: Subject<void> = new Subject();

	constructor(
		private tabsService: TabsService,
		private cdr: ChangeDetectorRef,
	) {}

	ngAfterContentInit() {
		this.setTabsHeaders();
		this.displayTabContent(0);
		this.emitTabSelection(0);

		this.tabsService.titlesUpdated
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				setTimeout(() => {
					this.setTabsHeaders();
					this.displayTabContent(this._selectedTab);
				}, 0);
			});
	}

	ngOnDestroy() {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	public handleTabClick(index: number): void {
		this._selectedTab = index;

		this.displayTabContent(index);
		this.emitTabSelection(index);
	}

	private emitTabSelection(index: number): void {
		const { id } = this.tabsHeaders[index];
		if (id) this.select.emit(id);
	}

	private displayTabContent(index: number): void {
		this.tabQueryList.toArray().forEach((t, i) => {
			if (i === index) t.hidden = false;
			else t.hidden = true;
		});
	}

	private setTabsHeaders(): void {
		this.tabsHeaders = this.tabQueryList
			.toArray()
			.map(({ title, icon, id }) => ({ title, icon, id }));
		this.cdr.markForCheck();
	}
}
