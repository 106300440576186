import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
	SongPhaseClueUpdatedEvent,
	SongPhaseEventType,
	SongPhaseFinishedEvent,
	SongPhaseRoundUpdatedEvent,
} from '@core/models/games/events/song-phase.events';

@Injectable()
export class SongPhaseSockets {
	public clueUpdated$: Observable<SongPhaseClueUpdatedEvent> =
		this.socket.fromEvent<SongPhaseClueUpdatedEvent>(
			SongPhaseEventType.ClueUpdated,
		);

	public roundUpdated$: Observable<SongPhaseRoundUpdatedEvent> =
		this.socket.fromEvent<SongPhaseRoundUpdatedEvent>(
			SongPhaseEventType.RoundUpdated,
		);

	public finished$: Observable<SongPhaseFinishedEvent> =
		this.socket.fromEvent<SongPhaseFinishedEvent>(
			SongPhaseEventType.Finished,
		);

	constructor(private socket: Socket) {}
}
