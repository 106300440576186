import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';

@Component({
	selector: 'plc-icon-badge',
	templateUrl: './icon-badge.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-icon-badge' },
})
export class IconBadgeComponent {
	@HostBinding('class') public get class(): string {
		return `plc-icon-badge--${this.size}`;
	}

	@Input({ required: true }) public icon: string;
	@Input() public counter = 0;
	@Input() public size: 'small' | 'medium' | 'big' = 'medium';

	public get src(): string {
		return `assets/icons/${this.icon}.svg`;
	}

	public get iconClass(): string {
		return `plc-icon-badge__icon--${this.size}`;
	}
	public get badgeClass(): string {
		return `plc-icon-badge__badge--${this.size}`;
	}

	public get formattedCounter(): string {
		return this.counter > 99 ? '+99' : `${this.counter}`;
	}
}
