/* eslint-disable @typescript-eslint/member-ordering */
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
	PlcTutorialStage,
	PlcTutorialStageId,
} from '@bussiness/custom-models/wizard.custom-models';
import {
	LoaderActions,
	LoaderToggleAction,
} from '@bussiness/store/components/loader/loader.actions';
import {
	ToastActions,
	ToastActionTypes,
	ToastSendMessageAction,
} from '@bussiness/store/components/toast/toast.actions';
import { WizardActions } from '@bussiness/store/components/wizard/wizard.actions';
import { WizardSelectors } from '@bussiness/store/components/wizard/wizard.selectors';
import { WizardTooltipConfig } from '@components/modules/common/wizard/wizard.models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { LoaderSelectors } from './loader/loader.selectors';

@Injectable()
export class ComponentsFacade {
	// Actions
	public get toastSendMessageAction$(): Observable<ToastSendMessageAction> {
		return this.actions$.pipe(ofType(ToastActionTypes.SendMessage));
	}

	public loaderToggleAction$: Observable<LoaderToggleAction> =
		this.actions$.pipe(ofType(LoaderActions.toggle));

	// Selectors
	public wizardConfig(route: string): Observable<WizardPageConfig> {
		return this.store.select(WizardSelectors.selectConfig(route));
	}

	public loaderReasons$: Observable<string[]> = this.store.select(
		LoaderSelectors.reasons,
	);

	public tutorial$: Observable<PlcTutorialStage[]> = this.store.select(
		WizardSelectors.tutorial,
	);

	constructor(
		private actions$: Actions,
		private store: Store,
	) {}

	public toastSendMessage(message: ToastSendMessageAction): void {
		this.store.dispatch(ToastActions.sendMessage(message));
	}

	public wizardHide(name: string): void {
		this.store.dispatch(WizardActions.hide({ name }));
	}

	public wizardAddConfig(configuration: WizardPageConfig): void {
		this.store.dispatch(WizardActions.addConfig({ configuration }));
	}

	public loaderToggle(display: boolean): void {
		this.store.dispatch(LoaderActions.toggle({ display }));
	}

	public tutorialStart(id: PlcTutorialStageId): void {
		this.store.dispatch(WizardActions.tutorialStart({ stageId: id }));
	}
}

export enum WizardPage {
	Home = 'home',
	RolePicker = 'role-picker',
	Profile = 'profile',
	AvatarEdit = 'avatar-edit',
	SearchGame = 'search-game',
	BagelDashboard = 'bagels/dashboard',
	BagelPlayer = 'bagels/player',
	BagelViewer = 'bagels/viewer',
	MemoryDashoard = 'memory/dashboard',
	MemoryPlayer = 'memory/player',
	SongDashboard = 'song/dashboard',
	SongPlayer = 'song/player',
	SoupsDashboard = 'soups/dashboard',
}

export class WizardPageConfig {
	public name: WizardPage;
	public show?: boolean = false;
	public tooltips: WizardTooltipConfig[];
}
