import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

export type WizardMessage = {
	title: string;
	text: string;
	type: 'info' | 'error' | 'warning';
};

@Injectable()
export class ToastService {
	public get messages$(): Observable<Array<WizardMessage>> {
		return this._messages$.asObservable();
	}

	private _messages$: BehaviorSubject<Array<WizardMessage>>;
	private _messages: Array<WizardMessage>;
	private _interval: NodeJS.Timeout;

	constructor() {
		this._messages = [];
		this._messages$ = new BehaviorSubject(this._messages);
	}

	public showInfo(text: string, title: string): void {
		this.addMessage(text, title, 'info');
	}

	public showError(text: string, title: string): void {
		this.addMessage(text, title, 'error');
	}

	public showWarning(text: string, title: string): void {
		this.addMessage(text, title, 'warning');
	}

	public closeMessage(index: number): void {
		this._messages.splice(index, 1);
		this._messages$.next(this._messages);
	}

	private addMessage(
		text: string,
		title: string,
		type: 'info' | 'error' | 'warning',
	): void {
		if (this._messages.length === 0) {
			this._interval = setInterval(() => {
				this._messages.pop();
				this._messages$.next(this._messages);
				if (this._messages.length === 0) clearInterval(this._interval);
			}, 3000);
		}

		const message: WizardMessage = { title, text, type };
		this._messages.unshift(message);
		this._messages$.next(this._messages);
	}
}
