import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import {
	PlcToggleButtonOption,
	PlcToggleButtonPlayerOption,
	PlcToggleButtonTeamOption,
} from './toggle-button.models';

/**
 * @deprecated use Toggle Button Three component instead
 */
@Component({
	selector: 'plc-toggle-button',
	templateUrl: './toggle-button.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toggle-button' },
})
export class ToggleButtonComponent implements OnInit, OnChanges {
	@HostBinding('class')
	public get imageOnlyClass(): string {
		return this.imageOnly ? 'plc-toggle-button--image-only' : '';
	}

	@Input({ required: true }) public disabled: boolean;
	@Input({ required: true }) public imageOnly: boolean;
	@Input({ required: true }) public options: Array<PlcToggleButtonOption>;
	@Output() public toggle: EventEmitter<string | number> = new EventEmitter();

	public get optionsToDisplay(): Array<PlcToggleButtonOption> {
		if (!this.options) return;

		const options = new Array<PlcToggleButtonOption>();

		if (!this._teamMode) {
			options.push(...this.options);
		} else {
			const [o1, o2] = this.options;
			options.push(
				{
					avatar: 'assets/icons/blueberry.svg',
					name: 'Azul',
					value: o1.value,
				},
				{
					avatar: 'assets/icons/orange.svg',
					name: 'Naranja',
					value: o2.value,
				},
			);
		}

		return options;
	}

	private _teamMode: boolean;
	private _selectedOption: 0 | 1;

	constructor() {
		console.warn(
			'Component plc-toggle-button is deprecated. Use plc-toggle-button-three instead',
		);
	}

	ngOnInit() {
		if (!this.options) throw Error('The "options" input cannot be null!');

		this._teamMode = this.areTeamMode(this.options);
		this._selectedOption = 0;
	}

	ngOnChanges(changes: SimpleChanges) {
		const { options } = changes;
		if (!options) throw Error('The "options" input cannot be null!');

		const { currentValue } = options;
		if (!currentValue) throw Error('The "options" input cannot be null!');

		this._teamMode = this.areTeamMode(currentValue);
	}

	public select(index: 0 | 1): void {
		this._selectedOption = index;
		this.toggle.emit(this.options[index].value);
	}

	public getOptionClasses(index: 0 | 1): string {
		const classes = new Array<string>();

		const selectedClass = 'plc-toggle-button__option--not-selected';
		const isSelected = this._selectedOption === index;
		if (isSelected) classes.push(selectedClass);

		if (this.imageOnly)
			classes.push('plc-toggle-button__option--image-only');

		return classes.join(' ');
	}

	private areTeamMode(
		options: Array<PlcToggleButtonOption>,
	): options is Array<PlcToggleButtonTeamOption> {
		return (options as Array<PlcToggleButtonPlayerOption>).every(
			(o) => !o.name && !o.avatar,
		);
	}
}
