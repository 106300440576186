<label class="plc-checkbox">
	<input
		class="plc-checkbox__input"
		type="checkbox"
		[checked]="checked"
		[disabled]="isDisabled"
		(change)="handleCheck()"
	/>

	<span class="plc-checkbox__checkmark" *ngIf="isCheck"></span>
	<span class="plc-checkbox__slider" *ngIf="isSlide"></span>

	<ng-content></ng-content>
</label>
