/* eslint-disable @typescript-eslint/member-ordering */
import { filter } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	DialogService,
	PlcDialogToggleParams,
} from '@components/modules/common/dialog/dialog.service';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';

@Component({
	selector: 'plc-raising-panel',
	templateUrl: './raising-panel.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-raising-panel' },
})
export class RaisingPanelComponent
	implements OnInit, OnDestroy, PlcUnsubscribable
{
	@HostBinding('class')
	public get className(): string {
		return this._show ? 'plc-raising-panel--toggled' : '';
	}

	@HostListener('mouseleave')
	public clickout(): void {
		this._show = false;
	}

	@Input() public title: string;
	@Input() public name: string;

	public destroyRef: DestroyRef = inject(DestroyRef);
	private _show: boolean;

	constructor(private dialogService: DialogService) {}

	ngOnInit() {
		this.dialogService.add(this.name);

		this.dialogService.toggle$
			.pipe(
				filter(({ name }: PlcDialogToggleParams) => name === this.name),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(({ action }: PlcDialogToggleParams) => {
				this._show = action === 'show';
			});
	}

	ngOnDestroy() {
		this.dialogService.remove(this.name);
	}

	public close(event?: MouseEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.dialogService.toggle({ name: this.name, action: 'hide' });
	}
}
