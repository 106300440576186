/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { plcHeightIncreaseAnimation } from '@components/animations/size-increate.animation';
import {
	PlcValidation,
	PlcValidationError,
} from '@components/models/foms.models';
import { TranslateService } from '@ngx-translate/core';

export type ValidationMessagesViewMode = 'none' | 'all' | 'first';

const ANIMATION_DURATION = 400;

@Component({
	selector: 'plc-validation-messages',
	templateUrl: './validation-messages.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [plcHeightIncreaseAnimation],
	host: { class: 'plc-validation-messages' },
})
export class ValidationMessagesComponent implements OnChanges {
	@HostBinding('class')
	public get hostDisplay(): string {
		return this._hostClass;
	}

	@Input() public viewMode: ValidationMessagesViewMode = 'all';
	@Input({ required: true }) public validations: PlcValidation[];
	@Input({ required: true }) public errors: PlcValidationError[];

	public trackByError = (val: PlcValidation) => val.error;

	public get hideMode(): boolean {
		return this.viewMode === 'none';
	}

	public get validationsToDisplay(): PlcValidation[] {
		let validationsToDisplay: PlcValidation[] = [];
		if (this.viewMode === 'all') validationsToDisplay = this.validations;
		if (this.viewMode === 'first')
			validationsToDisplay = [
				this.validations.find(
					({ error }) => error === this.errors.at(0).error,
				),
			];

		return validationsToDisplay.map((validation) => {
			const { interpolatedParams } = validation;
			if (!interpolatedParams) return validation;

			const i18nIntParams = Object.entries(interpolatedParams).map(
				([key, value]: [string, string | number]) => [
					key,
					value && typeof value !== 'number'
						? this.transService.instant(value)
						: value,
				],
			);

			return {
				...validation,
				interpolatedParams: Object.fromEntries(i18nIntParams),
			};
		});
	}

	private _hostClass: string;

	constructor(
		private cdr: ChangeDetectorRef,
		private transService: TranslateService,
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		const { viewMode } = changes;

		if (viewMode) {
			const { currentValue } = viewMode;
			const displayModeNone = currentValue === 'none';

			timer(displayModeNone ? ANIMATION_DURATION : 0)
				.pipe(take(1))
				.subscribe(() => {
					this._hostClass = displayModeNone
						? 'plc-validation-messages--hide-mode'
						: '';
					this.cdr.markForCheck();
				});
		}
	}
}
