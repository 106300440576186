import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { Lang } from '@api/models/enums';
import { Theme } from '@components/modules/common/theme-switcher/theme-switcher.models';

import {
	PlcToolbarAction,
	PlcToolbarData,
	PlcToolbarEntry,
} from '../toolbar.models';

@Component({
	selector: 'plc-toolbar-desktop',
	templateUrl: './toolbar-desktop.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toolbar-desktop' },
})
export class ToolbarDesktopComponent {
	@Input({ required: true }) public userData: PlcToolbarData;
	@Input({ required: true }) public entries: PlcToolbarEntry<any>[];
	@Input({ required: true }) public volume: number;
	@Input() public theme: Theme = 'light';
	@Input() public lang: Lang = Lang.Gl;

	@Output() public pressOption: EventEmitter<PlcToolbarAction> =
		new EventEmitter();
	@Output() public themeChange: EventEmitter<Theme> = new EventEmitter();
	@Output() public volumeChange: EventEmitter<number> = new EventEmitter();
	@Output() public langChange: EventEmitter<Lang> = new EventEmitter();

	public emitPressOption(option: PlcToolbarAction): void {
		this.pressOption.emit(option);
	}

	public emitThemeChange(theme: Theme): void {
		this.themeChange.emit(theme);
	}

	public emitVolumeChange(volume: number): void {
		this.volume = volume;
		this.volumeChange.emit(volume);
	}

	public emitLangChange(lang: Lang): void {
		this.langChange.emit(lang);
	}
}
