import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
	selector: '[plcFocus]',
})
export class FocusDirective implements OnChanges {
	@Input() public focus = false;

	constructor(private el: ElementRef) {}

	ngOnChanges() {
		if (this.focus) this.el.nativeElement.focus();
		else this.el.nativeElement.blur();
	}
}
