/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { PlcIcon } from '@components/models/icon.models';

@Component({
	selector: 'plc-button',
	templateUrl: './button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-button' },
})
export class ButtonComponent {
	@HostBinding('class')
	public get buttonClass(): string {
		const typeClass = `plc-button--${this.type}`;
		const classes = new Array<string>();
		classes.push(typeClass);

		if (!this.icon) classes.push('plc-button--only-text');
		else if (this.icon && this.onlyIcon)
			classes.push('plc-button--only-icon');

		if (this.flattened) classes.push('plc-button--flattened');

		if (this.disabled) classes.push('plc-button--disabled');

		return classes.join(' ');
	}

	@HostListener('click')
	public emitPress(): void {
		this.press.emit();
	}

	@Input() public disabled = false;
	@Input() public icon?: PlcIcon;
	@Input() public onlyIcon = false;
	@Input() public flattened = false;
	@Input() public type: 'primary' | 'secondary' | 'outline' = 'primary';

	@Output() public press: EventEmitter<void> = new EventEmitter();

	public get src(): string {
		return `assets/icons/${this.icon}.svg`;
	}

	public get iconClass(): string {
		return `plc-button--${this.type}__icon`;
	}
}
