import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
	constructor(private window: Window) {}

	public save(key: LocalStorageEnum, val: string | boolean): void {
		this.window.localStorage.setItem(key, val as string);
	}

	public get(key: LocalStorageEnum): string | boolean {
		return this.window.localStorage.getItem(key);
	}

	public remove(key: LocalStorageEnum): void {
		this.window.localStorage.removeItem(key);
	}

	public getAllTokens(): string[] {
		return [
			this.get(LocalStorageEnum.UserToken) as string,
			this.get(LocalStorageEnum.GameToken) as string,
			this.get(LocalStorageEnum.PlayerToken) as string,
		];
	}
}

export enum LocalStorageEnum {
	GameToken = 'game-token',
	UserToken = 'user-token',
	PlayerToken = 'player-token',
	HideWizard = 'hide-wizard',
}
