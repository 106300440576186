import { BehaviorSubject, Observable, timer } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	ViewChild,
} from '@angular/core';
import { Team } from '@api/models/enums';

const HITS_DISPLAY_DELAY = 500;

const CLUE_ANIMATION_DURATION = 700;

@Component({
	selector: 'plc-clue-timer',
	templateUrl: './clue-timer.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-clue-timer' },
})
export class ClueTimerComponent implements OnChanges {
	@HostBinding('class') public get teamClass(): string {
		const classes: string[] = [`plc-clue-timer--${this.team}`];

		if (this.hasHits) classes.push('plc-clue-timer--with-hits');

		return classes.join(' ');
	}

	@ViewChild('clueTextWrapper')
	public clueTextWrapperEl: ElementRef;

	@ViewChild('clueDefaulText')
	public clueDefaulTextEl: ElementRef;

	@Input({ required: true }) public clue: string;
	@Input({ required: true }) public time: number;
	@Input({ required: true }) public team: Team;
	@Input() public hits?: number;

	public get bannerMode$(): Observable<boolean> {
		return this._bannerMode$.asObservable();
	}

	public get showHits$(): Observable<boolean> {
		return this._showHits$.asObservable();
	}

	private get hasHits(): boolean {
		return this.hits !== null && this.hits !== undefined;
	}

	private _bannerMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _showHits$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	ngOnChanges(): void {
		this._bannerMode$.next(false);

		if (!this.hasHits) this._showHits$.next(false);

		timer(CLUE_ANIMATION_DURATION)
			.pipe(
				take(1),
				tap(() => {
					const clueTextWrapperEl =
						this.clueTextWrapperEl.nativeElement;
					const clueDefaulTextEl =
						this.clueDefaulTextEl.nativeElement;
					const clueTextWrapperWidth = clueTextWrapperEl.offsetWidth;
					const clueDefaulTextWidth = clueDefaulTextEl.offsetWidth;

					if (clueTextWrapperWidth < clueDefaulTextWidth)
						this._bannerMode$.next(true);
				}),
				delay(HITS_DISPLAY_DELAY),
				tap(() => this._showHits$.next(this.hasHits)),
			)
			.subscribe();
	}
}
