import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core';

@Component({
	selector: 'plc-file-picker',
	templateUrl: './file-picker.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-file-picker' },
})
export class FilePickerComponent implements OnInit {
	@Output() public select: EventEmitter<File> = new EventEmitter();
	@Output() public selectInvalid: EventEmitter<void> = new EventEmitter();

	public get fileName(): string {
		return this._currentFile ? this._currentFile.name : null;
	}

	private _currentFile: File;
	private _allowedExtensions: Array<string>;

	ngOnInit() {
		this._allowedExtensions = ['image/png', 'image/jpeg'];
	}

	public handleSelection(files: Array<File>): void {
		const [firstFile] = files;
		if (!firstFile) return;

		if (!this._allowedExtensions.includes(firstFile.type)) {
			this.selectInvalid.emit();
			return;
		}

		this._currentFile = firstFile;

		this.select.emit(firstFile);
	}
}
