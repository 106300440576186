import { Injectable } from '@angular/core';

import { Theme } from './theme-switcher.models';

@Injectable()
export class ThemeService {
	public setTheme(theme: Theme = 'light'): void {
		document.documentElement.setAttribute('data-theme', theme);
	}
}
