<img
	class="plc-flag-picker__flag"
	[src]="src(selected.name)"
	(click)="unfold()"
/>

<section
	@plcHeightIncrease
	class="plc-flag-picker__options plc-flag-picker__options--extended-row"
	*ngIf="rowUnfolded"
	[class]="optionsClass"
>
	<img
		class="plc-flag-picker__flag"
		*ngFor="let option of options"
		[src]="src(option.name)"
		(click)="emitChange(option)"
	/>
</section>

<section
	[@plcWidthIncrease]="{ value: ':enter', params: { size: 180 } }"
	class="plc-flag-picker__options plc-flag-picker__options--extended-column"
	*ngIf="columnUnfolded"
>
	<img
		class="plc-flag-picker__flag"
		*ngFor="let option of options"
		[src]="src(option.name)"
		(click)="emitChange(option)"
	/>
</section>
