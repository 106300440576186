/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';

export type AvatarShape = 'circle' | 'square' | 'default';

@Component({
	selector: 'plc-avatar',
	templateUrl: './avatar.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-avatar' },
})
export class AvatarComponent {
	@HostBinding('class')
	public get hostClass(): string {
		const classes: string[] = [];

		if (this.animated) classes.push('plc-avatar--animated');
		if (this.zoomed) classes.push('plc-avatar--zoomed');

		return classes.join(' ');
	}

	@Input({ required: true }) public set src(value) {
		this._src = value;
	}
	@Input() public shape: AvatarShape = 'default';
	@Input() public animated = true;
	@Input() public reverseFilter = false;
	@Input() public zoomed = false;

	public get imgClasses(): string {
		const classes: string[] = [`plc-avatar__img--${this.shape}`];

		const srcIsMissing = !this._src || this._src === '';
		if (srcIsMissing && !this.reverseFilter)
			classes.push('plc-avatar__img--no-src');
		else if (srcIsMissing && this.reverseFilter)
			classes.push('plc-avatar__img--no-src-reversed');

		return classes.join(' ');
	}

	public get src(): string {
		return !this._src || this._src === ''
			? 'assets/icons/user-circle.svg'
			: this._src;
	}

	private _src: string;
}
