import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
} from '@angular/core';
import { PlcFilter } from '@components/models/colors.models';
import {
	PLC_ICONS,
	PlcIcon,
	PlcIconAngle,
	PlcIconSize,
} from '@components/models/icon.models';

@Component({
	selector: 'plc-icon',
	templateUrl: './icon.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-icon' },
})
export class IconComponent implements OnInit {
	@HostBinding('class')
	public get hostClass(): string {
		const classes: string[] = [`plc-icon--${this.size}`];

		if (this.angle) classes.push(`plc-icon--${this.angle}`);

		return classes.join(' ');
	}

	@Input({ required: true }) public name: PlcIcon;
	@Input() public size: PlcIconSize = 'm';
	@Input() public color?: PlcFilter;
	@Input() public reversed = false;
	@Input() public hover = false;
	@Input() public hoverColor?: PlcFilter;
	@Input() public angle?: PlcIconAngle;

	public get src(): string {
		return `assets/icons/${this.name}.svg`;
	}

	public get imgClass(): string {
		const classes: string[] = [];
		if (this.hover) classes.push('plc-icon__img--hover');
		if (this.hover && this.hoverColor)
			classes.push(`plc-icon__img--hover--${this.hoverColor}`);

		if (this.color) classes.push(`plc-icon__img--${this.color}`);
		else if (this.reversed) classes.push('plc-icon__img--reversed');

		return classes.join(' ');
	}

	ngOnInit(): void {
		const iconFound = PLC_ICONS.some((icon) => icon.key === this.name);
		if (!iconFound)
			throw new Error(
				`Icon ${this.name} not found.\nExisting icons: ${PLC_ICONS.map(
					({ key }) => key,
				).join(', ')}`,
			);
	}
}
