<article
	*ngFor="let message of messages$ | async; let i = index"
	class="plc-toast__message"
	[class]="getTypeClass(message.type)"
>
	<span class="plc-toast__message__title"> {{ message.title }} </span>
	<span class="plc-toast__message__text"> {{ message.text }} </span>

	<plc-icon
		[name]="'cross'"
		[hover]="true"
		[color]="'white'"
		[hoverColor]="'black'"
		(click)="handleClose(i)"
	/>
</article>
