<section @plcHeightIncrease *ngIf="unfolded" class="plc-desktop-menu__dropdown">
	<span
		*ngFor="let entry of entries"
		class="plc-desktop-menu__dropdown__option"
		(click)="emitPressOption(entry.action)"
	>
		<img
			class="plc-desktop-menu__dropdown__option__icon"
			[src]="iconSrc(entry.icon)"
		/>
		{{ entry.name | translate }}
	</span>

	<span class="plc-desktop-menu__dropdown__volume">
		<plc-icon
			[name]="volumeIcon"
			[size]="'s'"
			[color]="'white'"
			[hover]="true"
			[hoverColor]="'black'"
			(click)="emitVolumeChange(0)"
		/>
		<plc-range-input
			[values]="volumeValues"
			[(ngModel)]="volume"
			(change)="emitVolumeChange($event.target.value)"
		/>
	</span>
</section>

<section *ngIf="userData" class="plc-desktop-menu__user-wrapper">
	<plc-flag-picker
		[dir]="'row'"
		[options]="options"
		[selected]="selectedLang"
		(mouseenter)="preventPropagation($event)"
		(mouseleave)="unfold()"
		(change)="emitLangChange($event)"
	/>

	<span>
		{{ 'components.layout.toolbars.welcome-back' | translate }}
	</span>

	<span class="plc-desktop-menu__user-wrapper__name">
		{{ userData.name }}
	</span>

	<plc-avatar [src]="avatarUrl" [shape]="'circle'" [reverseFilter]="true" />
</section>

<section *ngIf="!userData" class="plc-desktop-menu__no-user-wrapper">
	<plc-flag-picker
		[dir]="'row'"
		[options]="options"
		[selected]="selectedLang"
		(mouseenter)="preventPropagation($event)"
		(mouseleave)="unfold()"
		(change)="emitLangChange($event)"
	/>

	<plc-label [title]="'components.layout.toolbars.welcome' | translate">
		{{ 'components.layout.toolbars.create-account' | translate }}
	</plc-label>
</section>
