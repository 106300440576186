import { Injectable } from '@angular/core';
import {
	ElementInfo,
	FutureTooltipPosition,
	PointerType,
	TooltipPlacingInfo,
	TooltipSize,
	WindowSize,
} from '@components/modules/common/wizard/wizard.models';

const MARGIN = 25;

const HALF = 2;
@Injectable()
export class WizardService {
	public computePlacing(
		windowSize: WindowSize,
		tooltipSize: TooltipSize,
		elInfo: ElementInfo,
	): TooltipPlacingInfo {
		const { width: tooltipWidth, height: tooltipHeight } = tooltipSize;
		const {
			top,
			left,
			width: elementWidth,
			height: elementHeight,
		} = elInfo;

		const horizontalLimits = {
			max: left + elementWidth,
			min: left - tooltipWidth,
		};

		const verticalLimits = {
			max: top + elementHeight,
			min: top - tooltipHeight,
		};

		const rightPlace = {
			left: left + elementWidth + MARGIN,
			width: tooltipWidth,
			top: top - (tooltipHeight / HALF - elementHeight / HALF),
			height: tooltipHeight,
		};
		const outOfRightLimit = this.checkWindowLimits(
			rightPlace,
			windowSize,
			Direction.Right,
		);
		if (!outOfRightLimit)
			return {
				top: this.computeTopPlacement(
					rightPlace,
					windowSize,
					verticalLimits,
				),
				left: rightPlace.left,
				pointerType: PointerType.Left,
			};

		const bottomPlacingCandidate = {
			left: left + (elementWidth / HALF - tooltipWidth / HALF),
			width: tooltipWidth,
			top: top + elementHeight + MARGIN,
			height: tooltipHeight,
		};
		const outOfBottomLimit = this.checkWindowLimits(
			bottomPlacingCandidate,
			windowSize,
			Direction.Bottom,
		);
		if (!outOfBottomLimit)
			return {
				top: bottomPlacingCandidate.top,
				left: this.computeLeftPlacing(
					bottomPlacingCandidate,
					windowSize,
					horizontalLimits,
				),
				pointerType: PointerType.Top,
			};

		const leftPlace = {
			left: left - tooltipWidth - MARGIN,
			width: tooltipWidth,
			top: top - (tooltipHeight / HALF - elementHeight / HALF),
			height: tooltipHeight,
		};
		const outOfLeftLimit = this.checkWindowLimits(
			leftPlace,
			windowSize,
			Direction.Left,
		);
		if (!outOfLeftLimit)
			return {
				top: this.computeTopPlacement(
					leftPlace,
					windowSize,
					verticalLimits,
				),
				left: leftPlace.left,
				pointerType: PointerType.Right,
			};

		const topPlace = {
			left: left + (elementWidth / HALF - tooltipWidth / HALF),
			width: tooltipWidth,
			top: top - tooltipHeight - MARGIN,
			height: tooltipHeight,
		};
		const outOfTopLimit = this.checkWindowLimits(
			topPlace,
			windowSize,
			Direction.Top,
		);
		if (!outOfTopLimit)
			return {
				top: topPlace.top,
				left: this.computeLeftPlacing(
					topPlace,
					windowSize,
					horizontalLimits,
				),
				pointerType: PointerType.Bottom,
			};

		return {
			top: Math.abs(top),
			left: Math.abs(left),
			pointerType: PointerType.None,
		};
	}

	private checkWindowLimits(
		tooltipPosition: FutureTooltipPosition,
		windowSize: WindowSize,
		...directionsToCheck: Direction[]
	): boolean {
		const { top, left, width, height } = tooltipPosition;
		const { width: wizardWidth, height: wizardHeight } = windowSize;
		const outOfLimitDirections: Direction[] = [];

		if (left < 0) outOfLimitDirections.push(Direction.Left);
		if (left + width > wizardWidth)
			outOfLimitDirections.push(Direction.Right);
		if (top < 0) outOfLimitDirections.push(Direction.Top);
		if (top + height > wizardHeight)
			outOfLimitDirections.push(Direction.Bottom);

		return outOfLimitDirections.some((direction) =>
			directionsToCheck.includes(direction),
		);
	}

	private computeTopPlacement(
		initialPlacement: FutureTooltipPosition,
		windowSize: WindowSize,
		limits: Limits,
	): number {
		const { min, max } = limits;
		let candidatePlace = {
			...initialPlacement,
		};

		let outOfLimits = true;
		do {
			candidatePlace = {
				...initialPlacement,
				top: candidatePlace.top + 1,
			};
			outOfLimits = this.checkWindowLimits(
				candidatePlace,
				windowSize,
				Direction.Bottom,
				Direction.Top,
			);
		} while (outOfLimits && candidatePlace.top < max);

		if (outOfLimits) {
			do {
				candidatePlace = {
					...initialPlacement,
					top: candidatePlace.top - 1,
				};
				outOfLimits = this.checkWindowLimits(
					candidatePlace,
					windowSize,
					Direction.Bottom,
				);
			} while (outOfLimits && candidatePlace.top > min);
		}

		return candidatePlace.top;
	}

	private computeLeftPlacing(
		initialPlacement: FutureTooltipPosition,
		wizardSize: WindowSize,
		limits: Limits,
	): number {
		const { min, max } = limits;
		let candidatePlace = {
			...initialPlacement,
		};

		let outOfLimits = true;
		do {
			candidatePlace = {
				...initialPlacement,
				left: candidatePlace.left + 1,
			};
			outOfLimits = this.checkWindowLimits(
				candidatePlace,
				wizardSize,
				Direction.Right,
				Direction.Left,
			);
		} while (outOfLimits && candidatePlace.left < max);

		if (outOfLimits) {
			do {
				candidatePlace = {
					...initialPlacement,
					left: candidatePlace.left - 1,
				};
				outOfLimits = this.checkWindowLimits(
					candidatePlace,
					wizardSize,
					Direction.Right,
				);
			} while (outOfLimits && candidatePlace.left > min);
		}

		return candidatePlace.left;
	}
}

enum Direction {
	Left = 'left',
	Right = 'right',
	Top = 'top',
	Bottom = 'bottom',
}

interface Limits {
	max: number;
	min: number;
}
