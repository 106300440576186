<video autoplay [muted]="true" [srcObject]="srcObject" *ngIf="noError"></video>

<!-- <plc-button (press)="rotateCamera()" [icon]="'rotate-camera'" [onlyIcon]="true"/> -->

<div class="plc-webcam__error-wrapper" *ngIf="!noError">
	<span class="plc-webcam__error-wrapper__message" *ngIf="cameraIsNotAllowed">
		Tu dispositivo no está autorizando el uso de la cámara y el micrófono
	</span>
	<span class="plc-webcam__error-wrapper__message" *ngIf="cameraBusy">
		Otra aplicación está usando la cámara o el micrófono
	</span>
	<plc-button (press)="relaunchCamera()" [icon]="'refresh'">
		Reintentar
	</plc-button>
</div>
