/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { wizardFeatureKey, WizardState } from './wizard.reducer';

const selectWizard = createFeatureSelector<WizardState>(wizardFeatureKey);

export class WizardSelectors {
	public static selectConfig = (route: string) =>
		createSelector(selectWizard, (state: WizardState) => {
			const pageConfigFound = state.configurations.find(({ name }) =>
				route.includes(name),
			);

			if (!pageConfigFound) return null;

			if (pageConfigFound.show) return pageConfigFound;

			return null;
		});

	public static tutorial = createSelector(
		selectWizard,
		(state: WizardState) => {
			return state.tutorial;
		},
	);
}
