export class ObjectUtils {
	public static valuesAreEqual<T>(
		item1: T,
		item2: T,
		...keysToCompare: (keyof T)[]
	): boolean {
		for (const keyToCompare of keysToCompare) {
			if (item1[keyToCompare] !== item2[keyToCompare]) return false;
		}

		return true;
	}

	public static getKeyFrom(
		enumType: object,
		enumValue: string | number,
	): string {
		const entry = Object.entries(enumType).find(
			([, value]) => enumValue === value,
		);

		if (!entry) throw new Error(`Value ${enumValue} not found in enumType`);

		const [enumKey] = entry;
		return enumKey;
	}

	public static isDefined(value: unknown): boolean {
		return value !== undefined && value !== null;
	}
}
