import { PlcTutorialStageId } from '@bussiness/custom-models/wizard.custom-models';
import { WizardPageConfig } from '@bussiness/store/components/components-facade';
import { createAction, props } from '@ngrx/store';

export interface WizardAddConfigAction {
	configuration: WizardPageConfig;
}

export interface WizardHidedAction {
	name: string;
}

export interface WizardTutorialStartAction {
	stageId: PlcTutorialStageId;
}

export class WizardActions {
	public static addConfig = createAction(
		'[Wizard] Add Configuration',
		props<WizardAddConfigAction>(),
	);

	public static hide = createAction(
		'[Wizard] Hide',
		props<WizardHidedAction>(),
	);

	public static tutorialStart = createAction(
		'[Wizard] Tutorial Start',
		props<WizardTutorialStartAction>(),
	);
}
