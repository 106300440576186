/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
} from '@angular/core';

export type PlcToggleButtonThreeViewMode = 'full' | 'textOnly' | 'imgOnly';
export type PlcToggleButtonThreeValueType = 'text' | 'img';

export interface PlcToggleButtonThreeItem {
	type: PlcToggleButtonThreeValueType;
	value: string;
}

export interface PlcToggleButtonThreeOption {
	items: PlcToggleButtonThreeItem[];
	key: unknown;
}

@Component({
	selector: 'plc-toggle-button-three',
	templateUrl: './toggle-button-three.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toggle-button-three' },
})
export class ToggleButtonThreeComponent {
	@HostBinding('class')
	public get hostClass(): string {
		return this.disabled ? 'plc-toggle-button-three--disabled' : '';
	}

	@Input() public viewMode: PlcToggleButtonThreeViewMode = 'full';
	@Input() public options: PlcToggleButtonThreeOption[] = [];
	@Input() public disabled = false;

	@Input() public set selected(value: unknown) {
		this._selected = value;
	}

	@Output() public change: EventEmitter<unknown> = new EventEmitter();

	public selectedClass = (key: unknown): string => {
		return key === this._selected
			? 'plc-toggle-button-three__option--selected'
			: '';
	};

	public get filteredOptions(): PlcToggleButtonThreeOption[] {
		return this.options.map(({ key, items }) => ({
			key,
			items: items.filter(({ type }) => {
				if (this.viewMode === 'full') return true;
				else if (this.viewMode === 'imgOnly') return type === 'img';
				else if (this.viewMode === 'textOnly') return type === 'text';
			}),
		}));
	}

	private _selected: unknown;

	public select(key: unknown): void {
		if (this.disabled || key === this._selected) return;

		this._selected = key;
		this.change.emit(key);
	}
}
