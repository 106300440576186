<header class="plc-section__header">
	<plc-label [title]="title" [size]="'l'" />
	<plc-icon
		[name]="'right-arrow'"
		[hover]="true"
		[angle]="angle"
		(click)="toggle()"
	/>
</header>

<main class="plc-section__main" [class]="mainClasses">
	<ng-content></ng-content>
</main>
