import {
	PlcTutorialStage,
	TUTORIAL_INITIAL_STATE,
} from '@bussiness/custom-models/wizard.custom-models';
import { WizardPageConfig } from '@bussiness/store/components/components-facade';
import { Action, createReducer, on } from '@ngrx/store';

import {
	WizardActions,
	WizardAddConfigAction,
	WizardHidedAction,
} from './wizard.actions';

export const wizardFeatureKey = 'wizard';

export interface WizardState {
	configurations: WizardPageConfig[];
	tutorial: PlcTutorialStage[];
}

export const initialState: WizardState = {
	configurations: [],
	tutorial: TUTORIAL_INITIAL_STATE,
};

function addConfigFunc(
	state: WizardState,
	{ configuration }: WizardAddConfigAction,
): WizardState {
	const { name } = configuration;
	const configurationClone = { ...configuration, show: true };
	const alreadyAdded = state.configurations.find(
		(config) => config.name === name,
	);

	let configurations = state.configurations;
	if (alreadyAdded)
		configurations = state.configurations.map((config) =>
			config.name === name ? configurationClone : config,
		);
	else if (!alreadyAdded)
		configurations = state.configurations.concat([configurationClone]);

	return {
		...state,
		configurations,
	};
}

function hideFunc(
	state: WizardState,
	{ name }: WizardHidedAction,
): WizardState {
	const configurations = state.configurations.map((config) =>
		config.name === name ? { ...config, show: false } : config,
	);

	return {
		...state,
		configurations,
	};
}

export const reducer = createReducer(
	initialState,
	on(WizardActions.addConfig, addConfigFunc),
	on(WizardActions.hide, hideFunc),
);

export function wizardReducer(
	state = initialState,
	action: Action,
): WizardState {
	return reducer(state, action);
}
