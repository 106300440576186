import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from '@angular/core';

/**
 * @deprecated use Toggle Button Three Options instead
 */
export class ToggleButtonTwoOptions {
	public id: string;
	public avatarUrl: string;
	public avatarUrl2: string;
}

/**
 * @deprecated use Toggle Button Three component instead
 */
@Component({
	selector: 'plc-toggle-button-two',
	templateUrl: './toggle-button-two.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toggle-button plc-toggle-button--two' },
})
export class ToggleButtonTwoComponent implements OnInit {
	@HostBinding('class')
	public get hostClass(): string {
		return this.options.length === 2
			? 'plc-toggle-button--two--two-col'
			: '';
	}

	@Input({ required: true }) public options: ToggleButtonTwoOptions[];
	@Output() public readonly toggle = new EventEmitter<string>();

	public cellClass(id: string): string {
		return this._selected !== id
			? 'plc-toggle-button__option--not-selected'
			: '';
	}

	private _selected: string;

	constructor() {
		console.warn(
			'Component plc-toggle-button-two is deprecated. Use plc-toggle-button-three instead',
		);
	}

	ngOnInit(): void {
		if (!this.options) throw Error('The "options" input cannot be null!');

		const [firstOption] = this.options;
		this._selected = firstOption.id;
	}

	public handleOptionClick(id: string): void {
		this._selected = id;
		this.toggle.emit(id);
	}
}
