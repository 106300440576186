import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'plc-loader',
	templateUrl: './loader.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnDestroy {
	@Input() public hints: string[] = [];
	@Input() public loadReasons: string[] = [];

	public get hint(): string {
		return this.hints[this._currentHintIndex];
	}

	public get loadReason(): string {
		return this.loadReasons[this._currentLoadReasonIndex];
	}

	private _currentHintIndex = 0;
	private _currentLoadReasonIndex = 0;
	private _interval: NodeJS.Timeout;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this._interval = setInterval(() => {
			const newReasonIndex =
				this._currentLoadReasonIndex + 1 >= this.loadReasons.length
					? 0
					: this._currentLoadReasonIndex + 1;
			this._currentLoadReasonIndex = newReasonIndex;
			this.cdr.markForCheck();
		}, 2000);
	}

	ngOnDestroy(): void {
		clearInterval(this._interval);
	}

	public switchHint(): void {
		if (!this.hints || !this.hints.length) return;

		this._currentHintIndex =
			this._currentHintIndex + 1 >= this.hints.length
				? 0
				: this._currentHintIndex + 1;
	}
}
