import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	BagelPhaseBagelUpdatedEvent,
	BagelPhaseEventType,
	BagelPhaseTimeChangedEvent,
	BagelPhaseWordUpdatedEvent,
} from '@core/models/games/events/bagel-phase.events';

import { BagelPhaseCreatedEvent } from '../../models/games/events/bagel-phase.events';

@Injectable()
export class BagelPhaseSockets {
	public timeUpdated$ = this.socket.fromEvent<BagelPhaseTimeChangedEvent>(
		BagelPhaseEventType.TimeChanged,
	);

	public created$ = this.socket.fromEvent<BagelPhaseCreatedEvent>(
		BagelPhaseEventType.Created,
	);

	public bagelUpdated$ = this.socket.fromEvent<BagelPhaseBagelUpdatedEvent>(
		BagelPhaseEventType.BagelUpdated,
	);

	public bagelQuestionUpdated$ =
		this.socket.fromEvent<BagelPhaseWordUpdatedEvent>(
			BagelPhaseEventType.QuestionUpdated,
		);

	constructor(private socket: Socket) {}

	public updateTime(payload: BagelPhaseTimeChangedEvent): void {
		this.socket.emit(BagelPhaseEventType.TimeChanged, payload);
	}
}
