import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ToastService, WizardMessage } from './toast.service';

@Component({
	selector: 'plc-toast',
	templateUrl: './toast.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-toast' },
})
export class ToastComponent implements OnInit {
	public messages$: Observable<Array<WizardMessage>>;

	constructor(private toastService: ToastService) {}

	ngOnInit() {
		this.messages$ = this.toastService.messages$;
	}

	public getTypeClass(type: 'info' | 'error' | 'warning'): string {
		return `plc-toast__message--${type}`;
	}

	public handleClose(index: number): void {
		this.toastService.closeMessage(index);
	}
}
