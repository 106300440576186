import { createAction, props } from '@ngrx/store';

export interface LoaderToggleAction {
	display: boolean;
}

export interface LoaderAddReasonAction {
	key: string;
	value: string;
}

export interface LoaderRemoveReasonAction {
	key: string;
}

export class LoaderActions {
	public static toggle = createAction(
		'[Loader] Toggle',
		props<LoaderToggleAction>(),
	);

	public static addReason = createAction(
		'[Loader] Add Reason',
		props<LoaderAddReasonAction>(),
	);

	public static removeReason = createAction(
		'[Loader] Remove Reason',
		props<LoaderRemoveReasonAction>(),
	);
}
