import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { PlcIcon } from '@components/models/icon.models';

/**
 * @deprecated use Box component instead
 */
@Component({
	selector: 'plc-panel',
	templateUrl: './panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-panel' },
})
export class PanelComponent {
	@HostBinding('class')
	public get hostClasses(): string {
		const classes: string[] = [];

		if (!this.title && !this.showInfo && !this.secondaryIcon)
			classes.push('plc-panel--no-header');

		if (this.isScrolled) classes.push('plc-panel--extra-bottom-padding');

		return classes.join(' ');
	}

	@ViewChild('main') public mainEl: ElementRef;

	@Input() public title = '';
	@Input() public showInfo = false;
	@Input() public secondaryIcon: PlcIcon = null;

	@Output() public infoPress: EventEmitter<void> = new EventEmitter();
	@Output() public secondaryIconPress: EventEmitter<void> =
		new EventEmitter();

	public get isScrolled(): boolean {
		const { offsetHeight } = this.el.nativeElement as HTMLElement;
		return this._scrollTop >= offsetHeight;
	}

	private _scrollTop: number;

	constructor(private el: ElementRef) {
		console.warn('Component plc-panel is deprecated. Use plc-box instead');
	}

	public emitInfoPress(): void {
		this.infoPress.emit();
	}

	public handleScroll(event: Event): void {
		const { scrollTop } = event.target as Element;
		this._scrollTop = scrollTop;
	}

	public goToTop(): void {
		this.mainEl.nativeElement.scroll({ top: 0 });
	}

	public emitSecondaryIconPress(): void {
		this.secondaryIconPress.emit();
	}
}
