/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { Lang } from '@api/models/enums';
import { plcHeightIncreaseAnimation } from '@components/animations/size-increate.animation';
import { PlcIcon } from '@components/models/icon.models';
import { PlcFlagPickerOption } from '@components/modules/common/flag-picker/flag-picker.component';

import {
	FLAG_PICKER_OPTIONS,
	MAX_VOLUME,
	PlcToolbarAction,
	PlcToolbarData,
	PlcToolbarEntry,
	VOLUME_VALUES,
} from '../toolbar.models';

@Component({
	selector: 'plc-desktop-menu',
	templateUrl: './toolbar-desktop-menu.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-desktop-menu' },
	animations: [plcHeightIncreaseAnimation],
})
export class ToolbarDesktopMenuComponent {
	@HostBinding('class')
	public get unfoldedCssClass(): string {
		const className = 'plc-desktop-menu__user--unfolded';
		return this._unfolded ? className : '';
	}

	@HostListener('mouseenter')
	public unfold(): void {
		this._unfolded = true;
	}

	@HostListener('mouseleave')
	public fold(): void {
		this._unfolded = false;
	}

	@Input({ required: true }) public userData: PlcToolbarData;
	@Input({ required: true })
	public entries: PlcToolbarEntry<PlcToolbarAction>[];
	@Input({ required: true }) public volume: number;
	@Input() public lang: Lang = Lang.Gl;

	@Output() public pressOption: EventEmitter<PlcToolbarAction> =
		new EventEmitter();
	@Output() public volumeChange: EventEmitter<number> = new EventEmitter();
	@Output() public langChange: EventEmitter<Lang> = new EventEmitter();

	public volumeValues: number[] = VOLUME_VALUES;
	public options: PlcFlagPickerOption[] = FLAG_PICKER_OPTIONS;

	public iconSrc = (icon: PlcIcon): string => {
		return `assets/icons/${icon}.svg`;
	};

	public get volumeIcon(): PlcIcon {
		if (this.volume === 0) return 'speaker-mute';
		if (this.volume > 0 && this.volume < MAX_VOLUME) return 'speaker-low';
		if (this.volume === MAX_VOLUME) return 'speaker-high';
	}

	public get avatarUrl(): string {
		const { avatarUrl } = this.userData;
		return avatarUrl;
	}

	public get unfolded(): boolean {
		return this._unfolded;
	}

	public get selectedLang(): PlcFlagPickerOption {
		return this.options.find(({ lang }) => lang === this.lang);
	}

	private _unfolded = false;

	public preventPropagation(event: MouseEvent): void {
		event.stopPropagation();
		this._unfolded = false;
	}

	public emitPressOption(option: PlcToolbarAction): void {
		this._unfolded = false;
		this.pressOption.emit(option);
	}

	public emitVolumeChange(volume: number): void {
		this.volumeChange.emit(volume);
	}

	public emitLangChange(option: PlcFlagPickerOption): void {
		this.langChange.emit(option.lang);
	}
}
