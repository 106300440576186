import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

export type PlcDialogAction = 'show' | 'hide';

export interface PlcDialogToggleParams {
	name: string;
	action: PlcDialogAction;
}

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	public get toggle$(): Observable<PlcDialogToggleParams> {
		return this._toggle$.asObservable();
	}

	private _dialogOnScreen: string;
	private _dialogs: Set<string> = new Set();
	private _toggle$: Subject<PlcDialogToggleParams> = new Subject();

	public add(name: string): void {
		this._dialogs.add(name);
	}

	public toggle(params: PlcDialogToggleParams): void {
		const { name, action } = params;
		if (!this._dialogs.has(name)) {
			const dialogNames = Array.from(this._dialogs);
			throw Error(`The dialog with name "${name}" is not resitered.Try one of following: \n\t* ${dialogNames
				.join('\n\t* ')
				.toString()}
			`);
		}

		if (this._dialogOnScreen && action === 'show')
			this._toggle$.next({ name: this._dialogOnScreen, action: 'hide' });

		this._dialogOnScreen = action === 'show' ? name : null;

		this._toggle$.next(params);
	}

	public remove(name: string): void {
		this._dialogs.delete(name);
	}
}
