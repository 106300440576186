import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PlcLayoutModule } from '../layout/layout.module';
import { ComponentsSharedModule } from '../shared/components-shared.module';
import { TypeWritterDirective } from '../shared/type-writter.directive';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonComponent } from './button/button.component';
import { DialogWrapperComponent } from './dialog/dialog-wrapper.component';
import { DialogComponent } from './dialog/dialog.component';
import { RaisingPanelComponent } from './dialog/raising-panel.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FlagPickerComponent } from './flag-picker/flag-picker.component';
import { IconBadgeComponent } from './icon-badge/icon-badge.component';
import { IconComponent } from './icon/icon.component';
import { LabelComponent } from './label/label.component';
import { LinkComponent } from './link/link.component';
import { LoaderComponent } from './loader/loader.component';
import { StepperComponent } from './stepper/stepper.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { ThemeSwitcher2Component } from './theme-switcher/theme-switcher2.component';
import { ThemeService } from './theme-switcher/theme.service';
import { ToastComponent } from './toast/toast.component';
import { ToastService } from './toast/toast.service';
import { ToggleButtonThreeComponent } from './toggle-button-three/toggle-button-three.component';
import { TooltipComponent } from './wizard/tooltip.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardService } from './wizard/wizard.service';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
	declarations: [
		DialogComponent,
		DialogWrapperComponent,
		DisclaimerComponent,
		LinkComponent,
		LoaderComponent,
		StepperComponent,
		ToastComponent,
		WizardComponent,
		TooltipComponent,
		RaisingPanelComponent,
		ToggleButtonThreeComponent,
		ProgressBarComponent,
	],
	imports: [
		CommonModule,
		PlcLayoutModule,
		ObserversModule,
		FormsModule,
		ComponentsSharedModule,
		TranslateModule.forChild({
			extend: true,
		}),
	],
	exports: [
		AvatarComponent,
		ButtonComponent,
		DialogComponent,
		DialogWrapperComponent,
		DisclaimerComponent,
		IconComponent,
		IconBadgeComponent,
		LabelComponent,
		LinkComponent,
		LoaderComponent,
		StepperComponent,
		ThemeSwitcherComponent,
		ThemeSwitcher2Component,
		ToastComponent,
		WizardComponent,
		FlagPickerComponent,
		RaisingPanelComponent,
		ToggleButtonThreeComponent,
		TypeWritterDirective,
		ProgressBarComponent,
	],
	providers: [ThemeService, ToastService, WizardService],
})
export class PlcCommonModule {}
