<header class="plc-panel__header">
	<plc-icon
		[style.visibility]="!secondaryIcon ? 'hidden' : 'visible'"
		[name]="secondaryIcon || 'menu'"
		[hover]="true"
		(click)="emitSecondaryIconPress()"
	/>
	<plc-label [title]="title" [size]="'xl'" />
	<plc-icon
		[style.visibility]="!showInfo ? 'hidden' : 'visible'"
		[name]="'info'"
		[hover]="true"
		(click)="emitInfoPress()"
	/>
</header>

<main #main class="plc-panel__main" (scroll)="handleScroll($event)">
	<ng-content></ng-content>
</main>

<plc-button
	*ngIf="isScrolled"
	class="plc-panel__go-top-button"
	[icon]="'up-arrow'"
	[onlyIcon]="true"
	[flattened]="true"
	(press)="goToTop()"
/>
