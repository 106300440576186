<header class="plc-tooltip__header">
	{{ config.title | translate }}
</header>

<main class="plc-tooltip__content">
	<main
		class="plc-tooltip__content__text"
		[innerHTML]="config.text | translate | plcMarkdown"
	></main>

	<footer class="plc-tooltip__content__buttons">
		<plc-button
			[icon]="'left-arrow'"
			[onlyIcon]="true"
			[flattened]="true"
			[type]="'secondary'"
			(press)="emitPressPrevious()"
		/>

		<span> {{ index + 1 }}/{{ totalTooltips }} </span>

		<plc-button
			[icon]="'right-arrow'"
			[onlyIcon]="true"
			[flattened]="true"
			[type]="'secondary'"
			(press)="emitPressNext()"
		/>

		<plc-button
			*ngIf="!isLast"
			[flattened]="true"
			[type]="'outline'"
			(press)="emitSkip()"
		>
			{{ 'components.common.wizard.skip' | translate }}
		</plc-button>
		<plc-button *ngIf="isLast" [flattened]="true" (press)="emitSkip()">
			{{ 'components.common.wizard.done' | translate }}
		</plc-button>
	</footer>
</main>

<span class="plc-tooltip__pointer" [class]="pointerClass" [style]="position">
</span>
