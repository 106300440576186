<span class="plc-number-input__label" [class]="labelClass"> {{ label }} </span>

<section
	class="plc-number-input__wrapper"
	(click)="handleFocus(true)"
	(focusout)="handleFocusOut($event)"
>
	<button (click)="reduce()" [disabled]="!canReduce">-</button>

	<input type="number" step="1" [min]="min" [max]="max" [value]="value" />

	<button (click)="add()" [disabled]="!canAdd">+</button>
</section>

<plc-validation-messages
	[viewMode]="viewMode"
	[validations]="validations"
	[errors]="errors"
/>
