<img
	class="plc-button__icon"
	*ngIf="icon"
	[class]="iconClass"
	[src]="src"
	alt="blank"
/>

<span class="plc-button__text" *ngIf="!onlyIcon">
	<ng-content></ng-content>
</span>
