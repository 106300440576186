<img
	class="plc-theme-switcher2__sun"
	src="assets/icons/sun.svg"
	[class]="getSelectedClass('sun')"
	(click)="emitThemeChange()"
/>

<img
	class="plc-theme-switcher2__moon"
	src="assets/icons/moon.svg"
	[class]="getSelectedClass('moon')"
	(click)="emitThemeChange()"
/>
