<aside
	class="plc-players-overview__team"
	[class]="teamClass"
	*ngIf="blueTeamUsers; let lenght = count"
	[@plcAccordionAnimation]="lenght"
>
	<plc-label
		class="plc-players-overview__team__title"
		[title]="
			'components.game.commons.players-overview.blue-team' | translate
		"
	/>
	<div
		*ngFor="let user of blueTeamUsers"
		class="plc-players-overview__team__member"
	>
		<plc-avatar [src]="user.avatarUrl" [shape]="'circle'" />
		<plc-label [title]="user.name" />
	</div>
</aside>

<main class="plc-players-overview__presenter" *ngIf="presenter">
	<plc-avatar [src]="presenter.avatarUrl" [shape]="'circle'" />
	<plc-label [title]="presenter.name" />
</main>

<aside
	class="plc-players-overview__team"
	[class]="teamClass"
	*ngIf="orangeTeamUsers; let lenght = count"
	[@plcAccordionAnimation]="lenght"
>
	<plc-label
		class="plc-players-overview__team__title"
		[title]="
			'components.game.commons.players-overview.orange-team' | translate
		"
	/>
	<div
		*ngFor="let user of orangeTeamUsers"
		class="plc-players-overview__team__member"
	>
		<plc-avatar [src]="user.avatarUrl" [shape]="'circle'" />
		<plc-label [title]="user.name" />
	</div>
</aside>
