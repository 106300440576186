import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	ViewChild,
} from '@angular/core';

/**
 * @deprecated use Box component instead
 */
@Component({
	selector: 'plc-action-buttons-wrapper',
	templateUrl: './action-buttons-wrapper.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-action-buttons' },
})
export class ActionButtonsWrapperComponent implements AfterViewInit {
	@ViewChild('spacer') public spacerEl: ElementRef<HTMLElement>;

	constructor(private el: ElementRef) {
		console.warn(
			'Component plc-action-buttons-wrapper is deprecated. Use plc-box instead',
		);
	}

	ngAfterViewInit(): void {
		const host = this.el.nativeElement as HTMLElement;
		const { firstChild } = host;
		host.insertBefore(this.spacerEl.nativeElement, firstChild.nextSibling);
	}
}
