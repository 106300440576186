import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	GameNavigatedEvent,
	GamesEventType,
	GamesPhaseAddedEvent,
	GamesUpdatedEvent,
} from '@core/models/games/events/games.events';

@Injectable()
export class GamesSockets {
	public navigated$ = this.socket.fromEvent<GameNavigatedEvent>(
		GamesEventType.Navigated,
	);
	public updated$ = this.socket.fromEvent<GamesUpdatedEvent>(
		GamesEventType.Updated,
	);

	public phaseAdded$ = this.socket.fromEvent<GamesPhaseAddedEvent>(
		GamesEventType.PhaseAdded,
	);

	constructor(private socket: Socket) {}

	public join(gameId: string): void {
		this.socket.emit(GamesEventType.Joined, gameId);
	}

	public emitNavigation(payload: GameNavigatedEvent): void {
		this.socket.emit(GamesEventType.Navigated, payload);
	}
}
