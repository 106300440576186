import { animate, style, transition, trigger } from '@angular/animations';

export const plcWidthIncreaseAnimation = trigger('plcWidthIncrease', [
	transition(
		':enter',
		[
			style({ width: '0px' }),
			animate('400ms linear', style({ width: '{{size}}px' })),
		],
		{ params: { size: '275' } },
	),
	transition(':leave', [animate('400ms linear', style({ width: '0px' }))]),
]);

export const plcHeightIncreaseAnimation = trigger('plcHeightIncrease', [
	transition(':enter', [
		style({ height: '0px', overflow: 'hidden' }),
		animate('400ms ease', style({ height: '100%' })),
	]),
	transition(':leave', [animate('400ms ease', style({ height: '0px' }))]),
]);
