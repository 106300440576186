import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { plcWidthIncreaseAnimation } from '@components/animations/size-increate.animation';

@Component({
	selector: 'plc-progress-bar',
	templateUrl: './progress-bar.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-progress-bar' },
	animations: [plcWidthIncreaseAnimation],
})
export class ProgressBarComponent {
	@Input({ required: true }) public percentage: number;

	public get percentageAsString(): string {
		return `${this.percentage}%`;
	}
}
