/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { PlayerRole, Team } from '@api/models/enums';
import { PlcFilter } from '@components/models/colors.models';
import { PlcIcon } from '@components/models/icon.models';

const CONCURSANT_ROLES: PlayerRole[] = [
	PlayerRole.PlayerPresenter,
	PlayerRole.Player1,
	PlayerRole.Player2,
];

@Component({
	selector: 'plc-role-card',
	templateUrl: './role-card.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-role-card' },
})
export class RoleCardComponent {
	@Input({ required: true }) public ready: boolean;
	@Input({ required: true }) public role: PlayerRole;
	@Input({ required: true }) public avatar: string;
	@Input({ required: true }) public set name(value: string) {
		this._name = value;
	}
	@Input({ required: true }) public disabled: boolean;
	@Input({ required: true }) public team: Team;

	@Output() public select: EventEmitter<void> = new EventEmitter();
	@Output() public unselect: EventEmitter<void> = new EventEmitter();

	public get mainClass(): string {
		const classes: string[] = [];

		if (this.team) classes.push(`plc-role-card__main--${this.team}`);

		if (this.disabled) classes.push('plc-role-card__main--disabled');

		return classes.join(' ');
	}

	public get readyClass(): string {
		const baseClass = 'plc-role-card__main__status';
		return this.ready ? `${baseClass}--ready` : `${baseClass}--not-ready`;
	}

	public get statusColor(): PlcFilter {
		return this.ready ? 'dark-green' : 'dark-red';
	}

	public get roleIcon(): PlcIcon {
		return this.role === PlayerRole.Presenter ? 'mic' : 'gamepad';
	}

	public get roleName(): string {
		let roleName: string;

		if (this.role === PlayerRole.Presenter)
			roleName = roleName = 'components.game.commons.role-card.presenter';
		else if (CONCURSANT_ROLES.includes(this.role))
			roleName = 'components.game.commons.role-card.concursant';
		else if (this.role === PlayerRole.Guest)
			roleName = roleName = 'components.game.commons.role-card.guest';

		return roleName;
	}

	public get name(): string {
		return this._name
			? this._name
			: 'components.game.commons.role-card.free';
	}

	public get isFree(): boolean {
		return !this._name;
	}

	private _name: string;

	public handleClick(): void {
		if (this.isFree) this.select.emit();
		else this.unselect.emit();
	}
}
