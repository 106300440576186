<plc-label [title]="label" />

<div class="plc-pin-code__wrapper" (paste)="handlePaste($event)">
	<input
		*ngFor="let char of chars; let i = index"
		class="plc-pin-code__wrapper__input-char"
		#char_input
		maxlength="1"
		plcFocus
		[focus]="autofocusEnabled && valueLength === i"
		[type]="type"
		[disabled]="isDisabled"
		[value]="getCharBy(i)"
		(blur)="handleBlur()"
		(focus)="handleFocusBy(i)"
		(keydown)="handleKeydown($event, i)"
		(input)="handleInputBy($event.target.value, i)"
	/>

	<plc-icon
		class="plc-pin-code__wrapper__icon"
		[name]="icon"
		[hover]="true"
		(click)="togglePassword()"
	/>
</div>

<plc-validation-messages
	[viewMode]="viewMode"
	[validations]="validations"
	[errors]="errors"
/>
