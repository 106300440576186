<plc-background-screen class="plc-wizard" [type]="'transparent'">
	<div class="plc-wizard__hole" [ngStyle]="holeStyle"></div>

	<plc-tooltip
		*ngFor="let config of config.tooltips; let i = index; let total = count"
		[config]="config"
		[index]="i"
		[totalTooltips]="total"
		[parentSize]="parentSize"
		[animation]="tooltipAnimation(i)"
		(pressNext)="showNext()"
		(pressPrevious)="showPrevious()"
		(pressSkip)="emitSkip()"
	/>
</plc-background-screen>
