import { KeyValue } from '@angular/common';
import { Action, createReducer, on } from '@ngrx/store';

import {
	LoaderActions,
	LoaderAddReasonAction,
	LoaderRemoveReasonAction,
} from './loader.actions';

export const loaderFeatureKey = 'loader';

export interface LoaderState {
	reasons: KeyValue<string, string>[];
}

export const initialState: LoaderState = {
	reasons: [],
};

const reducer = createReducer(
	initialState,
	on(
		LoaderActions.addReason,
		(state: LoaderState, action: LoaderAddReasonAction) => ({
			...state,
			reasons: state.reasons.concat(action),
		}),
	),
	on(
		LoaderActions.removeReason,
		(state: LoaderState, { key: actionKey }: LoaderRemoveReasonAction) => ({
			...state,
			reasons: state.reasons.filter(({ key }) => key !== actionKey),
		}),
	),
);

export function loaderReducer(
	state = initialState,
	action: Action,
): LoaderState {
	return reducer(state, action);
}
