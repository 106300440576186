<plc-background-screen [type]="'gradient'" class="plc-loader">
	<section class="plc-loader__hint">
		<plc-label
			class="plc-loader__hint__text"
			[size]="'s'"
			[direction]="'center'"
			[title]="'Pista'"
		>
			{{ hint }}
		</plc-label>
		<plc-icon
			[name]="'right-arrow'"
			[hover]="true"
			[size]="'l'"
			(click)="switchHint()"
		/>
	</section>

	<section class="plc-loader__logo-wrapper">
		<plc-avatar
			class="plc-loader__logo-wrapper__img"
			[src]="'assets/images/logo.svg'"
		/>
		<plc-avatar
			class="plc-loader__logo-wrapper__img"
			[src]="'assets/images/loading.svg'"
		/>
	</section>

	<section class="plc-loader__loading-message" *ngIf="loadReason">
		<plc-label [direction]="'center'" [size]="'s'">
			{{ loadReason }}
		</plc-label>
	</section>
</plc-background-screen>
