import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class TabsService {
	public get titlesUpdated(): Observable<void> {
		return this._titlesUpdate$.asObservable();
	}

	private _titlesUpdate$: Subject<void> = new Subject();

	public emitTitlesUpdated(): void {
		this._titlesUpdate$.next();
	}
}
