import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	MemoryPhaseCellUpdatedEvent,
	MemoryPhaseChangeTimeEvent,
	MemoryPhaseEventType,
	MemoryPhaseGridFixedEvent,
	MemoryPhaseGridMissedEvent,
	MemoryPhaseGridUpdatedEvent,
	MemoryPhaseUpdateEvent,
} from '@core/models/games/events/memory-phase.events';

@Injectable()
export class MemoryPhaseSockets {
	public timeChanged$ = this.socket.fromEvent<MemoryPhaseChangeTimeEvent>(
		MemoryPhaseEventType.TimeChanged,
	);

	public gridUpdated$ = this.socket.fromEvent<MemoryPhaseGridUpdatedEvent>(
		MemoryPhaseEventType.GridUpdated,
	);

	public gridMissed$ = this.socket.fromEvent<MemoryPhaseGridMissedEvent>(
		MemoryPhaseEventType.GridMissed,
	);

	public gridFixed$ = this.socket.fromEvent<MemoryPhaseGridFixedEvent>(
		MemoryPhaseEventType.GridFixed,
	);

	public cellUpdated$ = this.socket.fromEvent<MemoryPhaseCellUpdatedEvent>(
		MemoryPhaseEventType.CellUpdated,
	);

	public finish$ = this.socket.fromEvent<MemoryPhaseUpdateEvent>(
		MemoryPhaseEventType.Finish,
	);

	constructor(private socket: Socket) {}

	public emitTimeChanged(payload: MemoryPhaseChangeTimeEvent): void {
		this.socket.emit(MemoryPhaseEventType.TimeChanged, payload);
	}
}
