import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'plc-footer',
	templateUrl: './footer.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-footer' },
})
export class FooterComponent {
	@Input({ required: true }) public environment: string;
	@Input({ required: true }) public year: number;
}
