import { ElementRef } from '@angular/core';

export class WizardTooltipConfig {
	private _elementInfo: ElementInfo;
	public get elementInfo(): ElementInfo {
		return this._elementInfo;
	}
	private _title: string;
	public get title(): string {
		return this._title;
	}
	private _text: string;
	public get text(): string {
		return this._text;
	}

	constructor(
		elementToExplain: ElementRef | HTMLElement | Element | ChildNode,
		title: string,
		text: string,
	) {
		let htmlElement: HTMLElement;
		if (elementToExplain instanceof ElementRef)
			htmlElement = (<ElementRef>elementToExplain).nativeElement;
		else if (
			elementToExplain instanceof HTMLElement ||
			elementToExplain instanceof Element
		)
			htmlElement = <HTMLElement>elementToExplain;
		else
			throw Error(
				'Argument "elementToExplain" must be either ElementRef or HTMLElement',
			);

		const { offsetWidth: width, offsetHeight: height } = htmlElement;
		const { left, top } = htmlElement.getBoundingClientRect();
		this._elementInfo = { width, height, left, top } as ElementInfo;

		this._title = title;
		this._text = text;
	}

	toJSON() {
		return {
			elementInfo: this._elementInfo,
			title: this._title,
			text: this._text,
		};
	}
}

export type ElementInfo = {
	left: number;
	top: number;
	width: number;
	height: number;
};

export type FutureTooltipPosition = ElementInfo;

export type TooltipPlacingInfo = {
	top: number;
	left: number;
	pointerType: PointerType;
};

export type TooltipSize = {
	width: number;
	height: number;
};

export type WindowSize = TooltipSize;

export enum PointerType {
	None = 'none',
	Bottom = 'bottom',
	Top = 'top',
	Left = 'left',
	Right = 'right',
}
