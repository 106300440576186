import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';

export type PlcBoxGridDirection = 'column' | 'row';

const GAP_TO_BOTTOM = 50;
@Component({
	selector: 'plc-box',
	templateUrl: './box.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-box' },
})
export class BoxComponent implements AfterViewInit, OnDestroy {
	@HostBinding('class')
	public get hostClass(): string {
		const classes: string[] = [];

		if (this.transparent) classes.push('plc-box--transparent');
		if (!this.padding) classes.push('plc-box--no-padding');

		return classes.join(' ');
	}

	@ViewChild('header')
	public headerEl: ElementRef<HTMLElement>;

	@ViewChild('subHeader')
	public subHeaderEl: ElementRef<HTMLElement>;

	@Input() public mainGridFlow: PlcBoxGridDirection = 'row';
	@Input() public transparent = false;
	@Input() public padding = true;
	@Output() public scrollEnd: EventEmitter<void> = new EventEmitter();

	public get mainClass(): string {
		const classes: string[] = [
			`plc-box__main--${this.mainGridFlow}-layout`,
		];

		if (this._mainNeedMargin) classes.push('plc-box__main--mt');

		return classes.join(' ');
	}

	private _mutationObserver: MutationObserver;
	private _mainNeedMargin: boolean;
	private _scrolledToEnd = false;

	constructor(private cdr: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		const headerHtml = this.headerEl.nativeElement as HTMLElement;
		const subHeaderHtml = this.subHeaderEl.nativeElement as HTMLElement;
		const headerChildElements = Array.from(headerHtml.children);
		const subHeaderChildElements = Array.from(subHeaderHtml.children);

		this.setMainNeedMargin(headerChildElements, subHeaderChildElements);

		this._mutationObserver = new MutationObserver(() => {
			this.setMainNeedMargin(headerChildElements, subHeaderChildElements);
			this.cdr.markForCheck();
		});

		headerChildElements.forEach((child) =>
			this._mutationObserver.observe(child, { childList: true }),
		);

		subHeaderChildElements.forEach((child) =>
			this._mutationObserver.observe(child, { childList: true }),
		);

		this.cdr.markForCheck();
	}

	ngOnDestroy(): void {
		this._mutationObserver.disconnect();
	}

	public handleScroll(event: Event): void {
		const { scrollTop, scrollHeight, clientHeight } =
			event.target as Element;

		const scrollTrigger = scrollTop + GAP_TO_BOTTOM;
		const maxScroll = scrollHeight - clientHeight;

		if (!this._scrolledToEnd && scrollTrigger >= maxScroll) {
			this._scrolledToEnd = true;
			this.scrollEnd.emit();
		} else if (this._scrolledToEnd && scrollTrigger < maxScroll)
			this._scrolledToEnd = false;
	}

	private setMainNeedMargin(
		headerChildElements: Element[],
		subHeaderChildElements: Element[],
	): void {
		const allHeaderChildrenAreEmpty = headerChildElements.every(
			(section) => section.children.length === 0,
		);
		const allSubHeaderChildrenAreEmpty = subHeaderChildElements.every(
			(section) => section.children.length === 0,
		);

		this._mainNeedMargin =
			!allHeaderChildrenAreEmpty || !allSubHeaderChildrenAreEmpty;
	}
}
