import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	AlphabetSoupAnswerUpdatedEvent,
	AlphabetSoupPhaseUpdatedEvent,
	AlphabetSoupRoundTimeChangedEvent,
	AlphabetSoupRoundUpdatedEvent,
	AlphabetSoupUpdatedEvent,
} from '@core/models/games/events/alphabet-soup-phase.events';

@Injectable()
export class AlphabetSoupPhaseSockets {
	public phaseUpdated$ = this.socket.fromEvent<AlphabetSoupPhaseUpdatedEvent>(
		'alphabet-soup-phase:phase-updated',
	);

	public roundUpdated$ = this.socket.fromEvent<AlphabetSoupRoundUpdatedEvent>(
		'alphabet-soup-phase:round-updated',
	);

	public soupUpdated$ = this.socket.fromEvent<AlphabetSoupUpdatedEvent>(
		'alphabet-soup-phase:soup-updated',
	);

	public answerUpdated$ =
		this.socket.fromEvent<AlphabetSoupAnswerUpdatedEvent>(
			'alphabet-soup-phase:answer-updated',
		);

	public roundTimeChanged$ =
		this.socket.fromEvent<AlphabetSoupRoundTimeChangedEvent>(
			'alphabet-soup-phase:round-time-changed',
		);

	constructor(private socket: Socket) {}

	public emitTimeChanged(payload: AlphabetSoupRoundTimeChangedEvent): void {
		this.socket.emit('alphabet-soup-phase:round-time-changed', payload);
	}
}
