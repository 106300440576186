import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core';

@Component({
	selector: 'plc-webcam',
	templateUrl: './webcam.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-webcam' },
})
export class WebcamComponent implements OnInit, AfterViewInit {
	@Output() public stream: EventEmitter<MediaStream> = new EventEmitter(null);

	public srcObject: MediaStream;

	public get cameraIsNotAllowed(): boolean {
		return this._error === 'NotAllowedError';
	}
	public get cameraBusy(): boolean {
		return this._error === 'NotReadableError';
	}
	public get noError(): boolean {
		return !this._error;
	}

	private _error: string;
	private _facindMode: 'user' | 'environment' = 'user';
	private _audioConstraints: MediaTrackConstraints;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this._audioConstraints = {
			echoCancellation: {
				ideal: true,
			},
		};
	}

	ngAfterViewInit() {
		this.initCamera({ audio: true, video: true });
	}

	public relaunchCamera(): void {
		this.initCamera({ audio: this._audioConstraints, video: true });
	}

	public rotateCamera(): void {
		this._facindMode = this._facindMode === 'user' ? 'environment' : 'user';
		this.initCamera({
			audio: this._audioConstraints,
			video: { facingMode: { exact: this._facindMode } },
		});
	}

	private initCamera(constraints: MediaStreamConstraints): void {
		this._error = null;

		const browser = <any>navigator;
		browser.getUserMedia =
			browser.getUserMedia ||
			browser.webkitGetUserMedia ||
			browser.mozGetUserMedia ||
			browser.msGetUserMedia;

		browser.mediaDevices
			.getUserMedia(constraints)
			.then((stream: MediaStream) => {
				this.srcObject = stream;
				this.stream.emit(stream);
				this.cdr.markForCheck();
			})
			.catch((error: DOMException) => {
				this._error = error.name;
				this.cdr.detectChanges();
			});
	}
}
