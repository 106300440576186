<img [class]="getClass('sun')" src="assets/icons/sun.svg" />

<label class="plc-theme-switcher__label" [class]="labelClass">
	<input
		type="checkbox"
		class="plc-theme-switcher__label__checkbox"
		[checked]="checked"
		(change)="emitThemeChange()"
	/>
	<span
		class="plc-theme-switcher__label__slider"
		[class]="sliderClass"
	></span>
</label>

<img [class]="getClass('moon')" src="assets/icons/moon.svg" />
