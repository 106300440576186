/* eslint-disable @typescript-eslint/member-ordering */

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	HostListener,
	Input,
	Signal,
	signal,
	WritableSignal,
} from '@angular/core';

@Component({
	selector: 'plc-check-panel',
	templateUrl: './check-panel.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-check-panel' },
})
export class CheckPanelComponent {
	@HostBinding('class')
	public get hostClass(): string {
		return this.checked() ? 'plc-check-panel--checked' : '';
	}

	@HostListener('click')
	public handleClick(): void {
		this._checked$.update((value) => !value);
	}

	@Input({ required: true }) public value: any;

	public get checked(): Signal<boolean> {
		return this._checked$;
	}

	private _checked$: WritableSignal<boolean> = signal(false);

	constructor(private cdr: ChangeDetectorRef) {}

	public check(): void {
		this._checked$.set(true);
		this.cdr.markForCheck();
	}

	public uncheckQuiet(): void {
		this._checked$.set(false);
	}
}
