import { PlayerRole, Team } from '@api/models/enums';
import { Game } from '@api/models/game.entity';
import { User } from '@api/models/user.entity';
import { PlcIcon } from '@components/models/icon.models';

export interface PlcTutorialStage {
	id: PlcTutorialStageId;
	title: string;
	text: string;
	completed: boolean;
	icon: PlcIcon;
	subStages: PlcTutorialSubStage[];
}

export enum PlcTutorialStageId {
	RolePicker,
	SoupPhase,
	MemoryPhase,
	SongPhase,
	BagelPhase,
}

export interface PlcTutorialSubStage {
	title: string;
	text: string;
	completed: boolean;
}

export const TUTORIAL_INITIAL_STATE: PlcTutorialStage[] = [
	{
		id: PlcTutorialStageId.RolePicker,
		title: 'pages.tutorial.stages.role-picker.title',
		text: 'pages.tutorial.stages.role-picker.text',
		completed: true,
		icon: 'gamepad',
		subStages: [],
	},
	{
		id: PlcTutorialStageId.SoupPhase,
		title: 'pages.tutorial.stages.soups.title',
		text: 'pages.tutorial.stages.soups.text',
		completed: false,
		icon: 'bawl',
		subStages: [],
	},
	{
		id: PlcTutorialStageId.MemoryPhase,
		title: 'pages.tutorial.stages.memory.title',
		text: 'pages.tutorial.stages.memory.text',
		completed: false,
		icon: 'brain',
		subStages: [],
	},
	{
		id: PlcTutorialStageId.SongPhase,
		title: 'pages.tutorial.stages.song.title',
		text: 'pages.tutorial.stages.song.text',
		completed: false,
		icon: 'note',
		subStages: [],
	},
	{
		id: PlcTutorialStageId.BagelPhase,
		title: 'pages.tutorial.stages.bagels.title',
		text: 'pages.tutorial.stages.bagels.text',
		completed: false,
		icon: 'bagel',
		subStages: [],
	},
];

export const TUTORIAL_GAME: Game = {
	_id: '1',
	over: false,
	phases: [
		// {
		// 	_id: '6464ed50eac1d24e7471bc18',
		// 	rounds: [
		// 		{
		// 			_id: '6464ed50eac1d24e7471bc03',
		// 			soups: [
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc00',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbf7',
		// 							word: 'león',
		// 							positions: [
		// 								{
		// 									x: 4,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'hit',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbf8',
		// 							word: 'zebra',
		// 							positions: [
		// 								{
		// 									x: 1,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbf9',
		// 							word: 'hiena',
		// 							positions: [
		// 								{
		// 									x: 3,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'animales de la jungla',
		// 					grid: [
		// 						['x', 'd', 'q', 'l', 'n'],
		// 						['a', 'r', 'b', 'e', 'z'],
		// 						['b', 'e', 'b', 'r', 's'],
		// 						['a', 'n', 'e', 'i', 'h'],
		// 						['n', 'ó', 'e', 'l', 'j'],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: '644d008cb3e44f2768a39b2a',
		// 					covered: false,
		// 					wordGroup: '62752f806df906e84c78dadc',
		// 				},
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc01',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbfa',
		// 							word: 'brazo',
		// 							positions: [
		// 								{
		// 									x: 4,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbfb',
		// 							word: 'boca',
		// 							positions: [
		// 								{
		// 									x: 3,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 3,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbfc',
		// 							word: 'pie',
		// 							positions: [
		// 								{
		// 									x: 2,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'partes del cuerpo humano',
		// 					grid: [
		// 						['x', 'v', 'c', 'm', 'h'],
		// 						['p', 'x', 'r', 'i', 'e'],
		// 						['e', 'i', 'p', 'h', 'ñ'],
		// 						['b', 'o', 'c', 'a', 'j'],
		// 						['o', 'z', 'a', 'r', 'b'],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: null,
		// 					covered: false,
		// 					wordGroup: '62752eb7634449ec58476870',
		// 				},
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc02',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbfd',
		// 							word: 'luces',
		// 							positions: [
		// 								{
		// 									x: 2,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbfe',
		// 							word: 'guion',
		// 							positions: [
		// 								{
		// 									x: 1,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bbff',
		// 							word: 'actor',
		// 							positions: [
		// 								{
		// 									x: 4,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 4,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'cine',
		// 					grid: [
		// 						['m', 'v', 'i', 'a', 'f'],
		// 						['n', 'o', 'i', 'u', 'g'],
		// 						['s', 'e', 'c', 'u', 'l'],
		// 						['y', 'o', 'd', 'm', 'i'],
		// 						['a', 'c', 't', 'o', 'r'],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: null,
		// 					covered: false,
		// 					wordGroup: '627aa5f7b7b377f8607e94c9',
		// 				},
		// 			],
		// 			team: Team.blue',
		// 			playing: false,
		// 			over: false,
		// 			time: 115,
		// 		},
		// 		{
		// 			_id: '6464ed50eac1d24e7471bc17',
		// 			soups: [
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc14',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc08',
		// 							word: 'hindi',
		// 							positions: [
		// 								{
		// 									x: 1,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 4,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc09',
		// 							word: 'ruso',
		// 							positions: [
		// 								{
		// 									x: 2,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 3,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc0a',
		// 							word: 'chino',
		// 							positions: [
		// 								{
		// 									x: 4,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 4,
		// 									y: 0,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'idiomas',
		// 					grid: [
		// 						['r', 'o', 'y', 'p', 'y'],
		// 						['h', 'i', 'n', 'd', 'i'],
		// 						['r', 'u', 's', 'o', 'm'],
		// 						['v', 'r', 'm', 'f', 'q'],
		// 						['o', 'n', 'i', 'h', 'c'],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: '644d008cb3e44f2768a39b2d',
		// 					covered: true,
		// 					wordGroup: '627aa5f7b7b377f8607e94c5',
		// 				},
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc15',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc0e',
		// 							word: 'arte',
		// 							positions: [
		// 								{
		// 									x: 0,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 3,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc0f',
		// 							word: 'etica',
		// 							positions: [
		// 								{
		// 									x: 4,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 3,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 4,
		// 								},
		// 								{
		// 									x: 0,
		// 									y: 4,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc10',
		// 							word: 'latín',
		// 							positions: [],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'asignaturas del instituto',
		// 					grid: [
		// 						[
		// 							'a',
		// 							'b',
		// 							's',
		// 							'e',
		// 							'a',
		// 							'n',
		// 							'c',
		// 							'p',
		// 							'j',
		// 							'c',
		// 						],
		// 						[
		// 							'b',
		// 							'r',
		// 							'm',
		// 							'u',
		// 							'c',
		// 							'f',
		// 							'x',
		// 							'x',
		// 							'l',
		// 							'y',
		// 						],
		// 						[
		// 							'u',
		// 							'v',
		// 							't',
		// 							'r',
		// 							'i',
		// 							'j',
		// 							'v',
		// 							'r',
		// 							'n',
		// 							'u',
		// 						],
		// 						[
		// 							'ñ',
		// 							'c',
		// 							'v',
		// 							'e',
		// 							't',
		// 							'y',
		// 							'o',
		// 							'g',
		// 							'j',
		// 							'y',
		// 						],
		// 						[
		// 							'a',
		// 							'r',
		// 							't',
		// 							'e',
		// 							'e',
		// 							'm',
		// 							'z',
		// 							'b',
		// 							'z',
		// 							'j',
		// 						],
		// 						[],
		// 						[],
		// 						[],
		// 						[],
		// 						[],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: null,
		// 					covered: false,
		// 					wordGroup: '627aa5f7b7b377f8607e94c1',
		// 				},
		// 				{
		// 					_id: '6464ed50eac1d24e7471bc16',
		// 					answers: [
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc11',
		// 							word: 'vaca',
		// 							positions: [
		// 								{
		// 									x: 2,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 2,
		// 									y: 3,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc12',
		// 							word: 'cebra',
		// 							positions: [
		// 								{
		// 									x: 1,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 1,
		// 									y: 4,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 						{
		// 							_id: '6464ed50eac1d24e7471bc13',
		// 							word: 'burro',
		// 							positions: [
		// 								{
		// 									x: 0,
		// 									y: 0,
		// 								},
		// 								{
		// 									x: 0,
		// 									y: 1,
		// 								},
		// 								{
		// 									x: 0,
		// 									y: 2,
		// 								},
		// 								{
		// 									x: 0,
		// 									y: 3,
		// 								},
		// 								{
		// 									x: 0,
		// 									y: 4,
		// 								},
		// 							],
		// 							status: 'none',
		// 						},
		// 					],
		// 					clue: 'animales vertebrados',
		// 					grid: [
		// 						['b', 'u', 'r', 'r', 'o'],
		// 						['c', 'e', 'b', 'r', 'a'],
		// 						['v', 'a', 'c', 'a', 'ñ'],
		// 						['g', 'r', 'h', 'g', 'f'],
		// 						['o', 'c', 's', 'o', 'a'],
		// 					],
		// 					rebounds: 0,
		// 					turnOwner: null,
		// 					covered: false,
		// 					wordGroup: '627aa5f7b7b377f8607e94bd',
		// 				},
		// 			],
		// 			team: Team.orange',
		// 			playing: false,
		// 			over: false,
		// 			time: 120,
		// 		},
		// 	],
		// 	kind: 'soups',
		// 	over: true,
		// },
		// {
		// 	_id: '6454c0a88131b38600a20fc8',
		// 	grids: [
		// 		{
		// 			_id: '6454c0a88131b38600a20fb4',
		// 			wordsGroup: {
		// 				_id: '62752eb7634449ec58476870',
		// 				words: [
		// 					'brazo',
		// 					'boca',
		// 					'cabeza',
		// 					'pie',
		// 					'pierna',
		// 					'cara',
		// 					'mano',
		// 					'espalda',
		// 					'codo',
		// 				],
		// 				clue: 'partes del cuerpo humano',
		// 			},
		// 			team: Team.blue',
		// 			playing: false,
		// 			time: 57,
		// 			over: false,
		// 			status: 'readyForPlaying',
		// 			turnOwner: '644d008cb3e44f2768a39b2b',
		// 			cells: [
		// 				{
		// 					_id: '6454c0a88131b38600a20fb5',
		// 					answerIndex: 2,
		// 					wordNumber: 1,
		// 					value: 'espalda',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fb6',
		// 					answerIndex: 7,
		// 					wordNumber: 2,
		// 					value: 'brazo',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fb7',
		// 					answerIndex: 5,
		// 					wordNumber: 3,
		// 					value: 'boca',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fb8',
		// 					answerIndex: 1,
		// 					wordNumber: 4,
		// 					value: 'cara',
		// 					hitted: true,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fb9',
		// 					answerIndex: 9,
		// 					wordNumber: 5,
		// 					value: 'codo',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fba',
		// 					answerIndex: 6,
		// 					wordNumber: 6,
		// 					value: 'mano',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fbb',
		// 					answerIndex: 8,
		// 					wordNumber: 7,
		// 					value: 'pierna',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fbc',
		// 					answerIndex: 3,
		// 					wordNumber: 8,
		// 					value: 'cabeza',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fbd',
		// 					answerIndex: 4,
		// 					wordNumber: 9,
		// 					value: 'pie',
		// 					hitted: false,
		// 				},
		// 			],
		// 		},
		// 		{
		// 			_id: '6454c0a88131b38600a20fbe',
		// 			wordsGroup: {
		// 				_id: '627aa5f7b7b377f8607e94c9',
		// 				words: [
		// 					'director',
		// 					'película',
		// 					'sonido',
		// 					'luces',
		// 					'guion',
		// 					'actor',
		// 					'guionista',
		// 					'cámara',
		// 					'reparto',
		// 				],
		// 				clue: 'cine',
		// 			},
		// 			team: Team.orange',
		// 			playing: false,
		// 			time: 60,
		// 			over: false,
		// 			status: 'none',
		// 			turnOwner: '644d008cb3e44f2768a39b2d',
		// 			cells: [
		// 				{
		// 					_id: '6454c0a88131b38600a20fbf',
		// 					answerIndex: 3,
		// 					wordNumber: 1,
		// 					value: 'reparto',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc0',
		// 					answerIndex: 6,
		// 					wordNumber: 2,
		// 					value: 'guionista',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc1',
		// 					answerIndex: 4,
		// 					wordNumber: 3,
		// 					value: 'luces',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc2',
		// 					answerIndex: 1,
		// 					wordNumber: 4,
		// 					value: 'director',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc3',
		// 					answerIndex: 5,
		// 					wordNumber: 5,
		// 					value: 'actor',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc4',
		// 					answerIndex: 7,
		// 					wordNumber: 6,
		// 					value: 'película',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc5',
		// 					answerIndex: 9,
		// 					wordNumber: 7,
		// 					value: 'sonido',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc6',
		// 					answerIndex: 2,
		// 					wordNumber: 8,
		// 					value: 'guion',
		// 					hitted: false,
		// 				},
		// 				{
		// 					_id: '6454c0a88131b38600a20fc7',
		// 					answerIndex: 8,
		// 					wordNumber: 9,
		// 					value: 'cámara',
		// 					hitted: false,
		// 				},
		// 			],
		// 		},
		// 	],
		// 	kind: 'memory',
		// 	over: true,
		// },
		// {
		// 	_id: '645420f18131b38600a1fcf6',
		// 	rounds: [
		// 		{
		// 			_id: '645420f18131b38600a1fce9',
		// 			players: [
		// 				'644d008cb3e44f2768a39b2b',
		// 				'644d008cb3e44f2768a39b2e',
		// 			],
		// 			clues: [
		// 				{
		// 					_id: '645420f18131b38600a1fce4',
		// 					type: 'year',
		// 					value: 1984,
		// 					score: 5,
		// 					status: 'answered',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fce5',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535146/songs/6373d36802c42d3530ee4c88.mp3',
		// 					score: 5,
		// 					status: 'none',
		// 					from: 0,
		// 					to: 3,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fce6',
		// 					type: 'lyric',
		// 					value: 'No se da ni cuenta que brillan mis ojos',
		// 					score: 4,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fce7',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535146/songs/6373d36802c42d3530ee4c88.mp3',
		// 					score: 3,
		// 					status: 'none',
		// 					from: 12,
		// 					to: 15,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fce8',
		// 					type: 'lyric',
		// 					value: 'Porque ha sido mía sin haberla amado',
		// 					score: 2,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 			],
		// 			song: {
		// 				_id: '6373d36802c42d3530ee4c88',
		// 				lyrics: [
		// 					'No se da ni cuenta que cuando la miro',
		// 					'Por no delatarme me guardo un suspiro',
		// 					'Que mi amor callado se enciende con verla',
		// 					'Que diera la vida para poseerla',
		// 					'<br>',
		// 					'No se da ni cuenta que brillan mis ojos',
		// 					'Que tiemblo a su lado y hasta me sonrojo',
		// 					'Que ella es el motivo que a mi amor despierta',
		// 					'Que ella es mi delirio y no se da cuenta',
		// 					'<br>',
		// 					'Esta cobardía de mi amor por ella',
		// 					'Hace que la vea igual que a una estrella',
		// 					'Tan lejos, tan lejos en la inmensidad',
		// 					'Que no espero nunca poderla alcanzar',
		// 					'<br>',
		// 					'Esta cobardía de mi amor por ella',
		// 					'Hace que la vea igual que a una estrella',
		// 					'Tan lejos, tan lejos en la inmensidad',
		// 					'Que no espero nunca poderla alcanzar',
		// 					'<br>',
		// 					'No se da ni cuenta que le he conseguido',
		// 					'Los cálidos besos que no me ha pedido',
		// 					'Que en mis noches tristes desiertas de sueño',
		// 					'En loco deseo me siento su dueño',
		// 					'<br>',
		// 					'No se da ni cuenta que ya la he gozado',
		// 					'Porque ha sido mía sin haberla amado',
		// 					'Que es su alma fría la que me atormenta',
		// 					'Que ve que me muero y no se da cuenta',
		// 					'<br>',
		// 					'Esta cobardía de mi amor por ella',
		// 					'Hace que la vea igual que a una estrella',
		// 					'Tan lejos, tan lejos en la inmensidad',
		// 					'Que no espero nunca poderla alcanzar',
		// 					'<br>',
		// 					'Esta cobardía de mi amor por ella',
		// 					'Hace que le vea igual que a una estrella',
		// 					'Tan lejos, tan lejos en la inmensidad',
		// 					'Que no espero nunca poderla alcanzar',
		// 				],
		// 				artist: 'Chiquetete',
		// 				name: 'Esta Cobardía - Remasterizado',
		// 				releaseYear: 1984,
		// 				trackUrl:
		// 					'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535146/songs/6373d36802c42d3530ee4c88.mp3',
		// 			},
		// 		},
		// 		{
		// 			_id: '645420f18131b38600a1fcef',
		// 			players: [
		// 				'644d008cb3e44f2768a39b2a',
		// 				'644d008cb3e44f2768a39b2d',
		// 			],
		// 			clues: [
		// 				{
		// 					_id: '645420f18131b38600a1fcea',
		// 					type: 'year',
		// 					value: 1993,
		// 					score: 5,
		// 					status: 'answered',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fceb',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535145/songs/6373d36702c42d3530ee4c84.mp3',
		// 					score: 5,
		// 					status: 'answered',
		// 					from: 0,
		// 					to: 3,
		// 					turnOwner: '644d008cb3e44f2768a39b2a',
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcec',
		// 					type: 'lyric',
		// 					value: 'Bamba, bamba',
		// 					score: 4,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fced',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535145/songs/6373d36702c42d3530ee4c84.mp3',
		// 					score: 3,
		// 					status: 'none',
		// 					from: 12,
		// 					to: 15,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcee',
		// 					type: 'lyric',
		// 					value: 'Bamba, bamba',
		// 					score: 2,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 			],
		// 			song: {
		// 				_id: '6373d36702c42d3530ee4c84',
		// 				lyrics: [
		// 					'Para bailar la bamba',
		// 					'Para bailar la bamba se necesita una poca de gracia',
		// 					"Una poca de gracia pa' mí pa' ti, ay arriba y arriba",
		// 					'Ay, arriba y arriba, por ti seré',
		// 					'Por ti seré, por ti seré',
		// 					'<br>',
		// 					'Yo no soy marinero',
		// 					'Yo no soy marinero, soy capitán',
		// 					'Soy capitán, soy capitán',
		// 					'<br>',
		// 					'Bamba, bamba',
		// 					'Bamba, bamba',
		// 					'Bamba, bamba',
		// 					'Bamba',
		// 					'<br>',
		// 					'Para bailar la bamba',
		// 					'Para bailar la bamba se necesita una poca de gracia',
		// 					"Una poca de gracia pa' mí pa' ti ah y arriba y arriba",
		// 					'<br>',
		// 					'Para bailar la bamba',
		// 					'Para bailar la bamba se necesita una poca de gracia',
		// 					"Una poca de gracia pa' mí pa' ti, ay arriba y arriba",
		// 					'Ay, arriba y arriba, por ti seré',
		// 					'Por ti seré, por ti seré',
		// 					'<br>',
		// 					'Bamba, bamba',
		// 					'Bamba, bamba',
		// 					'Bamba, bamba',
		// 					'Bamba, bamba',
		// 				],
		// 				artist: 'Los Lobos',
		// 				name: 'La Bamba',
		// 				releaseYear: 1993,
		// 				trackUrl:
		// 					'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535145/songs/6373d36702c42d3530ee4c84.mp3',
		// 			},
		// 			hittedBy: '644d008cb3e44f2768a39b2a',
		// 		},
		// 		{
		// 			_id: '645420f18131b38600a1fcf5',
		// 			players: [
		// 				'644d008cb3e44f2768a39b2c',
		// 				'644d008cb3e44f2768a39b2f',
		// 			],
		// 			clues: [
		// 				{
		// 					_id: '645420f18131b38600a1fcf0',
		// 					type: 'year',
		// 					value: 1978,
		// 					score: 5,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcf1',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535144/songs/6373d36602c42d3530ee4c80.mp3',
		// 					score: 5,
		// 					status: 'none',
		// 					from: 0,
		// 					to: 3,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcf2',
		// 					type: 'lyric',
		// 					value: '¡Hey!',
		// 					score: 4,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcf3',
		// 					type: 'songPiece',
		// 					value: 'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535144/songs/6373d36602c42d3530ee4c80.mp3',
		// 					score: 3,
		// 					status: 'none',
		// 					from: 12,
		// 					to: 15,
		// 				},
		// 				{
		// 					_id: '645420f18131b38600a1fcf4',
		// 					type: 'lyric',
		// 					value: null,
		// 					score: 2,
		// 					status: 'none',
		// 					from: null,
		// 					to: null,
		// 				},
		// 			],
		// 			song: {
		// 				_id: '6373d36602c42d3530ee4c80',
		// 				lyrics: [
		// 					'¡Hey!',
		// 					'No vayas presumiendo por ahí',
		// 					'Diciendo que no puedo estar sin ti',
		// 					'¿Tú qué sabes de mí?',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'Ya sé que a ti te gusta presumir',
		// 					'Decir a los amigos que sin ti',
		// 					'Ya no puedo vivir',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'No creas, que te haces un favor',
		// 					'Cuando hablas a la gente de mi amor',
		// 					'Y te burlas de mí',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'Que hay veces que es mejor querer así',
		// 					'Que ser querido y no poder sentir',
		// 					'Lo que siento por ti',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'Tú nunca me has querido, ya lo ves',
		// 					'Que nunca he sido tuyo, ya lo sé',
		// 					'Fue solo por orgullo ese querer',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'¿De qué te vale ahora presumir?',
		// 					'Ahora que no estoy ya junto a ti',
		// 					'¿Qué les dirás de mí?',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'Recuerdo que ganabas siempre tú',
		// 					'Que hacías de ese triunfo una virtud',
		// 					'Yo era sombra y tú luz',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'No sé si tú también recordarás',
		// 					'Que siempre que intentaba hacer la paz',
		// 					'Yo era un río en tu mar',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'Tú nunca me has querido, ya lo ves',
		// 					'Que nunca he sido tuyo, ya lo sé',
		// 					'Fue solo por orgullo ese querer',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'¿De qué te vale ahora presumir?',
		// 					'Ahora que no estoy ya junto a ti',
		// 					'¿Qué les dirás de mí?',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'Ahora que ya todo terminó',
		// 					'Que como siempre soy el perdedor',
		// 					'Cuando pienses en mí',
		// 					'<br>',
		// 					'¡Hey!',
		// 					'No creas que te guardo algún rencor',
		// 					'Es siempre más feliz quien más amó',
		// 					'Y ese siempre fui yo',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'Tú nunca me has querido, ya lo ves',
		// 					'Que nunca he sido tuyo, ya lo sé',
		// 					'Fue solo por orgullo ese querer',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'Tú nunca me has querido, ya lo ves',
		// 					'Que nunca he sido tuyo, ya lo sé',
		// 					'Fue solo por orgullo ese querer',
		// 					'<br>',
		// 					'¡Ya ves!',
		// 					'Tú nunca me has querido, ya lo ves',
		// 					'Que nunca he sido tuyo, ya lo sé',
		// 					'Fue solo por orgullo ese querer',
		// 				],
		// 				artist: 'Julio Iglesias',
		// 				name: 'Hey - Spanish',
		// 				releaseYear: 1978,
		// 				trackUrl:
		// 					'https://res.cloudinary.com/dk5vkbiof/raw/upload/v1668535144/songs/6373d36602c42d3530ee4c80.mp3',
		// 			},
		// 		},
		// 	],
		// 	kind: 'song',
		// 	over: false,
		// },
		// {
		// 	_id: '6454ab148131b38600a200a8',
		// 	over: false,
		// 	kind: 'bagels',
		// 	bagels: [
		// 		{
		// 			_id: '6454ab148131b38600a200a6',
		// 			questions: [
		// 				{
		// 					_id: '6454ab148131b38600a20074',
		// 					word: {
		// 						_id: '5e4f9afa949e66425433cc72',
		// 						name: 'alfred',
		// 						definition:
		// 							'Nombre del director de cine británico que dirigió las películas Psicosis y Los pájaros en 1960 y 1963 respectivamente',
		// 						char: 'a',
		// 					},
		// 					status: 'hit',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20076',
		// 					word: {
		// 						_id: '5c7ff76ffec544e9bface2c9',
		// 						name: 'besar',
		// 						definition:
		// 							'Tocar u oprimir con un movimiento de labios a alguien o algo como expresión de amor, deseo o reverencia, o como saludo',
		// 						char: 'b',
		// 					},
		// 					status: 'hit',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20078',
		// 					word: {
		// 						_id: '5ead9780cc210918345b7383',
		// 						name: 'cacofonía',
		// 						char: 'c',
		// 						definition:
		// 							'Combinación de palabras que resulta desagradable al oído.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007a',
		// 					word: {
		// 						_id: '5ea5ab5467b40512681c4992',
		// 						name: 'decodificador',
		// 						char: 'd',
		// 						definition:
		// 							'Dispositivo independiente que recibe y decodifica la señal de televisión, de forma que pueda visualizarse en un televisor.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007c',
		// 					word: {
		// 						_id: '636ce94d3ebb1944b87f43d5',
		// 						char: 'e',
		// 						name: 'enchufe',
		// 						definition:
		// 							'Dispositivo en el que encaja una clavija de conexión eléctrica.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007e',
		// 					word: {
		// 						_id: '5ead570d680281429864776a',
		// 						name: 'filial',
		// 						char: 'f',
		// 						definition:
		// 							'Equipo que depende de otro principal.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20080',
		// 					word: {
		// 						_id: '5e4e4cd490a295132034779c',
		// 						name: 'gasoducto',
		// 						char: 'g',
		// 						definition:
		// 							'Tubería de grueso calibre y gran longitud para conducir a distancia gas combustible procedente de emanaciones naturales',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20082',
		// 					word: {
		// 						_id: '5eaee45e68351f15c077daad',
		// 						name: 'hábitat',
		// 						char: 'h',
		// 						definition:
		// 							'Lugar con un tipo particular de ambiente en donde se encuentran conjuntos de organismos',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20084',
		// 					word: {
		// 						_id: '5ead7bf88c0e2e40807460b1',
		// 						name: 'iliada,\r\nla',
		// 						char: 'i',
		// 						definition:
		// 							'Obra de Homero cuya acción se sitúa en el último año de la guerra de Troya.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20086',
		// 					word: {
		// 						_id: '5ea5b22f06f4440dd833f47e',
		// 						name: 'jaima',
		// 						definition:
		// 							'Tienda de campaña de los pueblos nómadas del norte de África.',
		// 						char: 'j',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20088',
		// 					word: {
		// 						_id: '5e4d4e9c257de807081ab044',
		// 						name: 'látigo',
		// 						definition:
		// 							'Azote largo, delgado y flexible, de cuero, cuerda u otra materia, con que se aviva y castiga especialmente a las caballerías',
		// 						char: 'l',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008a',
		// 					word: {
		// 						_id: '5c7ff771fec544e9bface307',
		// 						name: 'masticar',
		// 						definition:
		// 							'Triturar la comida con los dientes u otros órganos bucales análogos',
		// 						char: 'm',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008c',
		// 					word: {
		// 						_id: '5e4e60c0132e703658fb4ddc',
		// 						name: 'natación',
		// 						char: 'n',
		// 						definition:
		// 							'Deporte olímpico que se practica en una piscina, consistente en pruebas de velocidad, sincronización, saltos o waterpolo',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008e',
		// 					word: {
		// 						_id: '5e4e60c0132e703658fb4dde',
		// 						name: 'seña',
		// 						char: 'ñ',
		// 						definition:
		// 							'Con la ñ: nota, indicio o gesto para dar a entender algo o venir en conocimiento de ello',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20090',
		// 					word: {
		// 						_id: '5c7ff772fec544e9bface31b',
		// 						name: 'orar',
		// 						definition:
		// 							'Dirigirse mentalmente o de palabra a una divinidad o a una persona sagrada, frecuentemente para hacerles una súplica',
		// 						char: 'o',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20092',
		// 					word: {
		// 						_id: '5e4e60c0132e703658fb4ddd',
		// 						name: 'paralímpico',
		// 						char: 'p',
		// 						definition:
		// 							'Perteneciente o relativo a las paralimpiadas',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20094',
		// 					word: {
		// 						_id: '5ead978bcc210918345b74cd',
		// 						name: 'quinteto',
		// 						char: 'q',
		// 						definition:
		// 							'Estrofa de cinco versos de arte mayor consonantes.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20096',
		// 					word: {
		// 						_id: '636ce94e3ebb1944b87f4426',
		// 						char: 'r',
		// 						name: 'ropa',
		// 						definition: 'Prenda de vestir.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20098',
		// 					word: {
		// 						_id: '5ea5b04070414358d894f8c7',
		// 						name: 'sabana',
		// 						char: 's',
		// 						definition:
		// 							'Paisaje de la acacia caven depredada por la acción destructiva del hombre.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009a',
		// 					word: {
		// 						_id: '5ead7bfe8c0e2e40807460db',
		// 						name: 'talismán',
		// 						char: 't',
		// 						definition:
		// 							'Objeto al que se atribuye un pretendido poder sobrenatural.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009c',
		// 					word: {
		// 						_id: '5e4e60c0132e703658fb4de5',
		// 						name: 'apuesta',
		// 						char: 'u',
		// 						definition:
		// 							'Con la u: acción y efecto de apostar',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009e',
		// 					word: {
		// 						_id: '636ce94d3ebb1944b87f43e4',
		// 						char: 'v',
		// 						name: 'ventana',
		// 						definition:
		// 							'Abertura en un muro o pared donde se coloca un elemento y que sirve generalmente para mirar y dar luz y ventilación.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a0',
		// 					word: {
		// 						_id: '5ea5ab5567b40512681c49a8',
		// 						name: 'píxel',
		// 						char: 'x',
		// 						definition:
		// 							'Elemento más pequeño de una imagen de televisión.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a2',
		// 					word: {
		// 						_id: '5e4c083738074d33d8fe8d8a',
		// 						name: 'ensayo',
		// 						char: 'y',
		// 						definition:
		// 							'Composición literaria que expone una o varias tesis sobre un asunto',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a4',
		// 					word: {
		// 						_id: '5ead570c680281429864774c',
		// 						name: 'derechazo',
		// 						char: 'z',
		// 						definition:
		// 							'Golpe dado con la mano o el puño derechos.',
		// 					},
		// 					status: 'none',
		// 				},
		// 			],
		// 			player: '644d008cb3e44f2768a39b2a',
		// 			hasTurn: true,
		// 			inGameQuestion: '6454ab148131b38600a20078',
		// 			baseTime: 200,
		// 			time: null,
		// 			playing: true,
		// 			over: false,
		// 		},
		// 		{
		// 			_id: '6454ab148131b38600a200a7',
		// 			questions: [
		// 				{
		// 					_id: '6454ab148131b38600a20075',
		// 					word: {
		// 						_id: '5ea5ab5467b40512681c4984',
		// 						name: 'audimetría',
		// 						char: 'a',
		// 						definition:
		// 							'Medición de la variación del índice de audiencia televisiva.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20077',
		// 					word: {
		// 						_id: '636ce94b3ebb1944b87f4393',
		// 						char: 'b',
		// 						name: 'balcón',
		// 						definition:
		// 							'Ventana abierta hasta el suelo de la habitación, generalmente con prolongación voladiza, con barandilla.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20079',
		// 					word: {
		// 						_id: '5ead9780cc210918345b7385',
		// 						name: 'cadencia',
		// 						char: 'c',
		// 						definition:
		// 							'Distribución de sonidos y acentos en un texto literario.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007b',
		// 					word: {
		// 						_id: '5ea5ab5467b40512681c4994',
		// 						name: 'desaforar',
		// 						char: 'd',
		// 						definition:
		// 							'Encuadre de imagen donde la lente rebasa el objetivo que se desea tomar.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007d',
		// 					word: {
		// 						_id: '636ce94f3ebb1944b87f444a',
		// 						char: 'e',
		// 						name: 'estantería',
		// 						definition:
		// 							'Mueble compuesto de estantes o anaqueles.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2007f',
		// 					word: {
		// 						_id: '5ead570e6802814298647772',
		// 						name: 'falta',
		// 						char: 'f',
		// 						definition:
		// 							'Infracción de las reglas del fútbol.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20081',
		// 					word: {
		// 						_id: '5eaee45e68351f15c077daa9',
		// 						name: 'gasterópodo',
		// 						char: 'g',
		// 						definition:
		// 							'Clase de los moluscos que tiene el cuerpo cubierto por una concha enrollada en espiral, como los caracoles',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20083',
		// 					word: {
		// 						_id: '5eaee45e68351f15c077daaf',
		// 						name: 'hemoglobina',
		// 						char: 'h',
		// 						definition:
		// 							'Pigmento respiratorio de los glóbulos rojos de los vertebrados',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20085',
		// 					word: {
		// 						_id: '5ead7bf98c0e2e40807460b3',
		// 						name: 'isis',
		// 						char: 'i',
		// 						definition:
		// 							'En la mitología egipcia, diosa de la fertilidad y de la maternidad.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20087',
		// 					word: {
		// 						_id: '5ea5b2fe1530ac1be048345b',
		// 						name: 'jarana',
		// 						definition: 'Diversión de gente ordinaria.',
		// 						char: 'j',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20089',
		// 					word: {
		// 						_id: '636ce94c3ebb1944b87f43c3',
		// 						char: 'l',
		// 						name: 'lavandería',
		// 						definition:
		// 							'Establecimiento industrial para el lavado de la ropa.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008b',
		// 					word: {
		// 						_id: '636ce94d3ebb1944b87f43cf',
		// 						char: 'm',
		// 						name: 'mansión',
		// 						definition: 'Casa suntuosa.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008d',
		// 					word: {
		// 						_id: '5ead570f68028142986477a8',
		// 						name: 'naranjito',
		// 						char: 'n',
		// 						definition:
		// 							'Mascota del mundial de fútbol que se celebró en España en 1982.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2008f',
		// 					word: {
		// 						_id: '5ead570b680281429864771e',
		// 						name: 'caño',
		// 						char: 'ñ',
		// 						definition:
		// 							'Acto de conseguir pasar el balón entre las piernas de otro jugador con el objetivo de desbordarlo.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20091',
		// 					word: {
		// 						_id: '636ce94d3ebb1944b87f43d2',
		// 						char: 'o',
		// 						name: 'oficina',
		// 						definition:
		// 							'Local donde se hace, se ordena o trabaja algo.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20093',
		// 					word: {
		// 						_id: '5ead570f68028142986477ae',
		// 						name: 'pabellón',
		// 						char: 'p',
		// 						definition:
		// 							'Recinto polideportivo cubierto, donde se puede jugar, entre otros, al fútbol sala.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20095',
		// 					word: {
		// 						_id: '5ead9780cc210918345b7397',
		// 						name: 'circunloquio',
		// 						char: 'q',
		// 						definition:
		// 							'Frase con la que se evita aludir directamente al asunto del discurso. Ver también Perífrasis .',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20097',
		// 					word: {
		// 						_id: '5e4d4e9c257de807081ab045',
		// 						name: 'robar',
		// 						definition:
		// 							'Quitar o tomar para sí con violencia o con fuerza lo ajeno',
		// 						char: 'r',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a20099',
		// 					word: {
		// 						_id: '5ea5b04070414358d894f8c9',
		// 						name: 'salar',
		// 						char: 's',
		// 						definition:
		// 							'Depresión terrestre en la cual se han depositado sales cristalizadas productos de la evaporación de agua salada.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009b',
		// 					word: {
		// 						_id: '5ea5ab5667b40512681c49b4',
		// 						name: 'target',
		// 						char: 't',
		// 						definition:
		// 							'Es el público objetivo al que se dirige un producto o servicio.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009d',
		// 					word: {
		// 						_id: '5ead5713680281429864781e',
		// 						name: 'ultras',
		// 						char: 'u',
		// 						definition:
		// 							'Aficionados radicales de un equipo.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a2009f',
		// 					word: {
		// 						_id: '636ce94d3ebb1944b87f43f3',
		// 						char: 'v',
		// 						name: 'vaso',
		// 						definition:
		// 							'Recipiente de vidrio por lo común de forma cilíndrica, que sirve para beber.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a1',
		// 					word: {
		// 						_id: '5e52748813c6a22d6cf65947',
		// 						name: 'aerotaxi',
		// 						char: 'x',
		// 						definition:
		// 							'Avión o avioneta de alquiler, destinado al tráfico no regular',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a3',
		// 					word: {
		// 						_id: '5ead9784cc210918345b7411',
		// 						name: 'etopeya',
		// 						char: 'y',
		// 						definition:
		// 							'Descripción del carácter, acciones y costumbres de una persona.',
		// 					},
		// 					status: 'none',
		// 				},
		// 				{
		// 					_id: '6454ab148131b38600a200a5',
		// 					word: {
		// 						_id: '5ead570d6802814298647760',
		// 						name: 'encontronazo',
		// 						char: 'z',
		// 						definition:
		// 							'Choque, encuentro sorprendente o inesperado entre dos jugadores.',
		// 					},
		// 					status: 'none',
		// 				},
		// 			],
		// 			player: '644d008cb3e44f2768a39b2d',
		// 			hasTurn: false,
		// 			inGameQuestion: '6454ab148131b38600a20075',
		// 			baseTime: 200,
		// 			time: null,
		// 			playing: false,
		// 			over: false,
		// 		},
		// 	],
		// },
	],
	viewers: 1,
	secured: true,
	name: 'Tutorial',
	players: [
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2a',
			role: PlayerRole.Player1,
			team: Team.Blue,
			user: null,
			//  {
			// 	_id: '5e500b8038d4a8365cba9989',
			// 	name: 'player1',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1677878962/avatars/5e500b8038d4a8365cba9989.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2b',
			role: PlayerRole.Guest,
			team: Team.Blue,
			user: null,
			//  {
			// 	_id: '628d42f22a891401348d31ab',
			// 	name: 'guest1',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1677879307/avatars/628d42f22a891401348d31ab.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2c',
			role: PlayerRole.Guest,
			team: Team.Blue,
			user: null,
			//  {
			// 	_id: '628d43c02a891401348d31bc',
			// 	name: 'guest2',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1677879264/avatars/628d43c02a891401348d31bc.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2d',
			role: PlayerRole.Player2,
			team: Team.Orange,
			user: null,
			//  {
			// 	_id: '5e500b9938d4a8365cba998a',
			// 	name: 'player2',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1665443984/avatars/5e500b9938d4a8365cba998a.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2e',
			role: PlayerRole.Guest,
			team: Team.Orange,
			user: null,
			//  {
			// 	_id: '628d446f2a891401348d31ce',
			// 	name: 'guest3',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1661599767/avatars/628d446f2a891401348d31ce.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b2f',
			role: PlayerRole.Guest,
			team: Team.Orange,
			user: null,
			//  {
			// 	_id: '628d46ac2a891401348d4801',
			// 	name: 'guest4',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1661599767/avatars/628d46ac2a891401348d4801.png',
			// },
		},
		{
			ready: true,
			_id: '644d008cb3e44f2768a39b30',
			role: PlayerRole.Presenter,
			user: null,
			//  {
			// 	_id: '5e500b3838d4a8365cba9988',
			// 	name: 'presenter',
			// 	avatarUrl:
			// 		'https://res.cloudinary.com/dk5vkbiof/image/upload/v1661712222/avatars/5e500b3838d4a8365cba9988.png',
			// },
		},
	],
	owner: {
		_id: '5e51579feaa900001ca9f886',
		name: 'Conor McGregor',
	} as User,
	winner: null,
};
