import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import { Theme } from './theme-switcher.models';

@Component({
	selector: 'plc-theme-switcher2',
	templateUrl: './theme-switcher2.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-theme-switcher2' },
})
export class ThemeSwitcher2Component implements OnInit, OnChanges {
	@Input({ required: true }) public initialTheme: Theme;
	@Output() public themeChange: EventEmitter<Theme> = new EventEmitter();

	private _theme: Theme;

	ngOnInit() {
		this._theme = this.initialTheme ?? 'light';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.initialTheme)
			this._theme = changes.initialTheme.currentValue;
	}

	public emitThemeChange(): void {
		this._theme = this._theme === 'light' ? 'dark' : 'light';
		this.themeChange.emit(this._theme);
	}

	public getSelectedClass(icon: 'sun' | 'moon'): string {
		if (this._theme === 'light' && icon === 'sun')
			return 'plc-theme-switcher2__sun--selected';
		else if (this._theme === 'dark' && icon === 'moon')
			return 'plc-theme-switcher2__moon--selected';
	}
}
