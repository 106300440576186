import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Host,
	Input,
	Optional,
	SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlcBaseFormControl } from '@components/models/foms.models';

const DEFAULT_VALUES = [0, 1];
const ONE_HUNDRED = 100;

@Component({
	selector: 'plc-range-input',
	templateUrl: './range-input.component.html',
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RangeInputComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-range-input' },
})
export class RangeInputComponent extends PlcBaseFormControl {
	@Input({ required: true }) public values: string[] | number[];

	public get valueIndex(): number {
		return this.values.findIndex(
			(value: string | number) => value === this._value,
		);
	}

	public get max(): number {
		return this.values ? this.values.length - 1 : 0;
	}

	public get backgroudSize(): string {
		return `${(this.valueIndex * ONE_HUNDRED) / this.max}% 100%`;
	}

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
	) {
		super(controlContainer, cdr);
	}

	ngOnInit(): void {
		if (!this.values) this.values = DEFAULT_VALUES;
		if (!this._value && this._value !== 0) this._value = 0;

		this.cdr.detectChanges();
	}

	writeValue(obj: any): void {
		this._value = obj;
		this.cdr.detectChanges();
	}

	public handleInput(value: number): void {
		this._value = this.values[value];
		this.onTouch();
		this.onChange(this._value);
	}
}
