<input
	id="file"
	type="file"
	class="plc-file-picker__input"
	accept="image/*"
	(change)="handleSelection($event.target.files)"
/>

<label for="file" class="plc-file-picker__label">
	<p class="plc-file-picker__label__text">
		{{ fileName || 'components.forms.file-picker.placeholder' | translate }}
	</p>
	<plc-icon [name]="'upload'" [color]="'white'" />
</label>
