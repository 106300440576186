import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	ViewChild,
} from '@angular/core';

export type PlcLabelAlignment = 'vertical' | 'horizontal';
export type PlcLabelDirection = 'ltr' | 'rtl' | 'center';
export type PlcLabelSize =
	| 'xs'
	| 's'
	| 'm'
	| 'l'
	| 'xl'
	| 'xxl'
	| 'xxxl'
	| 'masive';

@Component({
	selector: 'plc-label',
	templateUrl: './label.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-label' },
})
export class LabelComponent implements AfterViewInit {
	@HostBinding('class')
	public get alignmentClass(): string {
		const classes: Array<string> = [];

		classes.push(`plc-label--${this.aligment}`);
		classes.push(`plc-label--${this.direction}`);
		classes.push(`plc-label--${this.size}`);

		if (this.reversedColors) classes.push('plc-label--reversed-colors');

		return classes.join(' ');
	}

	@ViewChild('textContainer') public textContainer: ElementRef;

	@Input() public title?: string;
	@Input() public aligment: PlcLabelAlignment = 'vertical';
	@Input() public direction: PlcLabelDirection = 'ltr';
	@Input() public size: PlcLabelSize = 'm';
	@Input() public reversedColors = false;

	public get hiddenClass(): string {
		return !!this.textContainer &&
			(this.textContainer.nativeElement as HTMLElement).innerHTML.trim()
			? ''
			: 'plc-label__text--hidden';
	}

	constructor(private cdr: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		this.cdr.detectChanges();
	}

	public triggerDetection(): void {
		this.cdr.detectChanges();
	}
}
