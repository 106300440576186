<header class="plc-tabs__header">
	<section
		*ngFor="let tabHeader of tabsHeaders; let i = index"
		class="plc-tabs__header__tab"
		[class]="tabClass(i)"
		(click)="handleTabClick(i)"
	>
		<plc-icon [name]="tabHeader.icon" [size]="'m'" />

		<span class="plc-tabs__header__tab__title">
			{{ tabHeader.title }}
		</span>
	</section>
</header>

<main class="plc-tabs__content">
	<ng-content></ng-content>
</main>
