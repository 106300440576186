import { KeyValue } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Host,
	Input,
	Optional,
	SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlcBaseFormControl } from '@components/models/foms.models';

@Component({
	selector: 'plc-radio-buttons',
	templateUrl: './radio-buttons.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioButtonComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-radio-buttons' },
})
export class RadioButtonComponent extends PlcBaseFormControl {
	@Input({ required: true }) public options: KeyValue<
		string | number,
		string
	>[];
	@Input({ required: true }) public title: string;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
	) {
		super(controlContainer, cdr);
	}

	public handleCheck(value: string): void {
		this._value = value;
		this.onTouch();
		this.onChange(this._value);
	}

	public writeValue(value: string): void {
		this._value = value ? value : null;
		this.cdr.markForCheck();
	}
}
