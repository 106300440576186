<label class="plc-radio-buttons__title"> {{ title }} </label>

<main class="plc-radio-buttons__options">
	<label
		*ngFor="let item of options"
		class="plc-radio-buttons__options__label"
	>
		<input
			type="radio"
			[value]="item.key"
			[checked]="item.key === value"
			[disabled]="isDisabled"
			(change)="handleCheck($event.target.value)"
		/>

		<span class="checkmark"></span> {{ item.value | translate }}
	</label>
</main>
