import { marked } from 'marked';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'plcMarkdown',
})
export class MarkdownPipe implements PipeTransform {
	transform(value: string): unknown {
		return marked(value);
	}
}
