import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '@core/services/common/navigation.service';

@Directive({
	selector: '[plcBackNavigation]',
})
export class BackNavigationDirective {
	@HostListener('press') public back(): void {
		this.navigationService.back();
	}

	constructor(private navigationService: NavigationService) {}
}
