<div class="plc-text-input__wrapper" [class]="wrapperClass">
	<label
		class="plc-text-input__wrapper__label"
		[class]="labelClass"
		(click)="forceFocus()"
	>
		{{ label }}
	</label>
	<input
		#input
		class="plc-text-input__wrapper__input"
		[type]="type"
		[placeholder]="placeholder"
		[value]="value"
		(input)="handleInput($event.target.value)"
		(focus)="handleFocus(true)"
		(blur)="handleBlur()"
	/>

	<plc-icon
		class="plc-text-input__wrapper__icon"
		*ngIf="passwordIcon"
		[name]="passwordIcon"
		[hover]="true"
		(click)="togglePassword()"
	/>
</div>

<plc-validation-messages
	[viewMode]="viewMode"
	[validations]="validations"
	[errors]="errors"
/>
