import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { PhaseKind, PlayerRole, Team } from '@api/models/enums';
import { Game } from '@api/models/game.entity';
import { Player } from '@api/models/player.entity';
import { PlcIcon } from '@components/models/icon.models';

type PhaseData = {
	name: PlcIcon;
	over: boolean;
};

@Component({
	selector: 'plc-game-row',
	templateUrl: './game-row.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-game-row' },
})
export class GameRowComponent {
	@Input({ required: true }) public game: Game;
	@Output() public joinPress: EventEmitter<Game> = new EventEmitter();

	public get name(): string {
		return this.game.name;
	}

	public get secure(): boolean {
		return this.game.secured;
	}

	public get presenter(): Player {
		return this.game.players.find(
			({ role }) => role === PlayerRole.Presenter,
		);
	}

	public get bluePlayers(): Player[] {
		return this.game.players.filter(({ team }) => team === Team.Blue);
	}

	public get orangePlayers(): Player[] {
		return this.game.players.filter(({ team }) => team === Team.Orange);
	}

	public get viewers(): number {
		return this.game.viewers ?? 0;
	}

	public get phases(): PhaseData[] {
		return this.game.phases.map(({ kind, over }) => {
			if (kind === PhaseKind.Bagels) return { name: 'bagel', over };
			else if (kind === PhaseKind.Song) return { name: 'note', over };
			else if (kind === PhaseKind.Memory) return { name: 'brain', over };
			else if (kind === PhaseKind.Soups) return { name: 'bawl', over };
		});
	}

	public get phasesClass(): string {
		const phasesCount = this.game.phases.length;
		return phasesCount === 1
			? 'plc-game-row__row-top__phases--bagel-only'
			: '';
	}

	public emitShare(): void {
		/** */
	}

	public emitJoin(): void {
		this.joinPress.emit(this.game);
	}
}
