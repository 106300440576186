<plc-label class="plc-phase-score-counter__blue-score">
	<plc-score-counter [value]="timeBlue$ | async" [size]="'small'" />
</plc-label>

<plc-label
	[title]="'components.game.commons.phase-score-counter.round' | translate"
	[size]="'m'"
/>

<plc-label class="plc-phase-score-counter__orange-score" [direction]="'rtl'">
	<plc-score-counter [value]="timeOrange$ | async" [size]="'small'" />
</plc-label>

<plc-score-counter
	class="plc-phase-score-counter__blue-accumulated"
	[value]="timeAccumulatedBlue$ | async"
	[size]="'big'"
	[team]="'blue'"
	(valueChange)="emitValueChange($event)"
/>

<plc-label
	class="plc-phase-score-counter__accumulated"
	[title]="'components.game.commons.phase-score-counter.total' | translate"
	[size]="'xxxl'"
/>

<plc-score-counter
	class="plc-phase-score-counter__orange-accumulated"
	[value]="timeAccumulatedOrange$ | async"
	[size]="'big'"
	[team]="'orange'"
	(valueChange)="emitValueChange($event)"
/>
