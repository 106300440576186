<div @plcHeightIncrease *ngIf="!hideMode">
	<span
		class="plc-validation-messages__validation"
		*ngFor="let validation of validationsToDisplay; trackBy: trackByError"
	>
		<plc-icon
			*ngIf="validation.valid"
			class="plc-validation-messages__validation__icon"
			[name]="'tick'"
			[color]="'dark-green'"
		/>

		<plc-icon
			*ngIf="!validation.valid"
			class="plc-validation-messages__validation__icon"
			[name]="'cross'"
			[color]="'dark-red'"
		/>

		{{ validation.message | translate: validation.interpolatedParams }}
	</span>
</div>
