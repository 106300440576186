import { Injectable } from '@angular/core';

@Injectable()
export class PlcFileUtil {
	/**
	 *
	 * @param base64data the image to convert as base64
	 * @param filename the name of the resulting File
	 * @returns a File from provided base64data
	 */
	public convertToFile(base64data: string, filename: string): File {
		const arr = base64data.split(',');
		const type = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) u8arr[n] = bstr.charCodeAt(n);

		return new File([u8arr], filename, { type });
	}
}
