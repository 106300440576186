import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	UsersEventType,
	UserUpdatedEvent,
} from '@core/models/users/events/user.events';

@Injectable()
export class UsersSockets {
	public updated$ = this.socket.fromEvent<UserUpdatedEvent>(
		UsersEventType.Updated,
	);

	constructor(private socket: Socket) {}

	public join(userId: string): void {
		this.socket.emit(UsersEventType.Joined, userId);
	}
}
