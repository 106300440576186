<button
	*ngFor="let option of optionsToDisplay; let i = index"
	class="plc-toggle-button__option"
	[class]="getOptionClasses(i)"
	(click)="select(i)"
	[disabled]="disabled"
>
	<plc-avatar [src]="option.avatar" [shape]="'circle'" [animated]="false" />

	<plc-label *ngIf="!imageOnly">
		{{ option.name }}
	</plc-label>
</button>
