<plc-avatar [src]="'assets/images/logo.svg'" />

<plc-theme-switcher
	[initialTheme]="theme"
	(themeChange)="emitThemeChange($event)"
/>

<plc-desktop-menu
	[userData]="userData"
	[entries]="entries"
	[volume]="volume"
	[lang]="lang"
	(pressOption)="emitPressOption($event)"
	(volumeChange)="emitVolumeChange($event)"
	(langChange)="emitLangChange($event)"
/>
