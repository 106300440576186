import { Socket } from 'ngx-socket-io';

import { Injectable } from '@angular/core';
import {
	PlayersEventType,
	PlayersUpdatedEvent,
} from '@core/models/games/events/players.events';

@Injectable()
export class PlayersSockets {
	public updated$ = this.socket.fromEvent<PlayersUpdatedEvent>(
		PlayersEventType.Updated,
	);

	constructor(private socket: Socket) {}
}
