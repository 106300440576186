import { Player } from '@api/models/player.entity';
import { SongClue } from '@api/models/song-clue.entity';
import { SongPhase } from '@api/models/song-phase.entity';

export enum SongPhaseEventType {
	ClueUpdated = 'song-phase:clue-updated',
	RoundUpdated = 'song-phase:round-updated',
	Finished = 'song-phase:finished',
}

export class SongPhaseClueUpdatedEvent {
	public gameId: string;
	public songRoundId: string;
	public songClue: SongClue;
}

export class SongPhaseRoundUpdatedEvent {
	public gameId: string;
	public songRoundId: string;
	public hittedBy: string | Player;
}

export class SongPhaseFinishedEvent {
	public gameId: string;
	public phase: SongPhase;
}

export type SongPhaseEvent =
	| SongPhaseClueUpdatedEvent
	| SongPhaseRoundUpdatedEvent;
