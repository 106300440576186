import { MemoryCell } from '@api/models/memory-cell.entity';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { MemoryPhase } from '@api/models/memory-phase.entity';

export enum MemoryPhaseEventType {
	TimeChanged = 'memory-phase:time-changed',
	GridUpdated = 'memory-phase:grid-updated',
	GridMissed = 'memory-phase:grid-missed',
	GridFixed = 'memory-phase:grid-fixed',
	CellUpdated = 'memory-phase:cell-updated',
	Finish = 'memory-phase:finish',
}

export class MemoryPhaseChangeTimeEvent {
	public gameId: string;
	public memoryGridId: string;
	public time: number;
}

export class MemoryPhaseGridUpdatedEvent {
	public gameId: string;
	public memoryGrid: MemoryGrid;
}

export class MemoryPhaseGridMissedEvent {
	public gameId: string;
	public memoryGrid: MemoryGrid;
}

export class MemoryPhaseGridFixedEvent {
	public gameId: string;
	public memoryGrid: MemoryGrid;
}

export class MemoryPhaseCellUpdatedEvent {
	public gameId: string;
	public memoryGridId: string;
	public cell: MemoryCell;
}

export class MemoryPhaseUpdateEvent {
	public gameId: string;
	public memoryPhase: MemoryPhase;
}
