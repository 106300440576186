import { Player } from '@api/models/player.entity';

export enum PlayersEventType {
	Updated = 'players:updated',
}

export class PlayersUpdatedEvent {
	public gameId: string;
	public player: Player;
}
