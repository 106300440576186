import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsSharedModule } from '../shared/components-shared.module';
import { CheckPanelGroupComponent } from './check-panel-group/check-panel-group.component';
import { CheckPanelComponent } from './check-panel-group/check-panel.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { FocusDirective } from './pin-code/focus.directive';
import { PinCodeComponent } from './pin-code/pin-code.component';
import { RadioButtonComponent } from './radio-buttons/radio-buttons.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';

@NgModule({
	declarations: [
		CheckPanelGroupComponent,
		CheckPanelComponent,
		FilePickerComponent,
		PinCodeComponent,
		FocusDirective,
		RadioButtonComponent,
		TextInputComponent,
		NumberInputComponent,
		ValidationMessagesComponent,
	],
	imports: [
		CommonModule,
		ComponentsSharedModule,
		TranslateModule.forChild({
			extend: true,
		}),
	],
	exports: [
		CheckPanelGroupComponent,
		CheckPanelComponent,
		CheckboxComponent,
		FilePickerComponent,
		PinCodeComponent,
		RadioButtonComponent,
		RangeInputComponent,
		TextInputComponent,
		NumberInputComponent,
	],
})
export class PlcFormsModule {}
