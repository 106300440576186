import { BehaviorSubject, Observable } from 'rxjs';

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Team } from '@api/models/enums';

export const SCORE_COUNTER_ANIMATION = 1000;
export type ScoreCounterSize = 'small' | 'medium' | 'big';

@Component({
	selector: 'plc-score-counter',
	templateUrl: './score-counter.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-score-counter' },
})
export class ScoreCounterComponent implements OnInit, OnChanges {
	@HostBinding('class')
	public get hostClass(): string {
		const classes: string[] = [];

		classes.push(`plc-score-counter--${this.size}`);

		if (this.team) classes.push(`plc-score-counter--${this.team}`);

		return classes.join(' ');
	}

	@Input({ required: true }) public value: number;
	@Input() public team?: Team;
	@Input() public size: ScoreCounterSize = 'small';
	@Output() public valueChange = new EventEmitter<number>();

	public get value$(): Observable<number> {
		return this._value$.asObservable();
	}

	private _value$: BehaviorSubject<number> = new BehaviorSubject(0);

	ngOnInit(): void {
		this._value$.next(this.value);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			const { currentValue, previousValue } = changes.value;

			const valuesToEmit: number[] = [];

			if (currentValue > previousValue) {
				for (
					let value = previousValue ?? 0;
					value <= currentValue + 1;
					value++
				)
					valuesToEmit.push(value);
			} else {
				for (
					let value = previousValue;
					value >= currentValue - 1;
					value--
				)
					valuesToEmit.push(value);
			}

			let timeout: NodeJS.Timeout;
			for (const [index, value] of valuesToEmit.entries()) {
				timeout = setTimeout(
					() => {
						this._value$.next(value);
						this.valueChange.emit(value);
						clearTimeout(timeout);
					},
					(SCORE_COUNTER_ANIMATION / valuesToEmit.length) * index,
				);
			}
		}
	}
}
