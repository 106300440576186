import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	PlcHttpConfig,
	PlcHttpConfigService,
} from '@core/models/core-module.models';
import { MemoryWordsGroupMetrics } from '@core/models/memory-word-groups/memory-word-groups-metrics.entity';
import { SongMetrics } from '@core/models/songs/entity/song-metrics.entity';
import { WordMetrics } from '@core/models/words/entity/word-metrics.entity';

@Injectable()
export class MetricsService {
	constructor(
		private readonly http: HttpClient,
		@Inject(PlcHttpConfigService) private config: PlcHttpConfig,
	) {}

	public getWordsMetrics(): Observable<WordMetrics> {
		return this.http.get<WordMetrics>(
			`${this.config.newUrl}/v1/metrics/words`,
		);
	}

	public getSonsMetrics(): Observable<SongMetrics> {
		return this.http.get<SongMetrics>(
			`${this.config.newUrl}/v1/metrics/songs`,
		);
	}

	public getMemoryWordsGroupMetrics(): Observable<MemoryWordsGroupMetrics> {
		return this.http.get<MemoryWordsGroupMetrics>(
			`${this.config.newUrl}/v1/metrics/memory-groups`,
		);
	}
}
