/* eslint-disable @typescript-eslint/member-ordering */
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { WizardPageConfig } from '@bussiness/store/components/components-facade';

import { WindowSize } from './wizard.models';

const TOP_LEFT_GAP = 10;
const SIZE_GAP = 20;
@Component({
	selector: 'plc-wizard',
	templateUrl: './wizard.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardComponent implements AfterViewInit {
	@Input({ required: true }) public config: WizardPageConfig;

	@Output() public pressSkip: EventEmitter<void> = new EventEmitter();

	public get currentTooltip(): number {
		return this._currentTooltip;
	}

	public get totalTootips(): number {
		if (!this.config || !this.config.tooltips) return 0;

		const { tooltips } = this.config;
		return tooltips.length;
	}

	public get holeStyle(): {
		top: string;
		left: string;
		width: string;
		height: string;
	} {
		const { elementInfo } = this.config.tooltips[this.currentTooltip];
		const { top, left, width, height } = elementInfo;

		return {
			top: `${top - TOP_LEFT_GAP}px`,
			left: `${left - TOP_LEFT_GAP}px`,
			width: `${width + SIZE_GAP}px`,
			height: `${height + SIZE_GAP}px`,
		};
	}

	public get parentSize(): WindowSize {
		return this._parentSize ? this._parentSize : { width: 0, height: 0 };
	}

	public tooltipAnimation = (
		index: number,
	): 'hidden' | 'current' | 'previous' => {
		if (this._currentTooltip === index) return 'current';
		else if (this._previousTooltip === index) return 'previous';
		else return 'hidden';
	};

	private _currentTooltip = 0;
	private _previousTooltip: number = null;
	private _parentSize: WindowSize;

	constructor(
		private el: ElementRef,
		private cdr: ChangeDetectorRef,
	) {}

	ngAfterViewInit() {
		const [backgroundDiv] = this.el.nativeElement.querySelectorAll(
			'.plc-background-screen',
		);
		const { offsetWidth, offsetHeight } = backgroundDiv;
		this._parentSize = { width: offsetWidth, height: offsetHeight };
		this.cdr.detectChanges();
	}

	public showNext(): void {
		this._previousTooltip = this._currentTooltip;

		if (this._currentTooltip + 1 <= this.totalTootips - 1)
			this._currentTooltip++;
		else this._currentTooltip = 0;
	}

	public showPrevious(): void {
		this._previousTooltip = this._currentTooltip;

		if (this._currentTooltip - 1 >= 0) this._currentTooltip--;
		else this._currentTooltip = this.totalTootips - 1;
	}

	public emitSkip(): void {
		this._previousTooltip = null;
		this.pressSkip.emit();
	}
}
