<span class="plc-label__title" *ngIf="title">
	{{ title }}
</span>
<span
	#textContainer
	class="plc-label__text"
	[class]="hiddenClass"
	(cdkObserveContent)="triggerDetection()"
>
	<ng-content></ng-content>
</span>
