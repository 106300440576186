import { Injectable } from '@angular/core';

class AudioPlayer {
	public key: string;
	public player: HTMLAudioElement;
}

export class AudioConstats {
	public static readonly cheerDuration = 2000;
	public static readonly fullBagelDuration = 29000;
}

export const AUDIO_DIGIT_DURATION = 500;

export interface AudioPlayOptions {
	from?: number;
	to?: number;
	loop?: boolean;
	volume?: number;
}

/**
 * Since volume values provided goes from 0 to 1, we need to convert it
 * to a value from 0 to 1.
 * See more:
 * @link https://www.w3schools.com/jsref/prop_audio_volume.asp
 */
const AUDIO_MULTIPLYER = 0.1;
@Injectable()
export class AudioFeature {
	private _audioPlayers: AudioPlayer[] = [];

	public play(key: string, src: string, options?: AudioPlayOptions): void {
		const audioPlayer = new Audio(src);
		this._audioPlayers.push({ key, player: audioPlayer });

		audioPlayer.play().catch((error: any) => {
			throw new Error(error);
		});

		if (options && options.loop) audioPlayer.loop = true;

		if (options && options.volume >= 0)
			audioPlayer.volume = options.volume * 0.1;

		if (options && options.from >= 0 && options.to >= 0) {
			audioPlayer.currentTime = options.from;
			audioPlayer.ontimeupdate = (): void => {
				if (audioPlayer.currentTime >= options.to) audioPlayer.pause();
			};
		}

		audioPlayer.onended = (): void => {
			this._audioPlayers = this._audioPlayers.filter(
				(player: AudioPlayer) => player.key !== key,
			);
		};
	}

	public stop(key: string): void {
		const player = this._audioPlayers.find(
			(player: AudioPlayer) => player.key === key,
		);

		if (player) player.player.pause();

		this._audioPlayers = this._audioPlayers.filter(
			(player: AudioPlayer) => player.key !== key,
		);
	}

	public changeVolume(volume: number): void {
		this._audioPlayers.forEach(
			(player: AudioPlayer) =>
				(player.player.volume = volume * AUDIO_MULTIPLYER),
		);
	}
}
