<main class="plc-game-row__small">
	<section class="plc-game-row__row-top">
		<plc-label
			[title]="'components.game.commons.game-row.name' | translate"
			[aligment]="'center'"
		>
			{{ name }}
		</plc-label>

		<div class="plc-game-row__row-top__phases" [class]="phasesClass">
			<plc-label
				[title]="'components.game.commons.game-row.phases' | translate"
			/>
			<plc-icon
				*ngFor="let phase of phases"
				[name]="phase.name"
				[color]="phase.over ? 'dark-red' : null"
			/>
		</div>
	</section>

	<section class="plc-game-row__row-bottom">
		<section class="plc-game-row__row-bottom__icons">
			<plc-icon
				*ngIf="secure"
				[name]="'lock'"
				[color]="'dark-red'"
				[size]="'xl'"
			/>
			<plc-icon *ngIf="!secure" [name]="'unlock'" [size]="'xl'" />

			<plc-icon-badge
				[icon]="'tv'"
				[counter]="viewers"
				[size]="'small'"
			/>
		</section>

		<section class="plc-game-row__row-bottom__players">
			<header class="plc-game-row__row-bottom__players__header">
				<plc-label
					[title]="
						'components.game.commons.game-row.players' | translate
					"
				/>
			</header>

			<main class="plc-game-row__row-bottom__players__main">
				<plc-icon
					*ngFor="let player of bluePlayers; let i = index"
					[class.plc-game-row__row-bottom__players__main--first]="
						i === 0
					"
					[name]="player.user ? 'user-fill' : 'user'"
					[color]="'dark-blue'"
				/>
				<plc-icon
					class="plc-game-row__row-bottom__players__main--presenter"
					*ngIf="presenter"
					[name]="presenter ? 'user-fill' : 'user'"
				/>
				<plc-icon
					*ngFor="let player of orangePlayers; let i = index"
					[class.plc-game-row__row-bottom__players__main--first]="
						i === 0
					"
					[name]="player.user ? 'user-fill' : 'user'"
					[color]="'dark-orange'"
				/>
			</main>
		</section>

		<aside class="plc-game-row__row-bottom__buttons">
			<plc-button
				[type]="'outline'"
				[icon]="'share'"
				[onlyIcon]="true"
				(press)="emitShare()"
			/>

			<plc-button
				[type]="'primary'"
				[icon]="'right-arrow'"
				[onlyIcon]="true"
				(press)="emitJoin()"
			/>
		</aside>
	</section>
</main>

<main class="plc-game-row__big">
	<section class="plc-game-row__row-bottom__icons">
		<plc-icon
			*ngIf="secure"
			[name]="'lock'"
			[color]="'dark-red'"
			[size]="'xl'"
		/>
		<plc-icon *ngIf="!secure" [name]="'unlock'" [size]="'xl'" />

		<plc-icon-badge [icon]="'tv'" [counter]="viewers" [size]="'small'" />
	</section>

	<section class="plc-game-row__row-bottom__col-2">
		<plc-label
			[title]="'components.game.commons.game-row.name' | translate"
			[aligment]="'center'"
		>
			{{ name }}
		</plc-label>

		<div class="plc-game-row__row-top__phases">
			<plc-label
				[title]="'components.game.commons.game-row.phases' | translate"
			/>
			<plc-icon
				*ngFor="let phase of phases"
				[name]="phase.name"
				[color]="phase.over ? 'dark-red' : null"
				[size]="'l'"
			/>
		</div>
	</section>

	<section class="plc-game-row__row-bottom__players">
		<header class="plc-game-row__row-bottom__players__header">
			<plc-label
				[title]="'components.game.commons.game-row.players' | translate"
			/>
		</header>

		<main class="plc-game-row__row-bottom__players__main">
			<plc-icon
				*ngFor="let player of bluePlayers; let i = index"
				[class.plc-game-row__row-bottom__players__main--first]="i === 0"
				[name]="player.user ? 'user-fill' : 'user'"
				[color]="'dark-blue'"
				[size]="'l'"
			/>
			<plc-icon
				class="plc-game-row__row-bottom__players__main--presenter"
				*ngIf="presenter"
				[name]="presenter ? 'user-fill' : 'user'"
				[size]="'l'"
			/>
			<plc-icon
				*ngFor="let player of orangePlayers; let i = index"
				[class.plc-game-row__row-bottom__players__main--first]="i === 0"
				[name]="player.user ? 'user-fill' : 'user'"
				[color]="'dark-orange'"
				[size]="'l'"
			/>
		</main>
	</section>

	<aside class="plc-game-row__row-bottom__buttons">
		<plc-button [type]="'outline'" [icon]="'share'" (press)="emitShare()">
			{{ 'components.game.commons.game-row.share' | translate }}
		</plc-button>

		<plc-button
			[type]="'primary'"
			[icon]="'right-arrow'"
			(press)="emitJoin()"
		>
			{{ 'components.game.commons.game-row.join' | translate }}
		</plc-button>
	</aside>
</main>
