import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';

@Component({
	selector: 'plc-background-screen',
	templateUrl: './background-screen.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-background-screen' },
})
export class BackgroundScreenComponent {
	@HostBinding('class') public get bgClass(): string {
		return `plc-background-screen--${this.type}`;
	}

	@HostBinding('style.position') public get pos(): string {
		return this.position;
	}

	@Input() public type: 'plain' | 'gradient' | 'transparent' = 'plain';
	@Input() public position: 'absolute' | 'initial' = 'absolute';
	@Input() public transparent = false;
}
