import { BagelQuestion } from '@api/models/bagel-question.entity';
import { Bagel } from '@api/models/bagel.entity';

export enum BagelPhaseEventType {
	TimeChanged = 'bagel-phase:time-changed',
	Created = 'bagel-phase:created',
	BagelUpdated = 'bagel-phase:bagel-updated',
	QuestionUpdated = 'bagel-phase:word-updated',
}

export class BagelPhaseTimeChangedEvent {
	public gameId: string;
	public bagelId: string;
	public time: number;
}

export class BagelPhaseBagelUpdatedEvent {
	public gameId: string;
	public bagel: Bagel;
}

export class BagelPhaseCreatedEvent {
	public gameId: string;
	public bagels: Bagel[];
}

export class BagelPhaseWordUpdatedEvent {
	public gameId: string;
	public bagelId: string;
	public bagelQuestion: BagelQuestion;
}
