import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Output,
} from '@angular/core';

@Component({
	selector: 'plc-link',
	templateUrl: './link.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-link' },
})
export class LinkComponent {
	@HostListener('click')
	public emitPress(): void {
		this.press.next();
	}

	@Output() public press: EventEmitter<void> = new EventEmitter();
}
