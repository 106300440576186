import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'plc-stepper',
	templateUrl: './stepper.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-stepper' },
})
export class StepperComponent {
	@Input({ required: true }) public steps: string[];
	@Input() public currentStep = 0;

	public get lastStep(): number {
		return this.steps.length - 1;
	}

	public get currentStepName(): string {
		return this.steps ? this.steps[this.currentStep] : '';
	}

	public dotClass = (index: number): string => {
		return this.currentStep >= index ? 'plc-stepper__step__dot--curr' : '';
	};

	public lineClass = (index: number): string => {
		return this.currentStep > index ? 'plc-stepper__step__line--curr' : '';
	};
}
