import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import {
	PointerType,
	TooltipPlacingInfo,
	WindowSize,
	WizardTooltipConfig,
} from './wizard.models';
import { WizardService } from './wizard.service';

const HALF = 2;
@Component({
	selector: 'plc-tooltip',
	templateUrl: './tooltip.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-tooltip' },
})
export class TooltipComponent implements OnInit {
	@HostBinding('class')
	public get animationClass(): string {
		if (this.animation === 'hidden') return 'plc-tooltip--hidden';
		else if (this.animation === 'current') return 'plc-tooltip--current';
		else if (this.animation === 'previous') return 'plc-tooltip--previous';
	}

	@HostBinding('style.top')
	public get top(): string {
		const { top } = this._placingInfo;
		return `${top}px`;
	}

	@HostBinding('style.left')
	public get left(): string {
		const { left } = this._placingInfo;
		return `${left}px`;
	}

	@Input({ required: true }) public config: WizardTooltipConfig;
	@Input({ required: true }) public index: number;
	@Input({ required: true }) public totalTooltips: number;
	@Input({ required: true }) public parentSize: WindowSize;
	@Input({ required: true }) public animation:
		| 'hidden'
		| 'current'
		| 'previous';

	@Output() public pressPrevious: EventEmitter<void> = new EventEmitter();
	@Output() public pressNext: EventEmitter<void> = new EventEmitter();
	@Output() public pressSkip: EventEmitter<void> = new EventEmitter();

	public get isLast(): boolean {
		return this.index + 1 === this.totalTooltips;
	}

	public get pointerClass(): string {
		const { pointerType } = this._placingInfo;
		return `plc-tooltip__pointer--${pointerType.toString()}`;
	}

	public get position(): { top: string; left: string } {
		const pointerWidth = 50;

		const {
			elementInfo: { top, left, width, height },
		} = this.config;
		const { top: tooltipTop, left: tooltipLeft } = this._placingInfo;

		const elLeftCenter =
			left + (width / HALF - pointerWidth / HALF - tooltipLeft);
		const elTopCenter =
			top + (height / HALF - pointerWidth / HALF - tooltipTop);

		const { pointerType } = this._placingInfo;

		if (pointerType === PointerType.Left)
			return { top: `${elTopCenter}px`, left: '-18px' };
		else if (pointerType === PointerType.Top)
			return { top: `-18px`, left: `${elLeftCenter}px` };
		else if (pointerType === PointerType.Right)
			return { top: `${elTopCenter}px`, left: `calc(100% - 18px)` };
		else if (pointerType === PointerType.Bottom)
			return { top: `calc(100% - 18px)`, left: `${elLeftCenter}px` };

		return { top: `0`, left: '0' };
	}

	private _placingInfo: TooltipPlacingInfo;

	constructor(
		private wizardService: WizardService,
		private cdr: ChangeDetectorRef,
		private el: ElementRef,
	) {}

	ngOnInit() {
		this._placingInfo = {
			top: 0,
			left: 0,
			pointerType: PointerType.None,
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.parentSize) return;

		const {
			parentSize: { currentValue },
		} = changes;
		if (!currentValue || (currentValue as WindowSize).height === 0) return;

		const wizardSize = currentValue as WindowSize;

		const { offsetWidth, offsetHeight } = this.el.nativeElement;
		const tooltipSize = { width: offsetWidth, height: offsetHeight };
		const { elementInfo } = this.config;

		this._placingInfo = this.wizardService.computePlacing(
			wizardSize,
			tooltipSize,
			elementInfo,
		);
		this.cdr.markForCheck();
	}

	public emitPressNext(): void {
		this.pressNext.emit();
	}

	public emitPressPrevious(): void {
		this.pressPrevious.emit();
	}

	public emitSkip(): void {
		this.pressSkip.emit();
	}
}
