import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlcCommonModule } from '@components/modules/common/common.module';
import { TranslateModule } from '@ngx-translate/core';

import { ClueTimerComponent } from './clue-timer/clue-timer.component';
import { GameRowComponent } from './game-row/game-row.component';
import { PhaseScoreCounterComponent } from './phase-score-counter/phase-score-counter.component';
import { PlayersOverviewComponent } from './players-overview/players-overview.component';
import { RoleCardComponent } from './role-card/role-card.component';
import { ScoreCounterComponent } from './score-counter/score-counter.component';
import { ToggleButtonTwoComponent } from './toggle-button/toggle-button-two.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { ToCircleEdgeDirective } from './webcam/to-circle-edge.directive';
import { WebcamComponent } from './webcam/webcam.component';

@NgModule({
	declarations: [
		ClueTimerComponent,
		GameRowComponent,
		PhaseScoreCounterComponent,
		PlayersOverviewComponent,
		RoleCardComponent,
		ScoreCounterComponent,
		ToggleButtonComponent,
		ToggleButtonTwoComponent,
		WebcamComponent,
		ToCircleEdgeDirective,
	],
	imports: [
		CommonModule,
		PlcCommonModule,
		TranslateModule.forChild({
			extend: true,
		}),
	],
	exports: [
		ClueTimerComponent,
		GameRowComponent,
		PhaseScoreCounterComponent,
		PlayersOverviewComponent,
		RoleCardComponent,
		ScoreCounterComponent,
		ToggleButtonComponent,
		ToggleButtonTwoComponent,
		WebcamComponent,
		ToCircleEdgeDirective,
	],
})
export class PlcGameCommonsModule {}
