import { filter } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostBinding,
	inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlcUnsubscribable } from '@core/interfaces/subscribable.interface';

import { DialogService, PlcDialogToggleParams } from './dialog.service';

const WRAPPER_TAG_NAME = 'plc-dialog-wrapper';

@Component({
	selector: 'plc-dialog',
	templateUrl: './dialog.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-dialog' },
})
export class DialogComponent implements OnInit, OnDestroy, PlcUnsubscribable {
	@HostBinding('style.display')
	public get display(): string {
		return this._display ? 'grid' : 'none';
	}

	@Input({ required: true }) public title: string;
	@Input({ required: true }) public name: string;

	public destroyRef: DestroyRef = inject(DestroyRef);

	private _display: boolean;

	constructor(
		private el: ElementRef,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef,
	) {}

	/**
	 * In this hook the elementRef will be appended to the plc-dialog-wrapper.
	 * If no wrapper is available in the DOM an error will be thrown.
	 */
	ngOnInit() {
		if (!this.name) throw Error('The @Input "name" must be provided');

		const wrapperEls: HTMLCollectionOf<Element> =
			document.body.getElementsByTagName(WRAPPER_TAG_NAME);
		if (wrapperEls.length === 0)
			throw Error(
				`No wrapper component found. You must add a "${WRAPPER_TAG_NAME}" to the DOM!`,
			);

		const wrapperEl = wrapperEls.item(0);
		wrapperEl.firstElementChild.appendChild(this.el.nativeElement);

		this.dialogService.add(this.name);

		this.dialogService.toggle$
			.pipe(
				filter(({ name }: PlcDialogToggleParams) => name === this.name),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(({ action }: PlcDialogToggleParams) => {
				this._display = action === 'show';
				this.cdr.markForCheck();
			});
	}

	ngOnDestroy() {
		this.dialogService.remove(this.name);
	}

	public close(): void {
		this.dialogService.toggle({ name: this.name, action: 'hide' });
	}
}
