/* eslint-disable @typescript-eslint/no-empty-function */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	Host,
	Input,
	Optional,
	QueryList,
	Renderer2,
	SkipSelf,
	ViewChildren,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	PlcBaseFormControl,
	PlcInputType,
} from '@components/models/foms.models';
import { PlcIcon } from '@components/models/icon.models';

const DEFAULT_PIN_SIZE = 4;
@Component({
	selector: 'plc-pin-code',
	templateUrl: './pin-code.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PinCodeComponent),
			multi: true,
		},
	],
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-pin-code' },
})
export class PinCodeComponent extends PlcBaseFormControl {
	@ViewChildren('char_input') public inputs: QueryList<ElementRef>;

	@Input() public size = DEFAULT_PIN_SIZE;
	@Input({ required: true }) public label: string;
	@Input() public set autofocus(value: boolean) {
		this._autofocusEnabled = value ?? false;
	}

	public icon: PlcIcon = 'eye';

	public get chars(): void[] {
		return new Array(this.size);
	}

	public get valueLength(): number {
		return this._value ? (this._value as string).split('').length : 0;
	}

	public get type(): PlcInputType {
		return this._showPassword ? 'text' : 'password';
	}

	public get focused(): boolean {
		if (!this.inputs) return false;
		const inputs = this.inputs.toArray();
		return inputs.some(
			(input) => input.nativeElement === document.activeElement,
		);
	}

	public get autofocusEnabled(): boolean {
		return this._autofocusEnabled;
	}

	private _showPassword: boolean;
	private _autofocusEnabled = false;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
		private renderer: Renderer2,
	) {
		super(controlContainer, cdr);
	}

	public handleFocusBy(index: number): void {
		this._autofocusEnabled = true;
		if (index > this.valueLength) {
			const inputs = this.inputs.toArray();
			const inputToFocus = inputs[this.valueLength];
			inputToFocus.nativeElement.focus();
		}
	}

	public handleInputBy(value: string, index: number): void {
		if (!this._value) this._value = '';

		const valueChars = (this._value as string).split('');

		if (value) valueChars.splice(index, 0, value);
		else valueChars.splice(index, 1, value);

		this._value = valueChars.join('');
		this.onTouch();
		this.onChange(this._value);
	}

	public handlePaste(event: ClipboardEvent): void {
		event.preventDefault();

		const pastedText = event.clipboardData.getData('text');
		if (pastedText.length === this.size) {
			const inputs = this.inputs.toArray();
			const chars = pastedText.split('');

			for (let i = 0; i < chars.length; i++)
				this.renderer.setProperty(
					inputs[i].nativeElement,
					'value',
					chars[i],
				);

			this._value = pastedText;
			this.onChange(pastedText);
		}
	}

	public handleKeydown(event: KeyboardEvent, index: number): void {
		const deleteCharCode = 'Backspace';
		if (event.key === deleteCharCode && this.valueLength === index)
			this.handleInputBy('', index - 1);
	}

	public togglePassword(): void {
		this._showPassword = !this._showPassword;
		this.icon = this._showPassword ? 'eye-closed' : 'eye';
	}

	public getCharBy(index: number): string {
		if (!this._value) return '';
		const chars = (this._value as string).split('');
		return chars[index] || '';
	}
}
