import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownPipe } from '@components/pipes/markdown.pipe';

import { AvatarComponent } from '../common/avatar/avatar.component';
import { ButtonComponent } from '../common/button/button.component';
import { FlagPickerComponent } from '../common/flag-picker/flag-picker.component';
import { IconBadgeComponent } from '../common/icon-badge/icon-badge.component';
import { IconComponent } from '../common/icon/icon.component';
import { LabelComponent } from '../common/label/label.component';
import { ThemeSwitcherComponent } from '../common/theme-switcher/theme-switcher.component';
import { ThemeSwitcher2Component } from '../common/theme-switcher/theme-switcher2.component';
import { CheckboxComponent } from '../forms/checkbox/checkbox.component';
import { RangeInputComponent } from '../forms/range-input/range-input.component';
import { BackgroundScreenComponent } from '../layout/background-screen/background-screen.component';
import { TypeWritterDirective } from './type-writter.directive';

@NgModule({
	declarations: [
		AvatarComponent,
		MarkdownPipe,
		IconComponent,
		IconBadgeComponent,
		LabelComponent,
		CheckboxComponent,
		ButtonComponent,
		TypeWritterDirective,
		BackgroundScreenComponent,
		ThemeSwitcherComponent,
		ThemeSwitcher2Component,
		RangeInputComponent,
		FlagPickerComponent,
	],
	imports: [CommonModule],
	exports: [
		AvatarComponent,
		MarkdownPipe,
		IconComponent,
		IconBadgeComponent,
		CheckboxComponent,
		LabelComponent,
		ButtonComponent,
		TypeWritterDirective,
		BackgroundScreenComponent,
		ThemeSwitcherComponent,
		ThemeSwitcher2Component,
		RangeInputComponent,
		FlagPickerComponent,
	],
})
export class ComponentsSharedModule {}
