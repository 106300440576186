import { User } from '@api/models/user.entity';

export enum UsersEventType {
	Updated = 'users:updated',
	Joined = 'users:joined',
}

export class UserUpdatedEvent {
	public user: User;
}
