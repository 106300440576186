import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import { PlayerRole, Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { User } from '@api/models/user.entity';
import { plcAccordionAnimation } from '@components/animations/accordion.animation';

export const PLAYER_OVERVIEW_ANIMATION_DURATION = 1500;

@Component({
	selector: 'plc-players-overview',
	templateUrl: './players-overview.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [plcAccordionAnimation],
	host: { class: 'plc-players-overview' },
})
export class PlayersOverviewComponent {
	@HostBinding('class')
	public get hostClass(): string {
		const twoPlayerMode = this.players.length === 2;
		return twoPlayerMode ? 'plc-players-overview--two-col' : '';
	}

	@Input({ required: true }) public players: Player[];

	public get blueTeamUsers(): Partial<User>[] {
		return this.players
			.filter(({ team }: Player) => team === Team.Blue)
			.map(({ user }: Player) => user as Partial<User>);
	}

	public get orangeTeamUsers(): Partial<User>[] {
		return this.players
			.filter(({ team }: Player) => team === Team.Orange)
			.map(({ user }: Player) => user as Partial<User>);
	}

	public get presenter(): Partial<User> {
		const presenter = this.players.find(
			({ role }: Player) => role === PlayerRole.Presenter,
		);

		return presenter ? (presenter.user as Partial<User>) : null;
	}

	public get teamClass(): string {
		return this.blueTeamUsers.length < 3 && this.orangeTeamUsers.length < 3
			? 'plc-players-overview__team--forzed-one-col'
			: '';
	}
}
