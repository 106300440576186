import { createAction, props } from '@ngrx/store';

export enum ToastActionTypes {
	SendMessage = '[Toast] Send message',
}

export class ToastActions {
	public static sendMessage = createAction(
		ToastActionTypes.SendMessage,
		props<ToastSendMessageAction>(),
	);
}

export type ToastSendMessageAction = {
	title: string;
	text: string;
	mode: 'info' | 'warn' | 'error';
};
