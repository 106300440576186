import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	DialogService,
	PlcDialogToggleParams,
} from '@components/modules/common/dialog/dialog.service';

@Component({
	selector: 'plc-dialog-wrapper',
	templateUrl: './dialog-wrapper.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-dialog-wrapper' },
})
export class DialogWrapperComponent implements OnInit, OnDestroy {
	@HostBinding('style.display')
	public get display(): string {
		return this._display ? 'grid' : 'none';
	}

	private _display: boolean;
	private unsusbscribe$: Subject<void> = new Subject();

	constructor(
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.dialogService.toggle$
			.pipe(takeUntil(this.unsusbscribe$))
			.subscribe(({ action }: PlcDialogToggleParams) => {
				this._display = action === 'show';
				this.cdr.markForCheck();
			});
	}

	ngOnDestroy() {
		this.unsusbscribe$.next();
		this.unsusbscribe$.complete();
	}
}
