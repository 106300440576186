import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	Host,
	Input,
	OnChanges,
	Optional,
	SimpleChange,
	SimpleChanges,
	SkipSelf,
} from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	NG_VALUE_ACCESSOR,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import {
	PlcBaseFormControl,
	PlcTypedFormControl,
	PlcValidationError,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { ObjectUtils } from '@core/utils/object.utils';

@Component({
	selector: 'plc-number-input',
	templateUrl: './number-input.component.html',
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NumberInputComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-number-input' },
})
export class NumberInputComponent
	extends PlcBaseFormControl
	implements OnChanges
{
	@Input({ required: true }) public label: string;
	@Input({ required: true }) public min: number;
	@Input({ required: true }) public max: number;
	@Input({ required: true }) public required: boolean;

	public get canAdd(): boolean {
		if (
			!ObjectUtils.isDefined(this.max) ||
			!ObjectUtils.isDefined(this._value)
		)
			return true;

		return (this._value as number) < this.max;
	}

	public get canReduce(): boolean {
		if (
			!ObjectUtils.isDefined(this.min) ||
			!ObjectUtils.isDefined(this._value)
		)
			return true;

		return (this._value as number) > this.min;
	}

	public get labelClass(): string {
		const classes: string[] = [];

		// if (this._focused || this._value)
		// 	classes.push('plc-text-input__wrapper__label--focus');

		if (this._control && this._control.validator) {
			const validator = this._control.validator({} as AbstractControl);
			if (validator && validator.required)
				classes.push('plc-number-input__label--required');
		}

		return classes.join(' ');
	}

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		controlContainer: ControlContainer,
		cdr: ChangeDetectorRef,
		private elementRef: ElementRef,
	) {
		super(controlContainer, cdr);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.setValidators(
			new SimpleChange(null, this.min, false),
			new SimpleChange(null, this.max, false),
			new SimpleChange(null, this.required, false),
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.min || changes.max || changes.required) {
			const { min, max, required } = changes;
			this.setValidators(min, max, required);
		}
	}

	public handleInput(value: string | number): void {
		this._value = +(value as string).replace('.', '');
		this.onTouch();
		this.onChange(this._value);
	}

	public handleFocusOut(event: FocusEvent): void {
		const relatedTarget = event.relatedTarget as HTMLElement;
		const isChild = this.elementRef.nativeElement.contains(relatedTarget);

		if (isChild) event.preventDefault();
		else this.handleBlur();
	}

	public add(): void {
		this.handleInput(`${+(this._value ?? 0) + 1}`);
	}

	public reduce(): void {
		this.handleInput(`${+(this._value ?? 0) - 1}`);
	}

	private setValidators(
		min: SimpleChange,
		max: SimpleChange,
		required: SimpleChange,
	): void {
		const functions: ValidatorFn[] = [];
		const errors: PlcValidationError[] = [];

		if (
			ObjectUtils.isDefined(min?.previousValue) &&
			!ObjectUtils.isDefined(min?.currentValue)
		)
			this.min = null;
		else if (ObjectUtils.isDefined(min?.currentValue))
			this.min = min.currentValue;

		if (ObjectUtils.isDefined(this.min)) {
			const minError: PlcValidationError = {
				error: PlcValidationErrorType.min,
				message: 'forms.validations.min',
				interpolatedParams: {
					min: this.min,
				},
			};

			functions.push(Validators.min(this.min));
			errors.push(minError);
		}

		if (
			ObjectUtils.isDefined(max?.previousValue) &&
			!ObjectUtils.isDefined(max?.currentValue)
		)
			this.max = null;
		else if (ObjectUtils.isDefined(max?.currentValue))
			this.max = max.currentValue;

		if (ObjectUtils.isDefined(this.max)) {
			const maxError: PlcValidationError = {
				error: PlcValidationErrorType.max,
				message: 'forms.validations.max',
				interpolatedParams: {
					max: this.max,
				},
			};

			functions.push(Validators.max(this.max));
			errors.push(maxError);
		}

		if (
			ObjectUtils.isDefined(required?.previousValue) &&
			!ObjectUtils.isDefined(required?.currentValue)
		)
			this.required = null;
		else if (ObjectUtils.isDefined(required?.currentValue))
			this.required = required.currentValue;

		if (this.required) {
			const requiredError: PlcValidationError = {
				error: PlcValidationErrorType.required,
				message: `forms.validations.required`,
				interpolatedParams: { name: this.label },
			};

			functions.push(Validators.required);
			errors.push(requiredError);
		}

		if (this._control instanceof PlcTypedFormControl) {
			this._control.setPlcValidators(functions, errors);
			this._control.updateValueAndValidity();
		}

		this.cdr.markForCheck();
	}
}
