/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { Lang } from '@api/models/enums';
import {
	plcHeightIncreaseAnimation,
	plcWidthIncreaseAnimation,
} from '@components/animations/size-increate.animation';

export interface PlcFlagPickerOption {
	name: 'gl' | 'es' | 'us';
	lang: Lang;
}

export type PlcFlagPickerDirection = 'row' | 'column';

@Component({
	selector: 'plc-flag-picker',
	templateUrl: './flag-picker.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'plc-flag-picker' },
	animations: [plcHeightIncreaseAnimation, plcWidthIncreaseAnimation],
})
export class FlagPickerComponent {
	@HostListener('mouseleave')
	public fold(): void {
		this._unfolded = false;
	}

	@HostBinding('class')
	public get hostClass(): string {
		return this._unfolded ? `plc-flag-picker--extended-${this.dir}` : '';
	}

	@Input({ required: true }) public options: PlcFlagPickerOption[];
	@Input({ required: true }) public selected: PlcFlagPickerOption;
	@Input() public dir: PlcFlagPickerDirection = 'row';
	@Output() public change = new EventEmitter<PlcFlagPickerOption>();

	public src(name: string): string {
		return `assets/images/${name}.svg`;
	}

	public get rowUnfolded(): boolean {
		return this._unfolded && this.dir === 'row';
	}

	public get columnUnfolded(): boolean {
		return this._unfolded && this.dir === 'column';
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private _unfolded = false;

	public unfold(): void {
		this._unfolded = true;
	}

	public emitChange(option: PlcFlagPickerOption): void {
		this.selected = option;
		this._unfolded = false;
		this.change.emit(option);
	}
}
